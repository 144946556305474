import * as React from 'react';
import {AccountStateProp, accountStatePropsMapper,} from "src/type-definitions/reducers";
import {AppState} from "src/reducers/index";
import {AbstractStatelessComponent, connector} from "lumen-react-javascript";
import AccountHome from "src/app/public/routes/account-home";
import Home from "src/app/public/routes/home";

interface Props extends AccountStateProp {
}

export class HomeWrapper extends AbstractStatelessComponent<Props> {

    render() {
        return this.props.accountState && this.props.accountState.id ?
            <AccountHome {...this.props}/> : <Home {...this.props}/>
    }

}

export default connector(
    HomeWrapper,
    {
        mapStateToProps: (state: AppState) => ({
            ...accountStatePropsMapper(state),
        }),
    }
);