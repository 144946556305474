import * as React from 'react';
import {Dropdown, Glyphicon, MenuItem, NavItem} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import Cookie from 'js-cookie';
import {defineMessages, FormattedMessage} from 'react-intl';
import * as is from 'is_js';
import {AbstractSimpleComponent} from "lumen-react-javascript";
import {setCurrentLocationOptions} from "src/utils/cookie-options";

const messages = defineMessages({
    browserAlert: {
        id: 'browserAlert',
        defaultMessage: 'For the best experience we recommend using the Chrome browser',
    }
});

export default class Header extends AbstractSimpleComponent {

    private switchToLanguage(language) {
        Cookie.set('locale', language);
        window.location.reload();
    }

    private renderLanguages() {
        return ['en', 'fi', 'sv', 'nl'].map(lang => {
            return <MenuItem key={lang} onClick={() => this.switchToLanguage(lang)}>{this.intl.locale == lang ?
                <Glyphicon className="mr" glyph="ok"/> : null}{lang.toUpperCase()}</MenuItem>
        });
    }

    componentDidMount() {
        if (!(is.chrome() || is.firefox())) {
            if (!Cookie.get('browserAlert')) {
                Cookie.set('browserAlert', true);
                alert(this.intl.formatMessage(messages.browserAlert));
            }
        }
    }

    render() {
        let bsRoleToggle = {bsRole: 'toggle'};
        let bsRoleMenu = {bsRole: 'menu'};
        return (
            <div id="header" className="text-primary p">
                <div id="logo">
                    <a href="/"><img id="site-logo" src={require("src/img/logo.png")} title="Collaplan"/></a>
                    <small id="payoff-container" className="double-ml">
                        <FormattedMessage id="payoff" defaultMessage="urban collaborative planning"/>
                    </small>
                </div>
                <div id="dropdown-menu">
                    <Dropdown id="bs-dropdown-menu">
                        <div {...bsRoleToggle}>
                            <Glyphicon className="menu-button" glyph="menu-hamburger"/>
                        </div>
                        <div style={{right: 0, left: 'auto', fontWeight: 'bold'}}
                             className="dropdown-menu" {...bsRoleMenu}>
                            <ul className="list-unstyled p">
                                <LinkContainer to="/info">
                                    <NavItem>
                                        <FormattedMessage id="info" defaultMessage="Info"/>
                                    </NavItem>
                                </LinkContainer>
                                <LinkContainer to="/terms">
                                    <NavItem>
                                        <FormattedMessage id="terms" defaultMessage="Terms"/>
                                    </NavItem>
                                </LinkContainer>
                                <LinkContainer to="/privacy-policy">
                                    <NavItem>
                                        <FormattedMessage id="privacyPolicy" defaultMessage="Privacy policy"/>
                                    </NavItem>
                                </LinkContainer>
                                <MenuItem divider/>
                                {this.renderLanguages()}
                            </ul>
                        </div>
                    </Dropdown>
                </div>
            </div>
        );
    }

}