import * as React from 'react';
import {GoogleMap, StreetViewPanorama, withGoogleMap} from "react-google-maps";
import {projectsInBoundsDispatchBinder, ProjectsInBoundsDispatcher} from "src/type-definitions/actions";
import {ProjectsInBoundsStateProp, projectsInBoundsStatePropsMapper} from "src/type-definitions/reducers";
import {compose, withProps} from "recompose";
import PropTypes from "prop-types";
import {geolocated, GeolocatedProps} from "react-geolocated";
import {connect} from "react-redux";
import {AppState} from "src/reducers/index";
import {Project} from "src/type-definitions/types";
import {ProjectHoverActionType} from "src/reducers/project-map-hover-reducer";
import LocationAlert from 'src/components/alerts/location-alert';
import {
    AbstractPureComponent,
    AbstractStatelessComponent,
    connector,
    Empty,
    isMobile,
    locationLoader,
    LocationLoaderInjectedProps
} from "lumen-react-javascript";
import MapView from "src/components/map/map-view";
import PinMarker from 'src/components/map/pin-marker';
import {FormattedMessage} from "react-intl";
import {getCurrentLocationOptions, setCurrentLocationOptions} from "src/utils/cookie-options";
import CurrentLocationMarker from "src/components/map/current-location-marker";
import Toolbar from "src/components/misc/toolbar";
import {calculateDistance} from "src/utils/map-functions";
import {MAP_MARKER_DISTANCE} from "src/app/public/index";

interface Props extends ProjectsInBoundsStateProp, ProjectsInBoundsDispatcher, GeolocatedProps, LocationLoaderInjectedProps {
}

interface State {
    zoom: number;
    showLabels: boolean;
    currentLocation: { lat: number, lng: number };
    loaded: boolean;
    svLocation: { lat: number, lng: number };
}

const DEFAULT_ZOOM = 2;

class Map extends AbstractPureComponent<Props, State> {

    private gmaps;
    private timeoutId;

    static childContextTypes = {
        gmap: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);
        let currentLocationOptions = getCurrentLocationOptions();
        let showLabels = currentLocationOptions && 'showLabels' in currentLocationOptions ? currentLocationOptions.showLabels : false;
        this.state = {zoom: DEFAULT_ZOOM, currentLocation: null, showLabels, loaded: false, svLocation: null};
    }

    componentDidMount() {
        setTimeout(() => this.setState({loaded: true}));
    }

    getChildContext() {
        return {
            gmap: this.gmaps,
        }
    }

    render() {
        this.props.handleLocationCallbacks({
            onLocationEnabled: this.onLocationEnabled.bind(this),
        });
        let self = this;
        return (
            <Empty>
                <LocationAlert/>
                <GoogleMap
                    ref={i => this.gmaps = i}
                    defaultZoom={DEFAULT_ZOOM}
                    defaultCenter={{lat: 0, lng: 0}}
                    zoom={this.state.zoom}
                    onBoundsChanged={() => {
                        let bounds = this.gmaps.getBounds();
                        let sw = bounds.getSouthWest();
                        let ne = bounds.getNorthEast();
                        if (this.timeoutId) {
                            clearTimeout(this.timeoutId);
                        }
                        this.timeoutId = setTimeout(() => this.props.projectsInBoundsDispatcher(sw.lat(), sw.lng(), ne.lat(), ne.lng()), 100)
                    }}
                >
                    {this.state.loaded && (
                        <Empty>
                            {this.renderMarkers()}
                            <StreetViewPanorama onPositionChanged={function () {
                                let location = this.getPosition();
                                self.setState({svLocation: {lat: location.lat(), lng: location.lng()}});
                            }}>
                                {this.renderMarkers(MAP_MARKER_DISTANCE)}
                            </StreetViewPanorama>
                        </Empty>
                    )}
                </GoogleMap>

                <Toolbar
                    style={{position: 'absolute', right: 60, top: 10, zIndex: 12}}
                    toolbarOptions={[
                        {
                            type: 'toggle',
                            value: this.state.showLabels,
                            buttonContentActive: <FormattedMessage id="hideLabels" defaultMessage="Hide Labels"/>,
                            buttonContentNonActive: <FormattedMessage id="showLabels" defaultMessage="Show Labels"/>,
                            onChange: showLabels => {
                                this.setState({
                                        showLabels,
                                    }, () => setCurrentLocationOptions({
                                        showLabels
                                    })
                                );
                            }
                        }
                    ]}/>
            </Empty>
        );
    }

    private renderMarkers(distance = null) {
        return (
            <Empty>
                {(this.state.currentLocation && (distance === null || (this.state.svLocation && calculateDistance(this.state.currentLocation, this.state.svLocation) < distance))) && (
                    <CurrentLocationMarker
                        latitude={this.state.currentLocation.lat}
                        longitude={this.state.currentLocation.lng}
                    />
                )}

                {this.props.projectsInBoundsState.map(p => {
                    if (distance === null || (this.state.svLocation && calculateDistance({
                        lat: p.latitude,
                        lng: p.longitude,
                    }, this.state.svLocation) < distance)) {
                        return (
                            <MapView
                                key={p.id}
                                latitude={p.latitude}
                                longitude={p.longitude}>
                                <PinWrapper
                                    project={p}
                                    text={p.name}
                                    textOnHover={!this.state.showLabels}
                                    onClick={() => this.history.push(`/project/${p.id}`)}
                                />
                            </MapView>
                        );
                    }
                })}
            </Empty>
        );
    }

    onLocationEnabled(coords: Coordinates) {
        this.gmaps.panTo({lat: coords.latitude, lng: coords.longitude});
        setTimeout(() => this.setState({zoom: 10, currentLocation: {lat: coords.latitude, lng: coords.longitude}}), 0);
    }

}


class PinWrapperClass extends AbstractStatelessComponent<{
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    onClick?: () => void;
    active?: boolean;
    text?: string;
    textOnHover?: boolean;
    project: Project;
    projectMapHoverState: Project;
    projectMapHoverDispatcher: (action) => void;
}> {
    render() {

        return <PinMarker
            {...this.props}
            active={this.props.projectMapHoverState && this.props.projectMapHoverState.id == this.props.project.id}
            onMouseOver={() => this.props.projectMapHoverDispatcher({
                payload: this.props.project,
                type: ProjectHoverActionType.PROJECT_HOVER_IN
            })}
            onMouseOut={() => this.props.projectMapHoverDispatcher({
                payload: null,
                type: ProjectHoverActionType.PROJECT_HOVER_OUT
            })}
        />;
    }
}

let PinWrapper = connector(PinWrapperClass, {
    mapStateToProps: (state: AppState) => ({
        projectMapHoverState: state.projectMapHoverState,
    }),
    mapDispatchToProps: dispatch => ({
        projectMapHoverDispatcher: action => dispatch(action),
    }),
});

export default compose(
    withProps({
        containerElement: <div className="map-container-element"/>,
        mapElement: <div className="map-element"/>,
    }),
    withGoogleMap,
    geolocated({
        watchPosition: true,
    }),
    locationLoader(),
    connect(
        (state: AppState) => ({
            ...projectsInBoundsStatePropsMapper(state),
        }),
        dispatch => ({
            ...projectsInBoundsDispatchBinder(dispatch),
        })),
)(Map);