import * as React from 'react';
import {AbstractStatelessComponent} from "lumen-react-javascript";
import {Checklist, Discussion} from "src/type-definitions/types";
import './checklists-addon.less';
import {checklistItemDoneRequest} from "src/type-definitions/endpoints";

interface Props {
    discussion: Discussion;
}

export default class ChecklistsAddon extends AbstractStatelessComponent<Props> {

    private renderChecklist(checklist: Checklist) {
        return (
            <div key={checklist.id} className="p">
                <div className="checklist-addon flex-vertical rel-width pb">
                    <div>
                        <h4>{checklist.name}</h4>
                    </div>
                    {checklist.items.map(item => (
                        <div key={item.id} className="checklist-item flex-vertical">
                            <div className="item">
                                <span className="mr">
                                    <input type="checkbox"
                                           checked={item.done}
                                           onChange={() => {
                                               checklistItemDoneRequest(checklist.id, item.id, !item.done).then(() => {
                                                   window.location.reload()
                                               });
                                           }}/>
                                </span>
                                {item.item}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    render() {
        if (this.props.discussion.checklists.length) {
            return this.props.discussion.checklists.map(checklist => this.renderChecklist(checklist));
        } else {
            return null;
        }
    }

}
