import * as React from 'react';
import Raven from "raven-js/dist/raven.min";
import {AuthUtils} from "lumen-react-javascript";
import * as config from "config";
import {User} from "src/type-definitions/types";

export default class ErrorBoundary extends React.Component {

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
        if (config.environment !== "local") {
            Raven.captureException(error, {
                environment: config.environment,
                extra: errorInfo,
                tags: {
                    userId: AuthUtils.isLoggedIn() ? AuthUtils.authenticatedUser<User>().id.toString() : null,
                    token: AuthUtils.getToken(),
                }
            });
        }
    }

    render() {
        return this.props.children;
    }

}