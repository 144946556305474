import {combineReducers, Reducer, AnyAction} from "redux";
import {FormReducer, FormStateMap, reducer as formReducer} from 'redux-form';
import {ActionType} from "./actions";
import {GlobalActionType} from 'lumen-react-javascript';
import {Account, Project, Media, Discussion} from './types';

export function accountStatePropsMapper(state: BaseAppState) {
	return {accountState: state.accountState};
}

export function projectsStatePropsMapper(state: BaseAppState) {
	return {projectsState: state.projectsState};
}

export function projectsInBoundsStatePropsMapper(state: BaseAppState) {
	return {projectsInBoundsState: state.projectsInBoundsState};
}

export function projectStatePropsMapper(state: BaseAppState) {
	return {projectState: state.projectState};
}

export function showProjectByAliasStatePropsMapper(state: BaseAppState) {
	return {showProjectByAliasState: state.showProjectByAliasState};
}

export function mediaStatePropsMapper(state: BaseAppState) {
	return {mediaState: state.mediaState};
}

export function showMediaByAliasStatePropsMapper(state: BaseAppState) {
	return {showMediaByAliasState: state.showMediaByAliasState};
}

export function discussionStatePropsMapper(state: BaseAppState) {
	return {discussionState: state.discussionState};
}

export function showDiscussionByAliasStatePropsMapper(state: BaseAppState) {
	return {showDiscussionByAliasState: state.showDiscussionByAliasState};
}

export interface AccountStateProp {
    accountState: Account;
}

export interface ProjectsStateProp {
    projectsState: Array<Project>;
}

export interface ProjectsInBoundsStateProp {
    projectsInBoundsState: Array<Project>;
}

export interface ProjectStateProp {
    projectState: Project;
}

export interface ShowProjectByAliasStateProp {
    showProjectByAliasState: Project;
}

export interface MediaStateProp {
    mediaState: Media;
}

export interface ShowMediaByAliasStateProp {
    showMediaByAliasState: Media;
}

export interface DiscussionStateProp {
    discussionState: Discussion;
}

export interface ShowDiscussionByAliasStateProp {
    showDiscussionByAliasState: Discussion;
}

export interface BaseAppState {
    form: FormStateMap;
	accountState: Account;
	projectsState: Array<Project>;
	projectsInBoundsState: Array<Project>;
	projectState: Project;
	showProjectByAliasState: Project;
	mediaState: Media;
	showMediaByAliasState: Media;
	discussionState: Discussion;
	showDiscussionByAliasState: Discussion;
}

export interface BaseAppStateReducer<T extends BaseAppState> extends Reducer<T> {
    form: FormReducer;
	accountState: Reducer<Account>;
	projectsState: Reducer<Array<Project>>;
	projectsInBoundsState: Reducer<Array<Project>>;
	projectState: Reducer<Project>;
	showProjectByAliasState: Reducer<Project>;
	mediaState: Reducer<Media>;
	showMediaByAliasState: Reducer<Media>;
	discussionState: Reducer<Discussion>;
	showDiscussionByAliasState: Reducer<Discussion>;
}

export function createAppStateReducers<T extends BaseAppState, U extends BaseAppStateReducer<T>>(reducerOverrides: Partial<U> = {}): U {
	return {
		...{
			form: formReducer,
			accountState: accountReducer,
			projectsState: projectsReducer,
			projectsInBoundsState: projectsInBoundsReducer,
			projectState: projectReducer,
			showProjectByAliasState: showProjectByAliasReducer,
			mediaState: mediaReducer,
			showMediaByAliasState: showMediaByAliasReducer,
			discussionState: discussionReducer,
			showDiscussionByAliasState: showDiscussionByAliasReducer,
		},
		...(reducerOverrides as any)
	};
}

export function createRootReducer<T extends BaseAppState>(appStateReducers): Reducer<T> {
	return combineReducers<T>(appStateReducers);
}

/*
 * THE FOLLOWING ARE DEFAULT REDUCERS, COPY OR USE AS WANTED
 */
export const accountReducer: Reducer<Account> = (state: Account = null, action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case ActionType.ACCOUNT:
            return action.payload;
        case GlobalActionType.REFRESH_STORE:
            return {...state};
        default:
            return state;
    }
};

export const projectsReducer: Reducer<Array<Project>> = (state: Array<Project> = [], action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case ActionType.PROJECTS:
            return action.payload;
        case GlobalActionType.REFRESH_STORE:
            return {...state};
        default:
            return state;
    }
};

export const projectsInBoundsReducer: Reducer<Array<Project>> = (state: Array<Project> = [], action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case ActionType.PROJECTS_IN_BOUNDS:
            return action.payload;
        case GlobalActionType.REFRESH_STORE:
            return {...state};
        default:
            return state;
    }
};

export const projectReducer: Reducer<Project> = (state: Project = null, action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case ActionType.PROJECT:
            return action.payload;
        case GlobalActionType.REFRESH_STORE:
            return {...state};
        default:
            return state;
    }
};

export const showProjectByAliasReducer: Reducer<Project> = (state: Project = null, action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case ActionType.SHOW_PROJECT_BY_ALIAS:
            return action.payload;
        case GlobalActionType.REFRESH_STORE:
            return {...state};
        default:
            return state;
    }
};

export const mediaReducer: Reducer<Media> = (state: Media = null, action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case ActionType.MEDIA:
            return action.payload;
        case GlobalActionType.REFRESH_STORE:
            return {...state};
        default:
            return state;
    }
};

export const showMediaByAliasReducer: Reducer<Media> = (state: Media = null, action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case ActionType.SHOW_MEDIA_BY_ALIAS:
            return action.payload;
        case GlobalActionType.REFRESH_STORE:
            return {...state};
        default:
            return state;
    }
};

export const discussionReducer: Reducer<Discussion> = (state: Discussion = null, action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case ActionType.DISCUSSION:
            return action.payload;
        case GlobalActionType.REFRESH_STORE:
            return {...state};
        default:
            return state;
    }
};

export const showDiscussionByAliasReducer: Reducer<Discussion> = (state: Discussion = null, action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case ActionType.SHOW_DISCUSSION_BY_ALIAS:
            return action.payload;
        case GlobalActionType.REFRESH_STORE:
            return {...state};
        default:
            return state;
    }
};
