import * as React from 'react';
import {AccountStateProp, accountStatePropsMapper,} from "src/type-definitions/reducers";
import {AppState} from "src/reducers/index";
import {Project} from "src/type-definitions/types";
import {ProjectHoverActionType} from "src/reducers/project-map-hover-reducer";
import ProjectLabel from "src/components/project/project-label";
import {FormattedMessage} from 'react-intl';
import {AbstractStatelessComponent, connect, connector, LabelType} from "lumen-react-javascript";

interface Props extends AccountStateProp {
    projectMapHoverState: Project;
    projectMapHoverDispatcher: (action) => void;
    hardAccountId?: number;
}

export class AccountHome extends AbstractStatelessComponent<Props> {

    render() {
        return (
            <div className="bg-gray-lighter" style={{minHeight: '100%'}}>
                <table className="table table-bordered bg-gray-lighter animated-table" style={{margin: 0}}>
                    <tbody>
                    {(this.props.accountState && this.props.accountState.projects.length) ? this.props.accountState.projects.map(p => (
                        <tr
                            style={{cursor: 'pointer'}}
                            key={p.id}
                            className={(this.props.projectMapHoverState && this.props.projectMapHoverState.id == p.id) ? 'bg-primary' : ''}
                            onClick={() => {
                                this.history.push(`/project/${p.id}`);
                            }}
                            onMouseOver={() => this.props.projectMapHoverDispatcher({
                                payload: p,
                                type: ProjectHoverActionType.PROJECT_HOVER_IN
                            })}
                            onMouseOut={() => this.props.projectMapHoverDispatcher({
                                payload: null,
                                type: ProjectHoverActionType.PROJECT_HOVER_OUT
                            })}
                        >
                            <td><ProjectLabel project={p} labelType={LabelType.MEDIUM}/></td>
                        </tr>
                    )) : <tr>
                        <td><i><FormattedMessage id="noProjectsFound"
                                                 defaultMessage="No projects found in the current view"/></i></td>
                    </tr>}
                    </tbody>
                </table>
            </div>
        );
    }

}

export default connector(
    AccountHome,
    {
        mapStateToProps: (state: AppState) => ({
            ...accountStatePropsMapper(state),
            projectMapHoverState: state.projectMapHoverState,
        }),
        mapDispatchToProps: dispatch => ({
            projectMapHoverDispatcher: (action) => dispatch(action),
        }),
    }
);
