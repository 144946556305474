import * as React from 'react';
import {AbstractStatelessComponent} from "lumen-react-javascript";
import {Discussion, Poll} from "src/type-definitions/types";
import {FormattedMessage} from "react-intl";
import './polls-addon.less';
import {pollAnswerRequest} from "src/type-definitions/endpoints";

interface Props {
    discussion: Discussion;
}

export default class PollsAddon extends AbstractStatelessComponent<Props> {

    private renderPoll(poll: Poll) {
        let totalVotes = poll.answers.reduce((cum, answer) => answer.voteCount + cum, 0);
        return (
            <div key={poll.id} className="p">
                <div className="poll-addon flex-vertical rel-width pb">
                    <div>
                        <h4>{poll.question}</h4>
                        <h6>
                            <FormattedMessage
                                id="maxAnswersAllowed"
                                defaultMessage="Only {numberOfAnswers} answer(s) allowed"
                                values={{numberOfAnswers: poll.maxVotes}}
                            />
                        </h6>
                    </div>
                    {poll.answers.map(answer => (
                        <div key={answer.id} className="poll-answer flex-vertical">
                            <div className="answer">
                                <span className="mr">
                                    <input type="checkbox"
                                           checked={poll.used.findIndex(answerId => answerId == answer.id) >= 0}
                                           onClick={() => {
                                               pollAnswerRequest(poll.id, answer.id).then(() => {
                                                   window.location.reload()
                                               })
                                           }}/>
                                </span>
                                {answer.answer}
                            </div>
                            <div className="bar flex-horizontal">
                                <div className="bg-primary" style={{flex: answer.voteCount}}/>
                                <div style={{flex: totalVotes - answer.voteCount}}/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    render() {
        if (this.props.discussion.polls.length) {
            return this.props.discussion.polls.map(poll => this.renderPoll(poll));
        } else {
            return null;
        }
    }

}
