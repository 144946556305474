import Cookie from 'js-cookie';

function getPathName(path = null) {
    return (path ? path : window.location.pathname).replace(/[^a-z0-9]/g, '_');
}

export function getCurrentLocationOptions(path = null) {
    let cookie = Cookie.get(getPathName(path));
    return cookie ? JSON.parse(cookie) : {};
}

export function setCurrentLocationOptions(options, path = null) {
    Cookie.set(getPathName(path), JSON.stringify(options));
}