import * as React from 'react';
import {boot} from 'src/index';
import {Route, Switch} from 'react-router-dom';
import Header from "./routes/header";
import Footer from "./routes/footer";
import Map from "./routes/map";
import Media from "./routes/media";
import './index.less';
import Info from "./routes/info";
import Terms from "./routes/terms";
import PrivacyPolicy from "./routes/privacy-policy";
import AccountHome from "./routes/account-home";
import AccountMap from "./routes/account-map";
import Project from "./routes/project";
import Discussion from "./routes/discussion";
import Medias from "./routes/medias";
import RequestPassword from "./routes/request-password";
import UpdatePassword from "./routes/update-password";
import {
    Analytics,
    appComposer,
    AppRouter,
    AuthUtils,
    AxiosRequestConfigExtended,
    dispatch,
    getGlobal,
    setGlobal,
    Window
} from "lumen-react-javascript";
import {withScriptjs} from "react-google-maps";
import {withProps} from "recompose";
import axios, {AxiosError, AxiosResponse, Cancel} from "axios";
import ReactGA from 'react-ga';
import {User} from "src/type-definitions/types";
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import simpleNumberLocalizer from 'react-widgets-simple-number';
import MapWrapper from "src/app/public/routes/map-wrapper";
import HomeWrapper from "src/app/public/routes/home-wrapper";
import ApplicationContextLoader from "src/app/public/routes/application-context-loader";
import * as config from "config";
import '../../less/app.less';
import MobileViewButton from "src/app/public/components/mobile-view-button/mobile-view-button";
import $ from "jquery";
import {prefix} from 'inline-style-prefixer';

export const MAP_MARKER_DISTANCE = 200;

class Index extends React.Component {

    private renderRoutersWithContext(key, props) {
        return (
            <ApplicationContextLoader key={key} {...props}>
                <div id="content-push">
                    <Switch>
                        <Route path="/project/:projectId/media/:mediaId/pin/:pinId" component={Media}/>
                        <Route path="/project/:projectId/media/:mediaId" component={Media}/>
                        <Route path="/request-password" extact component={RequestPassword}/>
                        <Route path="/update-password" extact component={UpdatePassword}/>
                        <Route path="/info" extact component={Info}/>
                        <Route path="/terms" extact component={Terms}/>
                        <Route path="/privacy-policy" extact component={PrivacyPolicy}/>
                        <Route path="/account/:accountId" component={AccountMap}/>
                        <Route path="/" extact component={MapWrapper}/>
                    </Switch>
                    <Route path="/project/:projectId" component={Medias}/>
                </div>
                <div id="content-pop">
                    <Route path="/project/:projectId" component={Project}/>
                    <Switch>
                        <Route path="/project/:projectId/media/:mediaId" component={Discussion}/>
                        <Route path="/request-password" extact component={Map}/>
                        <Route path="/update-password" extact component={Map}/>
                        <Route path="/info" extact component={Map}/>
                        <Route path="/terms" extact component={Map}/>
                        <Route path="/privacy-policy" extact component={Map}/>
                        <Route path="/account/:accountId" component={AccountHome}/>
                        <Route path="/" extact component={HomeWrapper}/>
                    </Switch>
                </div>
            </ApplicationContextLoader>
        );
    }

    render() {
        return (
            <AppRouter>
                <Route render={() => {
                    let {pathname, search} = location;
                    return <Analytics location={{pathname, search}} reactGa={ReactGA}/>
                }}/>
                {/*<BetaChecker version="v1.40"/>*/}
                <Route component={Header}/>
                <div id="content">
                    <Switch>
                        <Route path="/project/:projectId" render={props => this.renderRoutersWithContext(1, props)}/>
                        <Route path="/account/:accountId" render={props => this.renderRoutersWithContext(2, props)}/>
                        <Route render={props => this.renderRoutersWithContext(3, props)}/>
                    </Switch>
                </div>
                <Route component={Footer}/>
                <MobileViewButton/>
            </AppRouter>
        );
    }

}

export const REQUEST_COUNTER_EVENT = 'REQUEST_COUNTER_EVENT';

export type CollaplanWindowObject = Window & {
    requestCounter: number;
};

function incrementRequestCounter() {
    let counter = getGlobal<CollaplanWindowObject>('requestCounter', 0);
    counter++;
    setGlobal<CollaplanWindowObject>('requestCounter', counter);

}

function decrementRequestCounter() {
    let counter = getGlobal<CollaplanWindowObject>('requestCounter', 0);
    counter--;
    counter = Math.max(counter, 0);
    setGlobal<CollaplanWindowObject>('requestCounter', counter);
}

let dom = $(`
<div style="position; fixed; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; background-color: white;">
    <img style="flex: 1;" src="${require("src/img/loader.gif")}"/>
</div>
`).appendTo($('body'));
$(document).ready(() => {
    $(dom).hide(250, () => $(dom).remove());
    boot(Index, {
        App: appComposer(
            withProps({
                googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsApiKey}&v=3.exp&libraries=geometry,drawing,places`,
                loadingElement: <div style={{height: '100%'}}/>,
                axios,
                axiosDefaultRequestConfig: {
                    baseURL: config.backendUrl,
                    globalHandlers: {
                        onRequest: (config: AxiosRequestConfigExtended) => {
                            if (config.method.toLowerCase() !== 'get') {
                                incrementRequestCounter();
                                dispatch(REQUEST_COUNTER_EVENT, {requestCounter: getGlobal<CollaplanWindowObject>('requestCounter')});
                            }
                        },
                        onResponse: (response: AxiosResponse) => {
                            if (response.config.method.toLowerCase() !== 'get') {
                                decrementRequestCounter();
                                dispatch(REQUEST_COUNTER_EVENT, {requestCounter: getGlobal<CollaplanWindowObject>('requestCounter')});
                            }
                        },
                        onResponseError: (error: AxiosError | Cancel) => {
                            if (axios.isCancel(error)) {
                                if ('config' in error && error.config.method.toLowerCase() !== 'get') {
                                    decrementRequestCounter();
                                    dispatch(REQUEST_COUNTER_EVENT, {requestCounter: getGlobal<CollaplanWindowObject>('requestCounter')});
                                }
                            } else {
                                if ((error as AxiosError).config.method.toLowerCase() !== 'get') {
                                    decrementRequestCounter();
                                    dispatch(REQUEST_COUNTER_EVENT, {requestCounter: getGlobal<CollaplanWindowObject>('requestCounter')});
                                }
                            }
                        },
                    }
                },
            }),
            withScriptjs,
        ),
        appName: 'public',
        className: '',
        postBoot: locale => {
            ReactGA.initialize(config.googleAnalyticsUA as any, {
                gaOptions: {
                    userId: AuthUtils.isLoggedIn() ? AuthUtils.authenticatedUser<User>().id.toString() : null
                }
            });
            Moment.locale(locale);
            momentLocalizer();
            simpleNumberLocalizer();
        },
        globalLoader: (
            <div style={{
                zIndex: 11,
                opacity: 0.5,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                position: 'absolute',
                left: 0,
                top: 0,
            }}>
                <img src={require("src/img/loader.gif")}/>
            </div>
        ),
    });
});