import * as React from "react";
import {DetailedHTMLProps, HTMLAttributes} from "react";
import {AbstractStatelessPureComponent} from "lumen-react-javascript";
import {ButtonToolbar, ToggleButton, ToggleButtonGroup} from "react-bootstrap";

interface ToolbarOption {
    type: 'toggle';
    value: any;
    buttonContentActive: any;
    buttonContentNonActive?: any;
    onChange?: (value: any) => void;
}

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    toolbarOptions: Array<ToolbarOption>;
}

export default class Toolbar extends AbstractStatelessPureComponent<Props> {

    private getDefaultValue() {
        return this.props.toolbarOptions.reduce((cum, cur, index) => {
            if (cur.type === 'toggle') {
                if (cur.value === true) {
                    return [...cum, ...[index]];
                }
            }
            return cum;
        }, []);
    }

    private renderToolbarButtons(toolbarOption) {
        if (toolbarOption.type === 'toggle') {
            if (toolbarOption.buttonContentNonActive) {
                if (toolbarOption.value === true) {
                    return toolbarOption.buttonContentActive;
                } else {
                    return toolbarOption.buttonContentNonActive;
                }
            } else {
                return toolbarOption.buttonContentActive;
            }
        } else {
            return null;
        }
    }

    render() {
        let {toolbarOptions, ...props} = this.props;
        return (
            <div {...props}>
                <ButtonToolbar>
                    <ToggleButtonGroup
                        type="checkbox"
                        name="options"
                        defaultValue={this.getDefaultValue()}
                        onChange={values => {
                            toolbarOptions.map((toolbarOption, index) => {
                                toolbarOption.onChange && toolbarOption.onChange(values.findIndex(v => v === index) >= 0);
                            });
                        }}>
                        {toolbarOptions.map((toolbarOption, index) => {
                            if (toolbarOption.type === 'toggle') {
                                return (
                                    <ToggleButton className="btn-xs" key={index} value={index}>
                                        {this.renderToolbarButtons(toolbarOption)}
                                    </ToggleButton>
                                );
                            }
                        })}
                    </ToggleButtonGroup>
                </ButtonToolbar>
            </div>
        );
    }

}