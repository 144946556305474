import * as React from 'react';
import {AbstractComponent} from 'lumen-react-javascript';
import classNames from 'classnames';

interface Props {
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    onClick?: () => void;
    active?: boolean;
    text?: string;
    textOnHover?: boolean;
}

interface State {
    hover: boolean;
}

export default class PinMarker extends AbstractComponent<Props, State> {

    static defaultProps = {
        textOnHover: false,
    };

    constructor(props, context) {
        super(props, context);
        this.state = {hover: false};
    }

    render() {
        return (
            <div
                className={classNames({
                    'pin': true,
                    'bg-primary': true,
                    'active': this.props.active || this.state.hover,
                })}
                onMouseOver={() => {
                    if (this.props.onMouseOver) {
                        this.props.onMouseOver();
                    }
                    this.setState({hover: true});
                }}
                onMouseOut={() => {
                    if (this.props.onMouseOut) {
                        this.props.onMouseOut();
                    }
                    this.setState({hover: false});
                }}
                onClick={() => {
                    if (this.props.onClick) {
                        this.props.onClick();
                    }
                    this.setState({hover: false});
                }}
            >
                {(this.props.text && (!this.props.textOnHover || this.state.hover || this.props.active)) && (
                    <div className="pin-text">{this.props.text}</div>
                )}
            </div>
        );
    }

}