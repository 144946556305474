import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {AbstractSimpleComponent} from "lumen-react-javascript";

export default class Footer extends AbstractSimpleComponent {

    render() {
        return (
            <div id="footer" className="text-primary p">
                <small>
                    <FormattedMessage id="footerText" defaultMessage="Powered by &copy; Collaplan Oy 2018"/>
                </small>
                <small>
                    <FormattedMessage id="buildVersion" defaultMessage="Build version"/>
                    &nbsp;{process.env.REACT_APP_BUILD_VERSION}
                </small>
            </div>
        );
    }

}