import {AnyAction, Reducer} from "redux";
import {GlobalActionType} from 'lumen-react-javascript';

export enum DiscussionActionType {
    LOAD_DISCUSSION = 'LOAD_DISCUSSION',
    CLEAR_DISCUSSION = 'CLEAR_DISCUSSION',
}

export interface DiscussionLoader {
    url: string;
    identifier: string;
    title: string;
    type: string;
}

const openDiscussionReducer: Reducer<DiscussionLoader> = (state: DiscussionLoader = {
    url: null,
    identifier: null,
    title: null,
    type: null,
}, action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case DiscussionActionType.LOAD_DISCUSSION:
            return action.payload ? {...state, ...action.payload} : {url: null, identifier: null, title: null, type: null};
        case DiscussionActionType.CLEAR_DISCUSSION:
            return {url: null, identifier: null, title: null, type: null};
        case GlobalActionType.REFRESH_STORE:
            return {...state};
        default:
            return state;
    }
};

export default openDiscussionReducer;