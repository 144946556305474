import * as React from 'react';
import {Project} from 'src/type-definitions/types';
import {FormattedDate} from 'react-intl';
import {AbstractLabel, LabelProps} from "lumen-react-javascript";
import ReactMarkdown from "react-markdown";

interface Props extends LabelProps {
    project: Project;
}

export default class ProjectLabel extends AbstractLabel<Props, any> {

    protected getContent(): string | JSX.Element {
        return <ReactMarkdown source={this.props.project.description}/>;
    }

    protected getGlyph(): string {
        return "map-marker";
    }

    protected getName(): string {
        return this.props.project.name;
    }

    protected getSub(): string | JSX.Element {
        return <FormattedDate value={this.props.project.created.toISOString()}/>;
    }

    protected getThumbnail(): string {
        return this.props.project.avatars.thumbs[AbstractLabel.sizeKeyMap[this.props.labelType]];
    }

    protected hasThumbnail(): boolean {
        return !!this.props.project.avatars;
    }

}