import * as React from 'react';
import {GoogleMap, Polyline, withGoogleMap} from "react-google-maps";
import {compose, withProps} from "recompose";
import {
    Discussion,
    MapType,
    Media,
    MediaMap as MediaMapObject,
    Pin,
    PinMap,
    User,
    UserType
} from "src/type-definitions/types";
import {AppState} from "src/reducers/index";
import {DiscussionHoverActionType} from "src/reducers/hover-discussion-reducer";
import {geolocated, GeolocatedProps} from "react-geolocated";
import StreetViewPanorama from "react-google-maps/lib/components/StreetViewPanorama";
import PinPopup from "public/components/media/pin-popup";
import {pinRequest} from "src/type-definitions/endpoints";
import {
    AbstractPureComponent,
    AbstractStatelessComponent,
    AuthUtils,
    connector,
    Empty,
    locationLoader,
    LocationLoaderInjectedProps
} from "lumen-react-javascript";
import MapView from "src/components/map/map-view";
import {Glyphicon} from "react-bootstrap";
import DiscussionPin from "src/components/discussion-pin";
import {FormattedMessage} from "react-intl";
import {getCurrentLocationOptions, setCurrentLocationOptions} from "src/utils/cookie-options";
import Toolbar from "src/components/misc/toolbar";
import {MAP_MARKER_DISTANCE} from "src/app/public/index";
import {calculateDistance} from "src/utils/map-functions";

interface Props {
    pins: Array<Pin>;
    onPinClick: (pin: Pin) => void;
    media: Media;
}

interface State {
    showLabels: boolean;
    showIcons: boolean;
}

export default class MediaMap extends AbstractPureComponent<Props, State> {

    constructor(props, state) {
        super(props, state);
        let currentLocationOptions = getCurrentLocationOptions(`media/${this.props.media.id}`);
        let showLabels = currentLocationOptions && 'showLabels' in currentLocationOptions ? currentLocationOptions.showLabels : false;
        let showIcons = currentLocationOptions && 'showIcons' in currentLocationOptions ? currentLocationOptions.showIcons : true;
        this.state = {showLabels, showIcons};
    }

    render() {
        return (
            <Empty>
                <div className="flex-horizontal justify-end p">
                    <Toolbar
                        toolbarOptions={[
                            {
                                type: 'toggle',
                                value: this.state.showLabels,
                                buttonContentActive: <FormattedMessage id="hideLabels" defaultMessage="Hide Labels"/>,
                                buttonContentNonActive: <FormattedMessage id="showLabels"
                                                                          defaultMessage="Show Labels"/>,
                                onChange: showLabels => {
                                    this.setState({
                                            showLabels,
                                        }, () => setCurrentLocationOptions({
                                            showLabels
                                        }, `media/${this.props.media.id}`)
                                    );
                                }
                            },
                            {
                                type: 'toggle',
                                value: this.state.showIcons,
                                buttonContentActive: <FormattedMessage id="hideIcons" defaultMessage="Hide Icons"/>,
                                buttonContentNonActive: <FormattedMessage id="showIcons" defaultMessage="Show Icons"/>,
                                onChange: showIcons => {
                                    this.setState({
                                            showIcons,
                                        }, () => setCurrentLocationOptions({
                                            showIcons
                                        }, `media/${this.props.media.id}`)
                                    );
                                }
                            }
                        ]}/>
                </div>
                <div className="flex-1 flex-vertical">
                    <ComponsedMediaMapImplementation {...this.props} showIcons={this.state.showIcons}
                                                     showLabels={this.state.showLabels}/>
                </div>
            </Empty>
        );
    }

}

interface ImplementedProps extends Props, GeolocatedProps, LocationLoaderInjectedProps {
    showLabels: boolean;
    showIcons: boolean;
}

interface ImplementedState {
    currentLocation: { lat: number, lng: number };
    newPin: boolean;
    loaded: boolean;
    svLocation: { lat: number, lng: number };
}


class MediaMapImplementation extends AbstractPureComponent<ImplementedProps, ImplementedState> {

    private gmaps;
    private newPinEventData = {longitude: 0, latitude: 0};

    state = {currentLocation: null, newPin: false, loaded: false, svLocation: null};

    private openNewPinDialog(e) {
        this.newPinEventData = {
            longitude: e.latLng.lng(),
            latitude: e.latLng.lat(),
        };
        this.setState({newPin: true});
    }

    private addPin(pin) {
        pin.type = this.props.media.type;
        pin.data = this.newPinEventData;

        pinRequest(pin, this.props.media.id).then((p) => {
            this.history.push(`/project/${this.props.media.projectId}/media/${this.props.media.id}/pin/${p.id}`);
            window.location.reload();
        });
    }

    componentDidMount() {
        this.resetView();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.media.id !== this.props.media.id) {
            this.resetView();
        }
    }

    private resetView() {
        setTimeout(() => this.setState({loaded: true}, () => {
            let latMin = Number.POSITIVE_INFINITY;
            let lngMin = Number.POSITIVE_INFINITY;
            let latMax = Number.NEGATIVE_INFINITY;
            let lngMax = Number.NEGATIVE_INFINITY;

            if (this.props.pins) {
                this.props.pins.forEach(pin => {
                    let p = pin.data as PinMap;
                    let lat = p.latitude;
                    let lng = p.longitude;
                    if (lat < latMin) {
                        latMin = lat;
                    }
                    if (lat > latMax) {
                        latMax = lat;
                    }
                    if (lng < lngMin) {
                        lngMin = lng;
                    }
                    if (lng > lngMax) {
                        lngMax = lng;
                    }
                });
            }

            let mediaMap = this.props.media.data as MediaMapObject;
            if (mediaMap.mediaMapDrawings) {
                mediaMap.mediaMapDrawings.forEach(drawing => {
                    if (drawing.locations) {
                        let locations = JSON.parse(drawing.locations);
                        locations.forEach(location => {
                            let lat = location.latitude;
                            let lng = location.longitude;
                            if (lat < latMin) {
                                latMin = lat;
                            }
                            if (lat > latMax) {
                                latMax = lat;
                            }
                            if (lng < lngMin) {
                                lngMin = lng;
                            }
                            if (lng > lngMax) {
                                lngMax = lng;
                            }
                        });
                    }
                });
            }

            if (latMin == Number.POSITIVE_INFINITY) {
                return;
            }

            this.gmaps.fitBounds(
                {
                    east: lngMax,
                    north: latMin,
                    west: lngMin,
                    south: latMax,
                }
            );
        }));
    }

    render() {
        this.props.handleLocationCallbacks({
            onLocationEnabled: this.onLocationEnabled.bind(this),
        });
        let self = this;
        return (
            <Empty>
                <GoogleMap
                    ref={i => this.gmaps = i}
                    defaultOptions={{
                        disableDoubleClickZoom: true,
                        mapTypeId: (this.props.media.data as MediaMapObject).mapType == MapType.MAP ? google.maps.MapTypeId.ROADMAP : google.maps.MapTypeId.SATELLITE,
                    }}
                    defaultZoom={9}
                    defaultCenter={{
                        lat: (this.props.media.data as MediaMapObject).latitude,
                        lng: (this.props.media.data as MediaMapObject).longitude,
                    }}
                    onDblClick={e => {
                        if (this.props.media.accessLevel == UserType.GUEST ||
                            (AuthUtils.authenticatedUser<User>() && (
                                AuthUtils.authenticatedUser<User>().type == UserType.SUPER ||
                                (AuthUtils.authenticatedUser<User>().type == UserType.ADMIN && (this.props.media.accessLevel == UserType.ADMIN || this.props.media.accessLevel == UserType.USER)) ||
                                (AuthUtils.authenticatedUser<User>().type == UserType.USER && this.props.media.accessLevel == UserType.USER)
                            ))
                        ) {
                            this.openNewPinDialog(e);
                        }
                    }}
                >
                    {this.state.loaded && (
                        <Empty>
                            <StreetViewPanorama onPositionChanged={function () {
                                let location = this.getPosition();
                                self.setState({svLocation: {lat: location.lat(), lng: location.lng()}});
                            }} defaultPosition={{
                                lat: (this.props.media.data as MediaMapObject).latitude,
                                lng: (this.props.media.data as MediaMapObject).longitude,
                            }} visible={(this.props.media.data as MediaMapObject).streetView}>
                                {this.renderMarkers(MAP_MARKER_DISTANCE)}
                            </StreetViewPanorama>
                            {this.renderMarkers()}
                            {this.renderDrawings()}
                        </Empty>
                    )}
                </GoogleMap>

                <PinPopup
                    modalProps={{
                        container: window.document.body,
                        onHide: () => this.setState({newPin: false}),
                        show: this.state.newPin,
                    }}
                    media={this.props.media}
                    onAddPin={(pin) => this.addPin(pin)}
                />
            </Empty>
        );
    }

    private renderMarkers(distance = null) {
        return (
            <Empty>
                {(distance === null || (this.state.svLocation && calculateDistance({
                    lat: (this.props.media.data as MediaMapObject).latitude,
                    lng: (this.props.media.data as MediaMapObject).longitude,
                }, this.state.svLocation) < distance)) && (
                    <MapView
                        latitude={(this.props.media.data as MediaMapObject).latitude}
                        longitude={(this.props.media.data as MediaMapObject).longitude}>
                        <div className="glyphicon-marker-container">
                            <Glyphicon className="text-primary" glyph="map-marker"/>
                        </div>
                    </MapView>
                )}

                {(this.state.currentLocation && (distance === null || (this.state.svLocation && calculateDistance(this.state.currentLocation, this.state.svLocation) < distance))) && (
                    <MapView
                        latitude={this.state.currentLocation.lat}
                        longitude={this.state.currentLocation.lng}>
                        <div className="glyphicon-marker-container">
                            <Glyphicon className="text-primary" glyph="screenshot"/>
                        </div>
                    </MapView>
                )}

                {this.props.pins.map(p => {
                    if (distance === null || (this.state.svLocation && calculateDistance({
                        lat: (p.data as PinMap).latitude,
                        lng: (p.data as PinMap).longitude,
                    }, this.state.svLocation) < distance)) {
                        return (
                            <MapView
                                key={p.id}
                                latitude={(p.data as PinMap).latitude}
                                longitude={(p.data as PinMap).longitude}>
                                <DiscussionPinWrapper
                                    discussion={p.discussion}
                                    textOnHover={!this.props.showLabels}
                                    hideIcons={!this.props.showIcons}
                                    onClick={() => this.props.onPinClick(p)}
                                />
                            </MapView>
                        );
                    }
                })}
            </Empty>
        );
    }

    private renderDrawings() {
        let mediaMap = this.props.media.data as MediaMapObject;
        if (mediaMap.mediaMapDrawings) {
            return mediaMap.mediaMapDrawings.map((drawing, index) => {
                if (drawing.locations) {
                    let locations = JSON.parse(drawing.locations);
                    if (drawing.closed) {
                        locations.push(locations[0]);
                    }
                    return <Polyline key={index} path={locations} options={{
                        geodesic: true,
                        strokeColor: `#${drawing.color}`,
                        strokeWeight: drawing.thickness,
                    }}/>
                } else {
                    return <Empty key={index}/>
                }
            });
        }
        return null;
    }

    onLocationEnabled(coords: Coordinates) {
        setTimeout(() => this.setState({currentLocation: {lat: coords.latitude, lng: coords.longitude}}), 0);
    }

}

class DiscussionPinWrapperClass extends AbstractStatelessComponent<{
    discussion: Discussion;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    onClick?: () => void;
    active?: boolean;
    textOnHover?: boolean;
    style?: any;
    hideIcons?: boolean;
    hoverDiscussionState?: Discussion;
    activeDiscussionState?: Discussion;
    hoverDiscussionDispatcher?: (action: any) => void;
}> {
    render() {

        return <DiscussionPin
            {...this.props}
            onMouseOver={() => this.props.hoverDiscussionDispatcher({
                type: DiscussionHoverActionType.DISCUSSION_HOVER_IN,
                payload: this.props.discussion,
            })}
            onMouseOut={() => this.props.hoverDiscussionDispatcher({
                type: DiscussionHoverActionType.DISCUSSION_HOVER_OUT
            })}
            active={
                this.props.activeDiscussionState && this.props.activeDiscussionState.id == this.props.discussion.id ||
                this.props.hoverDiscussionState && this.props.hoverDiscussionState.id == this.props.discussion.id
            }
        />;
    }
}

let DiscussionPinWrapper = connector(DiscussionPinWrapperClass, {
    mapStateToProps: (state: AppState) => ({
        activeDiscussionState: state.activeDiscussionState,
        hoverDiscussionState: state.hoverDiscussionState,
    }),
    mapDispatchToProps: dispatch => ({
        hoverDiscussionDispatcher: action => dispatch(action),
    }),
});

const ComponsedMediaMapImplementation = compose<ImplementedProps, ImplementedProps>(
    withProps({
        containerElement: <div className="map-container-element"/>,
        mapElement: <div className="map-element"/>,
    }),
    withGoogleMap,
    geolocated({
        watchPosition: true,
    }),
    locationLoader(),
)(MediaMapImplementation);