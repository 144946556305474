export enum UserType {
	SUPER = "SUPER",
	ADMIN = "ADMIN",
	USER = "USER",
	GUEST = "GUEST",
}

export enum MediaType {
	MAP = "MAP",
	IMG = "IMG",
	PDF = "PDF",
	YOUTUBE = "YOUTUBE",
	IFRAME = "IFRAME",
}

export enum PinType {
	MAP = "MAP",
	IMG = "IMG",
	PDF = "PDF",
	YOUTUBE = "YOUTUBE",
	IFRAME = "IFRAME",
}

export enum MapType {
	MAP = "MAP",
	SATELLITE = "SATELLITE",
}

export interface Account {
	id: number;
	users: Array<User>;
	projects: Array<Project>;
	name: string;
	whitelabel: boolean;
	avatars: { file: string, thumbs?: { [key: string] : string } };
	color: string;
	logo: string;
	tagline: string;
	created: Date;
	modified: Date;
	deleted: Date;
}

export interface AuthRequest {
	email: string;
	password: string;
}

export interface AuthResponse {
	token: string;
	user: User;
}

export interface ChecklistItem {
	id: number;
	checklistId: number;
	checklist: Checklist;
	item: string;
	done: boolean;
}

export interface Checklist {
	id: number;
	name: string;
	items: Array<ChecklistItem>;
	discussionId: number;
	discussion: Discussion;
	accessLevel: UserType;
}

export interface Comment {
	id: number;
	parent: number;
	projectId: number;
	project: string;
	mediaIds: number;
	media: string;
	discussionId: number;
	discussion: string;
	tags: Array<string>;
	location: string;
	attachments: string;
	author: string;
	email: string;
	avatar: string;
	isApproved: boolean;
	rawMessage: string;
	message: string;
	points: number;
	likes: number;
	dislikes: number;
	created: Date;
}

export interface Discussion {
	id: number;
	accountId: number;
	projectId: number;
	project: Project;
	userId: number;
	user: User;
	pins: Array<Pin>;
	media: Array<Media>;
	tags: Array<Tag>;
	polls: Array<Poll>;
	votes: Array<Vote>;
	checklists: Array<Checklist>;
	name: string;
	slug: string;
	avatars: { file: string, thumbs?: { [key: string] : string } };
	comments: any;
	created: Date;
	modified: Date;
	deleted: Date;
}

export interface MediaIframe {
	id: number;
	url: string;
}

export interface MediaImg {
	id: number;
	width: number;
	height: number;
	file: string;
}

export interface MediaMapDrawing {
	id: number;
	mediaMapId: number;
	mediaMap: MediaMap;
	locations: string;
	closed: boolean;
	color: string;
	thickness: number;
}

export interface MediaMapImage {
	id: number;
	mediaMapId: number;
	mediaMap: MediaMap;
	nwLongitude: number;
	nwLatitude: number;
	seLongitude: number;
	seLatitude: number;
	width: number;
	height: number;
	img: string;
}

export interface MediaMap {
	id: number;
	longitude: number;
	latitude: number;
	mapType: MapType;
	streetView: boolean;
	mediaMapDrawings: Array<MediaMapDrawing>;
	mediaMapImages: Array<MediaMapImage>;
}

export interface MediaPdf {
	id: number;
	file: string;
}

export interface Media {
	id: number;
	accountId: number;
	projectId: number;
	project: Project;
	userId: number;
	user: User;
	pins: Array<Pin>;
	discussions: Array<Discussion>;
	data: MediaMap | MediaImg | MediaPdf | MediaYoutube | MediaIframe;
	type: MediaType;
	name: string;
	slug: string;
	avatars: { file: string, thumbs?: { [key: string] : string } };
	hasDiscussion: boolean;
	accessLevel: UserType;
	active: boolean;
	order: number;
	comments: any;
	created: Date;
	modified: Date;
	deleted: Date;
}

export interface MediaYoutube {
	id: number;
	videoId: string;
}

export interface PasswordRequest {
	email: string;
}

export interface PasswordUpdate {
	password: string;
	newPassword: string;
	token: string;
}

export interface PinIframe {
	id: number;
}

export interface PinImg {
	id: number;
	x: number;
	y: number;
}

export interface PinMap {
	id: number;
	longitude: number;
	latitude: number;
}

export interface PinPdf {
	id: number;
	page: number;
}

export interface Pin {
	id: number;
	userId: number;
	user: User;
	discussionId: number;
	discussion: Discussion;
	mediaId: number;
	media: Media;
	data: PinMap | PinImg | PinPdf | PinYoutube | PinIframe;
	type: PinType;
	comments: any;
	created: Date;
	modified: Date;
	deleted: Date;
}

export interface PinYoutube {
	id: number;
}

export interface PollAnswerEntry {
	id: number;
	pollAnswerId: number;
	poll: PollAnswer;
	ip: string;
	longitude: number;
	latitude: number;
}

export interface PollAnswer {
	id: number;
	pollId: number;
	poll: Poll;
	answer: string;
	voteCount: any;
}

export interface Poll {
	id: number;
	question: string;
	maxVotes: number;
	answers: Array<PollAnswer>;
	discussionId: number;
	discussion: Discussion;
	accessLevel: UserType;
	used: Array<number>;
}

export interface Project {
	id: number;
	accountId: number;
	account: Account;
	userId: number;
	user: User;
	media: Array<Media>;
	discussions: Array<Discussion>;
	tags: Array<Tag>;
	name: string;
	slug: string;
	avatars: { file: string, thumbs?: { [key: string] : string } };
	description: string;
	longitude: number;
	latitude: number;
	hasDiscussion: boolean;
	accessLevel: UserType;
	openTags: boolean;
	requiredTags: boolean;
	active: boolean;
	comments: any;
	created: Date;
	modified: Date;
	deleted: Date;
}

export interface Tag {
	id: number;
	projectId: any;
	name: string;
	color?: string;
	project: Project;
	discussions: Array<Discussion>;
	created: Date;
	modified: Date;
	deleted: Date;
}

export interface User {
	id: number;
	accountId: number;
	account: Account;
	projects: Array<Project>;
	media: Array<Media>;
	discussions: Array<Discussion>;
	pins: Array<Pin>;
	email: string;
	phone?: string;
	avatars: { file: string, thumbs?: { [key: string] : string } };
	type: UserType;
	created: Date;
	modified: Date;
	deleted: Date;
}

export interface VoteEntry {
	id: number;
	voteId: number;
	vote: Vote;
	up: boolean;
	ip: string;
	longitude: number;
	latitude: number;
}

export interface Vote {
	id: number;
	question: string;
	upVoteLabel: string;
	downVoteLabel: string;
	discussionId: number;
	discussion: Discussion;
	accessLevel: UserType;
	reputation: { up: number, down: number };
	used: { used: boolean, up: boolean };
}

