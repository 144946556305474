import {AnyAction, Reducer} from "redux";
import {Project} from "../type-definitions/types";
import {GlobalActionType} from "lumen-react-javascript";

export enum ProjectHoverActionType {
    PROJECT_HOVER_IN = 'PROJECT_HOVER_IN',
    PROJECT_HOVER_OUT = 'PROJECT_HOVER_OUT',
}

const projectMapHoverReducer: Reducer<Project> = (state: Project = null, action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case ProjectHoverActionType.PROJECT_HOVER_IN:
            return {...action.payload};
        case ProjectHoverActionType.PROJECT_HOVER_OUT:
            return null;
        case GlobalActionType.REFRESH_STORE:
            return {...state};
        default:
            return state;
    }
};

export default projectMapHoverReducer;