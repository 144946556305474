import * as React from 'react';
import config from 'config';
import {DiscussionLoader} from "src/reducers/open-discussion-reducer";
import {AbstractStatelessComponent} from "lumen-react-javascript";
import Disqus from "src/components/disqus";

interface Props {
    discussionLoader: DiscussionLoader;
    projectId: number
}

export default class DisqusAddon extends AbstractStatelessComponent<Props> {

    getDisqusTranslationKey(language: string): string {
        switch (language) {
            case 'sv':
                return 'sv_SE';
            default:
                return language;
        }
    }

    render() {
        let DisqusClass: React.ComponentClass<any> = Disqus as any;
        if (this.props.discussionLoader) {
            return (
                <div style={{flex: 1, overflow: 'hidden'}} className="double-p bg-gray-lighter disqus-container">
                    <DisqusClass shortname={config.disqusShortName} config={{
                        ...this.props.discussionLoader,
                        language: this.getDisqusTranslationKey(this.intl.locale),
                        category_id: this.props.projectId
                    }}/>
                </div>
            );
        } else {
            return null;
        }
    }

}
