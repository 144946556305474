import * as React from 'react';
import {WheelEvent} from 'react';
import {Discussion, Media, MediaImg as MediaImgObject, Pin, PinImg, User, UserType} from "src/type-definitions/types";
import './media-img.less';
import {compose} from "recompose";
import {connect} from "react-redux";
import {AppState} from "src/reducers";
import {DiscussionHoverActionType} from "src/reducers/hover-discussion-reducer";
import PinPopup from "public/components/media/pin-popup";
import {pinRequest} from "src/type-definitions/endpoints";
import {AbstractComponent, AuthUtils, Empty, isMobile} from "lumen-react-javascript";
import {prefix} from 'inline-style-prefixer';
import {FormattedMessage} from "react-intl";
import DiscussionPin from "src/components/discussion-pin";
import {getCurrentLocationOptions, setCurrentLocationOptions} from "src/utils/cookie-options";
import Toolbar from "src/components/misc/toolbar";
import ReactHammer from "react-hammerjs";
import * as is from 'is_js';

interface ExternalProps {
    pins: Array<Pin>;
    onPinClick: (pin: Pin) => void;
    media: Media;
}

interface Props extends ExternalProps {
    hoverDiscussionState?: Discussion;
    activeDiscussionState?: Discussion;
    hoverDiscussionDispatcher?: (action: any) => void;
}

interface State {
    deltaX: number;
    deltaY: number;
    x: number;
    y: number;
    scale: number;
    scaleDelta: number;
    moving: boolean;
    newPin: boolean;
    showLabels: boolean;
    showIcons: boolean;
}

export class MediaImg extends AbstractComponent<Props, State> {

    private init = false;
    private parent = null;
    private child = null;
    private newPinEventData = {x: 0, y: 0};

    constructor(props, context) {
        super(props, context);
        let currentLocationOptions = getCurrentLocationOptions(`media/${this.props.media.id}`);
        let showLabels = currentLocationOptions && 'showLabels' in currentLocationOptions ? currentLocationOptions.showLabels : false;
        let showIcons = currentLocationOptions && 'showIcons' in currentLocationOptions ? currentLocationOptions.showIcons : true;
        this.state = {
            deltaX: 0,
            deltaY: 0,
            x: 0,
            y: 0,
            scale: 1,
            moving: false,
            newPin: false,
            scaleDelta: 0,
            showLabels,
            showIcons
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextProps.pins.every(p1 => this.props.pins.findIndex(p2 => p1.id == p2.id) >= 0) || !this.props.pins.every(p1 => nextProps.pins.findIndex(p2 => p1.id == p2.id) >= 0)) {
            setTimeout(() => {
                if (this.parent) {
                    let x = (this.props.media.data as MediaImgObject).width / this.parent.clientWidth;
                    let y = (this.props.media.data as MediaImgObject).height / this.parent.clientHeight;
                    this.setState({
                        deltaX: 0, deltaY: 0, x: 0, y: 0, scale: 1 / Math.max(x, y), moving: false
                    });
                }
            });
        }
        return true;
    }

    private dragStart(e) {
        this.setState({x: e.clientX, y: e.clientY, moving: true});
        e.preventDefault();
        e.stopPropagation();
    }

    private drag(e) {
        if (e.touches && e.touches.length == 2) {
            this.setState({scale: Math.max(0.01, 0.01)});
        } else if (this.state.moving) {
            let deltaX = this.state.deltaX + (e.clientX - this.state.x);
            let deltaY = this.state.deltaY + (e.clientY - this.state.y);
            this.setState({
                deltaX,
                deltaY,
                x: e.clientX,
                y: e.clientY,
            });
        }
        e.preventDefault();
        e.stopPropagation();
    }

    private dragStop(e) {
        this.setState({moving: false});
        e.preventDefault();
        e.stopPropagation();
    }

    private wheel(e: WheelEvent<HTMLDivElement>) {
        let delta = e.nativeEvent.wheelDelta || e.nativeEvent.deltaY;
        if (is.windows()) {
            this.setState({scale: Math.max(this.state.scale - (delta / 1000), 0.001)});
        } else {
            this.setState({scale: Math.max(this.state.scale + (delta / 1000), 0.001)});
        }
        e.preventDefault();
        e.stopPropagation();
    }

    private openNewPinDialog(e) {
        if (this.props.media.accessLevel == UserType.GUEST ||
            (AuthUtils.authenticatedUser<User>() && (
                AuthUtils.authenticatedUser<User>().type == UserType.SUPER ||
                (AuthUtils.authenticatedUser<User>().type == UserType.ADMIN && (this.props.media.accessLevel == UserType.ADMIN || this.props.media.accessLevel == UserType.USER)) ||
                (AuthUtils.authenticatedUser<User>().type == UserType.USER && this.props.media.accessLevel == UserType.USER)
            ))
        ) {
            this.newPinEventData = {
                x: Math.round(e.offsetX),
                y: Math.round(e.offsetY),
            };
            this.setState({newPin: true});
        }
    }

    private addPin(pin) {
        pin.type = this.props.media.type;
        pin.data = this.newPinEventData;

        pinRequest(pin, this.props.media.id).then((p) => {
            this.history.push(`/project/${this.props.media.projectId}/media/${this.props.media.id}/pin/${p.id}`);
            window.location.reload();
        });
    }

    render() {
        return (
            <Empty>
                <div className="flex-horizontal justify-end p">
                    {this.renderToolbar()}
                </div>
                {isMobile() ? (
                    <ReactHammer
                        onPinchStart={e => {
                            this.setState({scaleDelta: e.scale});
                        }}
                        onPinch={e => {
                            let scaleDelta = (e.scale - this.state.scaleDelta) / 3;
                            this.setState({
                                scaleDelta: e.scale,
                                scale: Math.max(this.state.scale + scaleDelta, 0.001),
                            });
                        }}
                        onPanStart={e => {
                            this.setState({
                                x: e.center.x,
                                y: e.center.y,
                            });
                        }}
                        onPan={e => {
                            let deltaX = this.state.deltaX + (e.center.x - this.state.x);
                            let deltaY = this.state.deltaY + (e.center.y - this.state.y);
                            this.setState({
                                deltaX,
                                deltaY,
                                x: e.center.x,
                                y: e.center.y,
                            });
                        }}
                        options={{
                            recognizers: {
                                pinch: {enable: true},
                            },
                        }}>
                        <div
                            className="media-img-container"
                            id="media-img-container"
                        >
                            <Empty ref={i => {
                                this.parent = document.getElementById("media-img-container");
                                if (this.parent && !this.init) {
                                    setTimeout(() => {
                                        let x = (this.props.media.data as MediaImgObject).width / this.parent.clientWidth;
                                        let y = (this.props.media.data as MediaImgObject).height / this.parent.clientHeight;
                                        this.setState({scale: 1 / Math.max(x, y)});
                                    }, 10);
                                    this.init = true;
                                }
                            }}>
                                {this.renderMediaImage(true)}
                            </Empty>
                        </div>
                    </ReactHammer>
                ) : (
                    <div
                        className="media-img-container"
                        draggable={false}
                        onMouseDown={(e) => this.dragStart(e)}
                        onMouseMove={(e) => this.drag(e)}
                        onMouseUp={(e) => this.dragStop(e)}
                        onMouseLeave={(e) => this.dragStop(e)}
                        onWheel={(e) => this.wheel(e)}
                        ref={i => {
                            this.parent = i;
                            if (this.parent && !this.init) {
                                setTimeout(() => {
                                    let x = (this.props.media.data as MediaImgObject).width / this.parent.clientWidth;
                                    let y = (this.props.media.data as MediaImgObject).height / this.parent.clientHeight;
                                    this.setState({scale: 1 / Math.max(x, y)});
                                }, 10);
                                this.init = true;
                            }
                        }}
                    >
                        {this.renderMediaImage()}
                    </div>
                )}
                {this.renderPopup()}
            </Empty>
        );
    }

    private renderPopup() {
        return (
            <PinPopup
                modalProps={{
                    container: window.document.body,
                    onHide: () => this.setState({newPin: false}),
                    show: this.state.newPin,
                }}
                media={this.props.media}
                onAddPin={(pin) => this.addPin(pin)}
            />
        );
    }

    private renderToolbar() {
        return (
            <Toolbar
                toolbarOptions={[
                    {
                        type: 'toggle',
                        value: this.state.showLabels,
                        buttonContentActive: <FormattedMessage id="hideLabels" defaultMessage="Hide Labels"/>,
                        buttonContentNonActive: <FormattedMessage id="showLabels"
                                                                  defaultMessage="Show Labels"/>,
                        onChange: showLabels => {
                            this.setState({
                                    showLabels,
                                }, () => setCurrentLocationOptions({
                                    showLabels
                                }, `media/${this.props.media.id}`)
                            );
                        }
                    },
                    {
                        type: 'toggle',
                        value: this.state.showIcons,
                        buttonContentActive: <FormattedMessage id="hideIcons" defaultMessage="Hide Icons"/>,
                        buttonContentNonActive: <FormattedMessage id="showIcons" defaultMessage="Show Icons"/>,
                        onChange: showIcons => {
                            this.setState({
                                    showIcons,
                                }, () => setCurrentLocationOptions({
                                    showIcons
                                }, `media/${this.props.media.id}`)
                            );
                        }
                    }
                ]}
            />
        );
    }

    private renderMediaImage(hammer = false) {
        let mediaImgStyle = prefix({
            width: (this.props.media.data as MediaImgObject).width,
            height: (this.props.media.data as MediaImgObject).height,
            transform: `translate(-50%, -50%) translate(${this.state.deltaX}px, ${this.state.deltaY}px) scale(${this.state.scale})`,
            backgroundImage: `url(${(this.props.media.data as MediaImgObject).file})`,
        });

        let div = (
            <div className="media-img-img"
                 style={mediaImgStyle}
                 onDoubleClick={e => this.openNewPinDialog(e.nativeEvent)}
                 draggable={false}
                 ref={i => this.child = i}>
                {this.props.pins.map(p => {
                    let style = prefix({transform: `translate(${(p.data as PinImg).x}px, ${(p.data as PinImg).y}px) scale(${1 / this.state.scale})`});
                    return (
                        <div key={p.id} className="position-absolute" style={style}>
                            <DiscussionPin
                                discussion={p.discussion}
                                textOnHover={!this.state.showLabels}
                                hideIcons={!this.state.showIcons}
                                active={
                                    this.props.activeDiscussionState && this.props.activeDiscussionState.id == p.discussionId ||
                                    this.props.hoverDiscussionState && this.props.hoverDiscussionState.id == p.discussionId
                                }
                                onClick={() => this.props.onPinClick(p)}
                                onMouseOver={() => this.props.hoverDiscussionDispatcher({
                                    type: DiscussionHoverActionType.DISCUSSION_HOVER_IN,
                                    payload: p.discussion
                                })}
                                onMouseOut={() => this.props.hoverDiscussionDispatcher({
                                    type: DiscussionHoverActionType.DISCUSSION_HOVER_OUT
                                })}/>
                        </div>
                    );
                })}
            </div>
        );

        if (hammer) {
            return (
                <ReactHammer onDoubleTap={e => {
                    const rect = e.target.getBoundingClientRect();
                    this.openNewPinDialog({
                        offsetX: (e.center.x - rect.left) / this.state.scale,
                        offsetY: (e.center.y - rect.top) / this.state.scale,
                    });
                }}>
                    {div}
                </ReactHammer>
            )
        } else {
            return div;
        }
    }

}

export default compose<Props, ExternalProps>(
    connect(
        (state: AppState) => ({
            hoverDiscussionState: state.hoverDiscussionState,
            activeDiscussionState: state.activeDiscussionState,
        }),
        dispatch => ({
            hoverDiscussionDispatcher: action => dispatch(action),
        })
    )
)(MediaImg);