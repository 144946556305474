import * as React from 'react';
import {
    MediaStateProp,
    mediaStatePropsMapper,
    ProjectStateProp,
    projectStatePropsMapper
} from "src/type-definitions/reducers";
import {ActionType, mediaDispatchBinder, MediaDispatcher} from "src/type-definitions/actions";
import {RouteComponentProps} from "react-router";
import './medias.less';
import {DiscussionActionType, DiscussionLoader} from "src/reducers/open-discussion-reducer";
import {DiscussionActiveActionType} from "src/reducers/active-discussion-reducer";
import Scrollbars from 'react-custom-scrollbars';
import {AbstractStatelessComponent, connect, LabelType, resolveLoader} from "lumen-react-javascript";
import classNames from 'classnames';
import MediaLabel from "src/components/media/media-label";

interface Props extends MediaStateProp, ProjectStateProp, MediaDispatcher, RouteComponentProps<{ projectId: number, mediaId: number }> {
    openDiscussionDispatcher: (payload: DiscussionLoader) => void;
    activeDiscussionDispatcher: (action: any) => void;
    closeDiscussionDispatcher: () => void;
}

export class Medias extends AbstractStatelessComponent<Props> {

    render() {
        if (!this.props.projectState) {
            return resolveLoader();
        }

        return (
            <div id="medias">
                <Scrollbars autoHide hideTracksWhenNotNeeded className="scrollbars">
                    {this.props.projectState.media.map(media =>
                        <div
                            className={classNames(
                                "unselectable",
                                "media-button",
                                {"active": this.props.mediaState && this.props.mediaState.id == media.id},
                            )}
                            key={media.id}
                            onClick={() => {
                                this.props.closeDiscussionDispatcher();
                                this.props.mediaDispatcher(media.id, {
                                    requestConfig: {with: 'pins.discussion'},
                                    then: media => {
                                        if (media.hasDiscussion) {
                                            this.props.activeDiscussionDispatcher({
                                                type: DiscussionActiveActionType.DISCUSSION_ACTIVE_OUT,
                                            });
                                            this.props.openDiscussionDispatcher({
                                                url: `${window.location.origin}/project/${this.props.projectState.id}/media/${media.id}`,
                                                identifier: `media-discussion-${media.id}`,
                                                title: `General discussion for ${media.name}`,
                                                type: 'media',
                                            });
                                        }
                                    }
                                });
                                this.history.push(`/project/${this.props.match.params.projectId}/media/${media.id}`);
                            }}>
                            <MediaLabel _media={media} labelType={LabelType.MEDIUM}/>
                        </div>
                    )}
                </Scrollbars>
            </div>
        );
    }

}

export default connect(Medias,
    state => ({
        ...mediaStatePropsMapper(state),
        ...projectStatePropsMapper(state),
    }),
    dispatch => ({
        ...mediaDispatchBinder(dispatch),
        closeDiscussionDispatcher: () => dispatch({
            type: ActionType.DISCUSSION,
            payload: null,
        }),
        openDiscussionDispatcher: (payload: DiscussionLoader) => dispatch({
            type: DiscussionActionType.LOAD_DISCUSSION,
            payload
        }),
        activeDiscussionDispatcher: action => dispatch(action),
    }));