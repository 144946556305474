import * as React from "react";
import {AbstractStatelessPureComponent} from "lumen-react-javascript";
import MapView from "src/components/map/map-view";
import {Glyphicon} from "react-bootstrap";

interface Props {
    latitude: number;
    longitude: number;
}

export default class CurrentLocationMarker extends AbstractStatelessPureComponent<Props> {

    render() {
        let {latitude, longitude} = this.props;
        return (
            <MapView
                latitude={latitude}
                longitude={longitude}>
                <div className="glyphicon-marker-container">
                    <Glyphicon className="text-primary" glyph="screenshot"/>
                </div>
            </MapView>
        );
    }

}