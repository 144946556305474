import {Reducer} from "redux";
import {Account, Discussion, Project, User} from "src/type-definitions/types";
import {
    BaseAppState,
    BaseAppStateReducer,
    createAppStateReducers,
    createRootReducer
} from "src/type-definitions/reducers";
import authReducer from "./auth-reducer";
import openDiscussionReducer, {DiscussionLoader} from "src/reducers/open-discussion-reducer";
import projectMapHoverReducer from "src/reducers/project-map-hover-reducer";
import hoverDiscussionReducer from "src/reducers/hover-discussion-reducer";
import activeDiscussionReducer from "src/reducers/active-discussion-reducer";
import accountStyleReducer from "src/reducers/account-style-reducer";

export interface AppState extends BaseAppState {
    authenticatedUser: User;
    openDiscussionState: DiscussionLoader;
    projectMapHoverState: Project;
    hoverDiscussionState: Discussion;
    activeDiscussionState: Discussion;
    accountStyleState: Account;
}

export interface AppStateReducer extends BaseAppStateReducer<AppState> {
    authenticatedUser: Reducer<User>;
    openDiscussionState: Reducer<DiscussionLoader>;
    projectMapHoverState: Reducer<Project>;
    hoverDiscussionState: Reducer<Discussion>;
    activeDiscussionState: Reducer<Discussion>;
    accountStyleState: Reducer<Account>;
}

export const rootReducer: Reducer<AppState> = createRootReducer<AppState>(createAppStateReducers<AppState, AppStateReducer>({
    authenticatedUser: authReducer,
    openDiscussionState: openDiscussionReducer,
    projectMapHoverState: projectMapHoverReducer,
    hoverDiscussionState: hoverDiscussionReducer,
    activeDiscussionState: activeDiscussionReducer,
    accountStyleState: accountStyleReducer,
}));

export default rootReducer;