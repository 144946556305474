import {AnyAction, Reducer} from "redux";
import {Account} from "../type-definitions/types";

export enum AccountStyleActionType {
    ACCOUNT_STYLE_IN = 'ACCOUNT_STYLE_IN',
    ACCOUNT_STYLE_OUT = 'ACCOUNT_STYLE_OUT',
}

export const APPLICATION_LOADED_EVENT = "APPLICATION_LOADED_EVENT";

const accountStyleReducer: Reducer<Account> = (state: Account = null, action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case AccountStyleActionType.ACCOUNT_STYLE_IN:
            let account = action.payload;

            if (account.whitelabel) {
                if (account.color) {
                    let style = document.createElement('style');
                    style.innerText = `.text-primary {color: #${account.color} !important;}`;
                    style.innerText += `.bg-primary {background-color: #${account.color} !important;}`;
                    style.innerText += `.btn-primary {background-color: #${account.color} !important; border-color: #${account.color} !important;}`;
                    //style.innerText += `#medias .media-button.active {background-color: #${account.color} !important;}`;
                    style.innerText += `.media-img-container .media-img-img .media-img-pin {background-color: #${account.color} !important;}`;
                    style.innerText += `a {color: #${account.color} !important;}`;
                    style.innerText += `.badge {background-color: #${account.color}; }`;
                    document.head.appendChild(style);
                }
                if (account.tagline) {
                    document.getElementById('payoff-container').innerText = account.tagline;
                    document.title = `${account.name} - ${account.tagline}`;
                }
                if (account.logo) {
                    document.getElementById('site-logo').setAttribute("src", account.logo);
                    document.getElementById('site-logo').setAttribute("title", account.name);
                    let image = new Image();
                    image.onload = () => {
                        window.dispatchEvent(new CustomEvent(APPLICATION_LOADED_EVENT));
                    };
                    image.src = account.logo;
                } else {
                    window.dispatchEvent(new CustomEvent(APPLICATION_LOADED_EVENT));
                }

                let favicon = (account.avatars && account.avatars.thumbs) ? account.avatars.thumbs['100x100'] : (account.logo ? account.logo : null);

                if (favicon) {
                    // Favicon
                    let link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
                    link.type = 'image/x-icon';
                    link.rel = 'shortcut icon';
                    link.href = favicon;
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                return {...action.payload};
            } else {
                window.dispatchEvent(new CustomEvent(APPLICATION_LOADED_EVENT));
                return state;
            }
        case AccountStyleActionType.ACCOUNT_STYLE_OUT:
            window.location.reload();
            return null;
        default:
            return state;
    }
};

export default accountStyleReducer;