import * as React from 'react';
import {AxiosRequestConfiguration, requestConfigToTableState, TableState} from "lumen-react-javascript";
import {defineMessages, FormattedMessage} from 'react-intl';
import {Label} from "react-bootstrap";

export const DEFAULT_REQUEST_CONFIG: Partial<AxiosRequestConfiguration> = {
    offset: 0,
    limit: 25,
    order: {'id': 'desc'},
};

export const DEFAULT_TABLE_STATE: TableState =
    requestConfigToTableState(DEFAULT_REQUEST_CONFIG);

export const DEFAULT_TABLE_MESSAGES = defineMessages({
    first: {
        id: 'first',
        defaultMessage: 'First',
    },
    last: {
        id: 'last',
        defaultMessage: 'Last',
    },
    next: {
        id: 'next',
        defaultMessage: 'Next',
    },
    previous: {
        id: 'previous',
        defaultMessage: 'Previous',
    },
    tableShowingFooterWithObject: {
        id: 'tableShowingFooterWithObject',
        defaultMessage: 'Showing {from} to {to} of {size} {objects}',
    },
    tableShowingFooter: {
        id: 'tableShowingFooter',
        defaultMessage: 'Showing {from} to {to} of {size} results',
    },
});

export const YES_NO_SELECT_FILTER_OPTIONS = {
    translate: true,
    options: {1: 'yes', 0: 'no'},
};

export const YES_NO_FORMATTER = cell => cell ?
    <Label bsStyle="success"><FormattedMessage id="yes" defaultMessage="Yes"/></Label> :
    <Label bsStyle="danger"><FormattedMessage id="no" defaultMessage="No"/></Label>;

export const USER_TYPE_SELECT_FILTER_OPTIONS = {
    options: {
        'SUPER': 'SUPER',
        'ADMIN': 'ADMIN',
        'USER': 'USER',
        'GUEST': 'GUEST',
    },
};

export const MEDIA_TYPE_SELECT_FILTER_OPTIONS = {
    translate: true,
    options: {
        'MAP': 'MAP',
        'IMG': 'IMG',
        'PDF': 'PDF',
        'YOUTUBE': 'YOUTUBE',
        'IFRAME': 'IFRAME',
    },
};

export const MAP_TYPE_SELECT_FILTER_OPTIONS = {
    translate: true,
    options: {
        'MAP': 'MAP',
        'SATELLITE': 'SATELLITE',
    },
};

export const DEFAULT_TABLE_PROPS = {
    striped: true,
    hover: true,
    condensed: true,
};

const dropdownMessages = defineMessages({
    dropdownOpen: {
        id: 'dropdownOpen',
        defaultMessage: 'Open',
    },
    dropdownEmptyList: {
        id: 'dropdownEmptyList',
        defaultMessage: 'No results',
    },
    dropdownEmptyFilter: {
        id: 'dropdownEmptyFilter',
        defaultMessage: 'Empty filter',
    },
    dropdownFilterPlaceholder: {
        id: 'dropdownFilterPlaceholder',
        defaultMessage: 'Filter',
    },
    dropdownCreateOption: {
        id: 'dropdownCreateOption',
        defaultMessage: 'Create',
    },
});

export const DROPDOWN_MESSAGES = (intl) => ({
    open: intl.formatMessage(dropdownMessages.dropdownOpen),
    emptyList: intl.formatMessage(dropdownMessages.dropdownEmptyList),
    emptyFilter: intl.formatMessage(dropdownMessages.dropdownEmptyFilter),
    filterPlaceholder: intl.formatMessage(dropdownMessages.dropdownFilterPlaceholder),
    createOption: intl.formatMessage(dropdownMessages.dropdownCreateOption),
});

const multiselectMessages = defineMessages({
    multiselectOpen: {
        id: 'multiselectOpen',
        defaultMessage: 'Open',
    },
    multiselectEmptyList: {
        id: 'multiselectEmptyList',
        defaultMessage: 'No results',
    },
    multiselectEmptyFilter: {
        id: 'multiselectEmptyFilter',
        defaultMessage: 'Empty filter',
    },
    multiselectCreateOption: {
        id: 'multiselectCreateOption',
        defaultMessage: 'Create',
    },
    multiselectTagsLabel: {
        id: 'multiselectTagsLabel',
        defaultMessage: 'Tags',
    },
    multiselectSelectedItems: {
        id: 'multiselectSelectedItems',
        defaultMessage: 'Selected',
    },
    multiselectNoneSelected: {
        id: 'multiselectNoneSelected',
        defaultMessage: 'None selected',
    },
    multiselectRemoveLabel: {
        id: 'multiselectRemoveLabel',
        defaultMessage: 'Remove',
    },
});

export const MULTISELECT_MESSAGES = (intl) => ({
    open: intl.formatMessage(multiselectMessages.multiselectOpen),
    emptyList: intl.formatMessage(multiselectMessages.multiselectEmptyList),
    emptyFilter: intl.formatMessage(multiselectMessages.multiselectEmptyFilter),
    createOption: intl.formatMessage(multiselectMessages.multiselectCreateOption),
    tagsLabel: intl.formatMessage(multiselectMessages.multiselectTagsLabel),
    selectedItems: intl.formatMessage(multiselectMessages.multiselectSelectedItems),
    noneSelected: intl.formatMessage(multiselectMessages.multiselectNoneSelected),
    removeLabel: intl.formatMessage(multiselectMessages.multiselectRemoveLabel),
});