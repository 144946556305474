import * as React from 'react';
import {ProjectsInBoundsStateProp, projectsInBoundsStatePropsMapper,} from "src/type-definitions/reducers";
import {AppState} from "src/reducers/index";
import {Project} from "src/type-definitions/types";
import {ProjectHoverActionType} from "src/reducers/project-map-hover-reducer";
import ProjectLabel from "src/components/project/project-label";
import {FormattedMessage} from 'react-intl';
import {AbstractStatelessComponent, connector, LabelType} from "lumen-react-javascript";

interface Props extends ProjectsInBoundsStateProp {
    projectMapHoverState: Project;
    projectMapHoverDispatcher: (action) => void;
}

class Home extends AbstractStatelessComponent<Props> {

    render() {
        return (
            <div className="bg-gray-lighter">
                <table className="table table-bordered bg-gray-lighter animated-table" style={{margin: 0}}>
                    <tbody>
                    {this.props.projectsInBoundsState.length ? this.props.projectsInBoundsState.map(p => (
                        <tr
                            style={{cursor: 'pointer'}}
                            key={p.id}
                            className={(this.props.projectMapHoverState && this.props.projectMapHoverState.id == p.id) ? 'bg-primary' : ''}
                            onClick={() => {
                                this.history.push(`/project/${p.id}`);
                            }}
                            onMouseOver={() => this.props.projectMapHoverDispatcher({
                                payload: p,
                                type: ProjectHoverActionType.PROJECT_HOVER_IN
                            })}
                            onMouseOut={() => this.props.projectMapHoverDispatcher({
                                payload: null,
                                type: ProjectHoverActionType.PROJECT_HOVER_OUT
                            })}
                        >
                            <td>
                                <ProjectLabel project={p} labelType={LabelType.MEDIUM}/>
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td>
                                <i>
                                    <FormattedMessage id="noProjectsFound"
                                                      defaultMessage="No projects found in the current view"/>
                                </i>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        );
    }

}

export default connector(
    Home,
    {
        mapStateToProps: (state: AppState) => ({
            ...projectsInBoundsStatePropsMapper(state),
            projectMapHoverState: state.projectMapHoverState,
        }),
        mapDispatchToProps: dispatch => ({
            projectMapHoverDispatcher: (action) => dispatch(action),
        }),
    },
);
