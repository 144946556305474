import * as React from 'react';
import {Media, Pin} from "src/type-definitions/types";
import YouTube from "react-youtube";
import {AbstractComponent} from "lumen-react-javascript";

interface Props {
    pins: Array<Pin>;
    onPinClick: (pin: Pin) => void;
    media: Media;
}

export default class MediaYoutube extends AbstractComponent<Props, {}> {

    render() {
        return (
            <div className="flex-1 position-relative">
                <YouTube
                    opts={{
                        height: '100%',
                        width: '100%',
                    }}
                    videoId={(this.props.media.data as any).videoId}
                />
            </div>
        );
    }

}