import axios, {AxiosResponse, AxiosError, AxiosInstance} from 'axios';
import {AxiosRequestConfiguration, ExtendedResponseData, getUrl, parseHeadersToApiResponseHeaders} from 'lumen-react-javascript';
import _ from 'lodash';
import { AuthRequest, AuthResponse, User, Account, Project, Media, Discussion, Pin, Poll, Vote, Checklist, MediaMapImage, MediaMapDrawing, Comment, Tag, PasswordRequest, PasswordUpdate } from './types';

const apiDateFields = [
    'created',
    'modified',
    'deleted',
];

export interface FilesInput {
	[key: string]: File | Array<File>;
}

export function convertApiFields<T>(object: T): T {
    if (_.isArray(object)) {
        let ret: any = [];
        let array: Array<any> = object as any;
        for (let i = 0; i < array.length; i++) {
            ret[i] = convertApiFields<T>(array[i]);
        }
        return ret as T;
    } else if (_.isObject(object) && !_.isNull(object)) {
        let ret: any = {};
        for (let key in object) {
            if (_.isNull(object[key])) {
                ret[key] = null;
            } else if (_.isArray(object[key])) {
                ret[key] = [];
                let array: Array<any> = object[key] as any;
                for (let i = 0; i < array.length; i++) {
                    ret[key][i] = convertApiFields<any>(array[i]);
                }
            } else if (_.isObject(object[key])) {
                ret[key] = convertApiFields<any>(object[key]);
            } else {
                ret[key] = convertApiField(key, object[key]);
            }
        }
        return ret as T;
    } else {
        return object;
    }
}

export function convertApiField(key: string, value: any): any {
	let ret = value;
	if (_.indexOf(apiDateFields, key) >= 0) {
		ret = new Date(value);
	}
	return ret;
}

export function createFormData(files, input?): FormData {
	let formData = new FormData();
	for (let key in files) {
		if (_.isArray(files[key])) {
			for (let i in files[key]) {
				formData.append(`${key}[${i}]`, files[key][i]);
			}
		} else {
			formData.append(`${key}`, files[key]);
		}
	}
	if (input) {
		for (let key in input) {
			formData.append(key, input[key]);
		}
	}
	return formData;
}

export function loginRequest(input: AuthRequest, requestConfig?: AxiosRequestConfiguration): Promise<AuthResponse> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<AuthResponse>((resolve, reject) => {
		instance.post<AuthResponse>(
			getUrl(`/auth`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<AuthResponse>) => {
			resolve(convertApiFields<AuthResponse>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function loginRequestExtended(input: AuthRequest, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<AuthResponse>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<AuthResponse>>((resolve, reject) => {
		instance.post<AuthResponse>(
			getUrl(`/auth`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<AuthResponse>) => {
			resolve({
				data: convertApiFields<AuthResponse>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function whoRequest(requestConfig?: AxiosRequestConfiguration): Promise<User> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<User>((resolve, reject) => {
		instance.get<User>(
			getUrl(`/auth`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve(convertApiFields<User>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function whoRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<User>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<User>>((resolve, reject) => {
		instance.get<User>(
			getUrl(`/auth`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve({
				data: convertApiFields<User>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function logoutRequest(requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/auth`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function logoutRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/auth`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function accountIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<Account>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Account>>((resolve, reject) => {
		instance.get<Array<Account>>(
			getUrl(`/private/account`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Account>>) => {
			resolve(convertApiFields<Array<Account>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function accountIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Account>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Account>>>((resolve, reject) => {
		instance.get<Array<Account>>(
			getUrl(`/private/account`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Account>>) => {
			resolve({
				data: convertApiFields<Array<Account>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function accountStoreRequest(input: Account, requestConfig?: AxiosRequestConfiguration): Promise<Account> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Account>((resolve, reject) => {
		instance.put<Account>(
			getUrl(`/private/account`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Account>) => {
			resolve(convertApiFields<Account>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function accountStoreRequestExtended(input: Account, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Account>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Account>>((resolve, reject) => {
		instance.put<Account>(
			getUrl(`/private/account`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Account>) => {
			resolve({
				data: convertApiFields<Account>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function accountShowRequest(accountId: number, requestConfig?: AxiosRequestConfiguration): Promise<Account> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Account>((resolve, reject) => {
		instance.get<Account>(
			getUrl(`/private/account/${accountId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Account>) => {
			resolve(convertApiFields<Account>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function accountShowRequestExtended(accountId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Account>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Account>>((resolve, reject) => {
		instance.get<Account>(
			getUrl(`/private/account/${accountId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Account>) => {
			resolve({
				data: convertApiFields<Account>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function accountUpdateRequest(input: Account, requestConfig?: AxiosRequestConfiguration): Promise<Account> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Account>((resolve, reject) => {
		instance.post<Account>(
			getUrl(`/private/account/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Account>) => {
			resolve(convertApiFields<Account>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function accountUpdateRequestExtended(input: Account, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Account>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Account>>((resolve, reject) => {
		instance.post<Account>(
			getUrl(`/private/account/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Account>) => {
			resolve({
				data: convertApiFields<Account>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function accountDestroyRequest(accountId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/account/${accountId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function accountDestroyRequestExtended(accountId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/account/${accountId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setAccountAvatarRequest(accountId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/account/${accountId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setAccountAvatarRequestExtended(accountId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/account/${accountId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setAccountLogoRequest(accountId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/account/${accountId}/logo`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setAccountLogoRequestExtended(accountId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/account/${accountId}/logo`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<Project>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Project>>((resolve, reject) => {
		instance.get<Array<Project>>(
			getUrl(`/private/project`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Project>>) => {
			resolve(convertApiFields<Array<Project>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Project>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Project>>>((resolve, reject) => {
		instance.get<Array<Project>>(
			getUrl(`/private/project`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Project>>) => {
			resolve({
				data: convertApiFields<Array<Project>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectStoreRequest(input: Project, requestConfig?: AxiosRequestConfiguration): Promise<Project> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Project>((resolve, reject) => {
		instance.put<Project>(
			getUrl(`/private/project`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Project>) => {
			resolve(convertApiFields<Project>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectStoreRequestExtended(input: Project, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Project>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Project>>((resolve, reject) => {
		instance.put<Project>(
			getUrl(`/private/project`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Project>) => {
			resolve({
				data: convertApiFields<Project>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectShowRequest(projectId: number, requestConfig?: AxiosRequestConfiguration): Promise<Project> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Project>((resolve, reject) => {
		instance.get<Project>(
			getUrl(`/private/project/${projectId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Project>) => {
			resolve(convertApiFields<Project>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectShowRequestExtended(projectId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Project>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Project>>((resolve, reject) => {
		instance.get<Project>(
			getUrl(`/private/project/${projectId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Project>) => {
			resolve({
				data: convertApiFields<Project>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectUpdateRequest(input: Project, requestConfig?: AxiosRequestConfiguration): Promise<Project> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Project>((resolve, reject) => {
		instance.post<Project>(
			getUrl(`/private/project/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Project>) => {
			resolve(convertApiFields<Project>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectUpdateRequestExtended(input: Project, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Project>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Project>>((resolve, reject) => {
		instance.post<Project>(
			getUrl(`/private/project/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Project>) => {
			resolve({
				data: convertApiFields<Project>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectDestroyRequest(projectId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/project/${projectId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectDestroyRequestExtended(projectId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/project/${projectId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setProjectAvatarRequest(projectId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/project/${projectId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setProjectAvatarRequestExtended(projectId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/project/${projectId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<Media>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Media>>((resolve, reject) => {
		instance.get<Array<Media>>(
			getUrl(`/private/media`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Media>>) => {
			resolve(convertApiFields<Array<Media>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Media>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Media>>>((resolve, reject) => {
		instance.get<Array<Media>>(
			getUrl(`/private/media`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Media>>) => {
			resolve({
				data: convertApiFields<Array<Media>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaStoreRequest(input: Media, requestConfig?: AxiosRequestConfiguration): Promise<Media> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Media>((resolve, reject) => {
		instance.put<Media>(
			getUrl(`/private/media`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Media>) => {
			resolve(convertApiFields<Media>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaStoreRequestExtended(input: Media, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Media>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Media>>((resolve, reject) => {
		instance.put<Media>(
			getUrl(`/private/media`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Media>) => {
			resolve({
				data: convertApiFields<Media>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaShowRequest(mediaId: number, requestConfig?: AxiosRequestConfiguration): Promise<Media> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Media>((resolve, reject) => {
		instance.get<Media>(
			getUrl(`/private/media/${mediaId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Media>) => {
			resolve(convertApiFields<Media>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaShowRequestExtended(mediaId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Media>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Media>>((resolve, reject) => {
		instance.get<Media>(
			getUrl(`/private/media/${mediaId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Media>) => {
			resolve({
				data: convertApiFields<Media>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaUpdateRequest(input: Media, requestConfig?: AxiosRequestConfiguration): Promise<Media> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Media>((resolve, reject) => {
		instance.post<Media>(
			getUrl(`/private/media/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Media>) => {
			resolve(convertApiFields<Media>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaUpdateRequestExtended(input: Media, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Media>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Media>>((resolve, reject) => {
		instance.post<Media>(
			getUrl(`/private/media/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Media>) => {
			resolve({
				data: convertApiFields<Media>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaDestroyRequest(mediaId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/media/${mediaId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaDestroyRequestExtended(mediaId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/media/${mediaId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setMediaAvatarRequest(mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/media/${mediaId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setMediaAvatarRequestExtended(mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/media/${mediaId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<User>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<User>>((resolve, reject) => {
		instance.get<Array<User>>(
			getUrl(`/private/user`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<User>>) => {
			resolve(convertApiFields<Array<User>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<User>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<User>>>((resolve, reject) => {
		instance.get<Array<User>>(
			getUrl(`/private/user`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<User>>) => {
			resolve({
				data: convertApiFields<Array<User>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userStoreRequest(input: User, requestConfig?: AxiosRequestConfiguration): Promise<User> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<User>((resolve, reject) => {
		instance.put<User>(
			getUrl(`/private/user`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve(convertApiFields<User>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userStoreRequestExtended(input: User, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<User>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<User>>((resolve, reject) => {
		instance.put<User>(
			getUrl(`/private/user`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve({
				data: convertApiFields<User>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userShowRequest(userId: number, requestConfig?: AxiosRequestConfiguration): Promise<User> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<User>((resolve, reject) => {
		instance.get<User>(
			getUrl(`/private/user/${userId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve(convertApiFields<User>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userShowRequestExtended(userId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<User>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<User>>((resolve, reject) => {
		instance.get<User>(
			getUrl(`/private/user/${userId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve({
				data: convertApiFields<User>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userUpdateRequest(input: User, requestConfig?: AxiosRequestConfiguration): Promise<User> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<User>((resolve, reject) => {
		instance.post<User>(
			getUrl(`/private/user/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve(convertApiFields<User>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userUpdateRequestExtended(input: User, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<User>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<User>>((resolve, reject) => {
		instance.post<User>(
			getUrl(`/private/user/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<User>) => {
			resolve({
				data: convertApiFields<User>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userDestroyRequest(userId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/user/${userId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function userDestroyRequestExtended(userId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/user/${userId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setUserAvatarRequest(userId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/user/${userId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setUserAvatarRequestExtended(userId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/user/${userId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<Discussion>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Discussion>>((resolve, reject) => {
		instance.get<Array<Discussion>>(
			getUrl(`/private/discussion`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Discussion>>) => {
			resolve(convertApiFields<Array<Discussion>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Discussion>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Discussion>>>((resolve, reject) => {
		instance.get<Array<Discussion>>(
			getUrl(`/private/discussion`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Discussion>>) => {
			resolve({
				data: convertApiFields<Array<Discussion>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionStoreRequest(input: Discussion, requestConfig?: AxiosRequestConfiguration): Promise<Discussion> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Discussion>((resolve, reject) => {
		instance.put<Discussion>(
			getUrl(`/private/discussion`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Discussion>) => {
			resolve(convertApiFields<Discussion>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionStoreRequestExtended(input: Discussion, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Discussion>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Discussion>>((resolve, reject) => {
		instance.put<Discussion>(
			getUrl(`/private/discussion`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Discussion>) => {
			resolve({
				data: convertApiFields<Discussion>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionShowRequest(discussionId: number, requestConfig?: AxiosRequestConfiguration): Promise<Discussion> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Discussion>((resolve, reject) => {
		instance.get<Discussion>(
			getUrl(`/private/discussion/${discussionId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Discussion>) => {
			resolve(convertApiFields<Discussion>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionShowRequestExtended(discussionId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Discussion>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Discussion>>((resolve, reject) => {
		instance.get<Discussion>(
			getUrl(`/private/discussion/${discussionId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Discussion>) => {
			resolve({
				data: convertApiFields<Discussion>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionUpdateRequest(input: Discussion, requestConfig?: AxiosRequestConfiguration): Promise<Discussion> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Discussion>((resolve, reject) => {
		instance.post<Discussion>(
			getUrl(`/private/discussion/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Discussion>) => {
			resolve(convertApiFields<Discussion>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionUpdateRequestExtended(input: Discussion, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Discussion>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Discussion>>((resolve, reject) => {
		instance.post<Discussion>(
			getUrl(`/private/discussion/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Discussion>) => {
			resolve({
				data: convertApiFields<Discussion>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionDestroyRequest(discussionId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/discussion/${discussionId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionDestroyRequestExtended(discussionId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/discussion/${discussionId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setDiscussionAvatarRequest(discussionId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/discussion/${discussionId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setDiscussionAvatarRequestExtended(discussionId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/discussion/${discussionId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pinIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<Pin>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Pin>>((resolve, reject) => {
		instance.get<Array<Pin>>(
			getUrl(`/private/pin`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Pin>>) => {
			resolve(convertApiFields<Array<Pin>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pinIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Pin>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Pin>>>((resolve, reject) => {
		instance.get<Array<Pin>>(
			getUrl(`/private/pin`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Pin>>) => {
			resolve({
				data: convertApiFields<Array<Pin>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pinStoreRequest(input: Pin, requestConfig?: AxiosRequestConfiguration): Promise<Pin> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Pin>((resolve, reject) => {
		instance.put<Pin>(
			getUrl(`/private/pin`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Pin>) => {
			resolve(convertApiFields<Pin>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pinStoreRequestExtended(input: Pin, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Pin>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Pin>>((resolve, reject) => {
		instance.put<Pin>(
			getUrl(`/private/pin`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Pin>) => {
			resolve({
				data: convertApiFields<Pin>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pinShowRequest(pinId: number, requestConfig?: AxiosRequestConfiguration): Promise<Pin> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Pin>((resolve, reject) => {
		instance.get<Pin>(
			getUrl(`/private/pin/${pinId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Pin>) => {
			resolve(convertApiFields<Pin>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pinShowRequestExtended(pinId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Pin>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Pin>>((resolve, reject) => {
		instance.get<Pin>(
			getUrl(`/private/pin/${pinId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Pin>) => {
			resolve({
				data: convertApiFields<Pin>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pinUpdateRequest(input: Pin, requestConfig?: AxiosRequestConfiguration): Promise<Pin> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Pin>((resolve, reject) => {
		instance.post<Pin>(
			getUrl(`/private/pin/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Pin>) => {
			resolve(convertApiFields<Pin>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pinUpdateRequestExtended(input: Pin, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Pin>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Pin>>((resolve, reject) => {
		instance.post<Pin>(
			getUrl(`/private/pin/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Pin>) => {
			resolve({
				data: convertApiFields<Pin>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pinDestroyRequest(pinId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/pin/${pinId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pinDestroyRequestExtended(pinId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/pin/${pinId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pollIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<Poll>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Poll>>((resolve, reject) => {
		instance.get<Array<Poll>>(
			getUrl(`/private/poll`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Poll>>) => {
			resolve(convertApiFields<Array<Poll>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pollIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Poll>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Poll>>>((resolve, reject) => {
		instance.get<Array<Poll>>(
			getUrl(`/private/poll`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Poll>>) => {
			resolve({
				data: convertApiFields<Array<Poll>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pollStoreRequest(input: Poll, requestConfig?: AxiosRequestConfiguration): Promise<Poll> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Poll>((resolve, reject) => {
		instance.put<Poll>(
			getUrl(`/private/poll`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Poll>) => {
			resolve(convertApiFields<Poll>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pollStoreRequestExtended(input: Poll, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Poll>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Poll>>((resolve, reject) => {
		instance.put<Poll>(
			getUrl(`/private/poll`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Poll>) => {
			resolve({
				data: convertApiFields<Poll>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pollShowRequest(pollId: number, requestConfig?: AxiosRequestConfiguration): Promise<Poll> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Poll>((resolve, reject) => {
		instance.get<Poll>(
			getUrl(`/private/poll/${pollId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Poll>) => {
			resolve(convertApiFields<Poll>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pollShowRequestExtended(pollId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Poll>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Poll>>((resolve, reject) => {
		instance.get<Poll>(
			getUrl(`/private/poll/${pollId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Poll>) => {
			resolve({
				data: convertApiFields<Poll>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pollUpdateRequest(input: Poll, requestConfig?: AxiosRequestConfiguration): Promise<Poll> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Poll>((resolve, reject) => {
		instance.post<Poll>(
			getUrl(`/private/poll/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Poll>) => {
			resolve(convertApiFields<Poll>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pollUpdateRequestExtended(input: Poll, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Poll>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Poll>>((resolve, reject) => {
		instance.post<Poll>(
			getUrl(`/private/poll/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Poll>) => {
			resolve({
				data: convertApiFields<Poll>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pollDestroyRequest(pollId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/poll/${pollId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pollDestroyRequestExtended(pollId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/poll/${pollId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setPollAvatarRequest(pollId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/poll/${pollId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setPollAvatarRequestExtended(pollId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/poll/${pollId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function voteIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<Vote>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Vote>>((resolve, reject) => {
		instance.get<Array<Vote>>(
			getUrl(`/private/vote`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Vote>>) => {
			resolve(convertApiFields<Array<Vote>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function voteIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Vote>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Vote>>>((resolve, reject) => {
		instance.get<Array<Vote>>(
			getUrl(`/private/vote`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Vote>>) => {
			resolve({
				data: convertApiFields<Array<Vote>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function voteStoreRequest(input: Vote, requestConfig?: AxiosRequestConfiguration): Promise<Vote> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Vote>((resolve, reject) => {
		instance.put<Vote>(
			getUrl(`/private/vote`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Vote>) => {
			resolve(convertApiFields<Vote>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function voteStoreRequestExtended(input: Vote, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Vote>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Vote>>((resolve, reject) => {
		instance.put<Vote>(
			getUrl(`/private/vote`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Vote>) => {
			resolve({
				data: convertApiFields<Vote>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function voteShowRequest(voteId: number, requestConfig?: AxiosRequestConfiguration): Promise<Vote> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Vote>((resolve, reject) => {
		instance.get<Vote>(
			getUrl(`/private/vote/${voteId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Vote>) => {
			resolve(convertApiFields<Vote>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function voteShowRequestExtended(voteId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Vote>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Vote>>((resolve, reject) => {
		instance.get<Vote>(
			getUrl(`/private/vote/${voteId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Vote>) => {
			resolve({
				data: convertApiFields<Vote>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function voteUpdateRequest(input: Vote, requestConfig?: AxiosRequestConfiguration): Promise<Vote> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Vote>((resolve, reject) => {
		instance.post<Vote>(
			getUrl(`/private/vote/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Vote>) => {
			resolve(convertApiFields<Vote>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function voteUpdateRequestExtended(input: Vote, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Vote>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Vote>>((resolve, reject) => {
		instance.post<Vote>(
			getUrl(`/private/vote/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Vote>) => {
			resolve({
				data: convertApiFields<Vote>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function voteDestroyRequest(voteId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/vote/${voteId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function voteDestroyRequestExtended(voteId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/vote/${voteId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setVoteAvatarRequest(voteId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/vote/${voteId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setVoteAvatarRequestExtended(voteId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/vote/${voteId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function checklistIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<Checklist>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Checklist>>((resolve, reject) => {
		instance.get<Array<Checklist>>(
			getUrl(`/private/checklist`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Checklist>>) => {
			resolve(convertApiFields<Array<Checklist>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function checklistIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Checklist>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Checklist>>>((resolve, reject) => {
		instance.get<Array<Checklist>>(
			getUrl(`/private/checklist`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Checklist>>) => {
			resolve({
				data: convertApiFields<Array<Checklist>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function checklistStoreRequest(input: Checklist, requestConfig?: AxiosRequestConfiguration): Promise<Checklist> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Checklist>((resolve, reject) => {
		instance.put<Checklist>(
			getUrl(`/private/checklist`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Checklist>) => {
			resolve(convertApiFields<Checklist>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function checklistStoreRequestExtended(input: Checklist, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Checklist>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Checklist>>((resolve, reject) => {
		instance.put<Checklist>(
			getUrl(`/private/checklist`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Checklist>) => {
			resolve({
				data: convertApiFields<Checklist>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function checklistShowRequest(checklistId: number, requestConfig?: AxiosRequestConfiguration): Promise<Checklist> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Checklist>((resolve, reject) => {
		instance.get<Checklist>(
			getUrl(`/private/checklist/${checklistId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Checklist>) => {
			resolve(convertApiFields<Checklist>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function checklistShowRequestExtended(checklistId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Checklist>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Checklist>>((resolve, reject) => {
		instance.get<Checklist>(
			getUrl(`/private/checklist/${checklistId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Checklist>) => {
			resolve({
				data: convertApiFields<Checklist>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function checklistUpdateRequest(input: Checklist, requestConfig?: AxiosRequestConfiguration): Promise<Checklist> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Checklist>((resolve, reject) => {
		instance.post<Checklist>(
			getUrl(`/private/checklist/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Checklist>) => {
			resolve(convertApiFields<Checklist>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function checklistUpdateRequestExtended(input: Checklist, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Checklist>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Checklist>>((resolve, reject) => {
		instance.post<Checklist>(
			getUrl(`/private/checklist/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Checklist>) => {
			resolve({
				data: convertApiFields<Checklist>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function checklistDestroyRequest(checklistId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/checklist/${checklistId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function checklistDestroyRequestExtended(checklistId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/checklist/${checklistId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setChecklistAvatarRequest(checklistId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/checklist/${checklistId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function setChecklistAvatarRequestExtended(checklistId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/checklist/${checklistId}/avatar`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapImageIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<MediaMapImage>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<MediaMapImage>>((resolve, reject) => {
		instance.get<Array<MediaMapImage>>(
			getUrl(`/private/media-map-image`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<MediaMapImage>>) => {
			resolve(convertApiFields<Array<MediaMapImage>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapImageIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<MediaMapImage>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<MediaMapImage>>>((resolve, reject) => {
		instance.get<Array<MediaMapImage>>(
			getUrl(`/private/media-map-image`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<MediaMapImage>>) => {
			resolve({
				data: convertApiFields<Array<MediaMapImage>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapImageStoreRequest(input: MediaMapImage, requestConfig?: AxiosRequestConfiguration): Promise<MediaMapImage> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<MediaMapImage>((resolve, reject) => {
		instance.put<MediaMapImage>(
			getUrl(`/private/media-map-image`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<MediaMapImage>) => {
			resolve(convertApiFields<MediaMapImage>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapImageStoreRequestExtended(input: MediaMapImage, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<MediaMapImage>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<MediaMapImage>>((resolve, reject) => {
		instance.put<MediaMapImage>(
			getUrl(`/private/media-map-image`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<MediaMapImage>) => {
			resolve({
				data: convertApiFields<MediaMapImage>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapImageShowRequest(mediaMapImageId: number, requestConfig?: AxiosRequestConfiguration): Promise<MediaMapImage> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<MediaMapImage>((resolve, reject) => {
		instance.get<MediaMapImage>(
			getUrl(`/private/media-map-image/${mediaMapImageId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<MediaMapImage>) => {
			resolve(convertApiFields<MediaMapImage>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapImageShowRequestExtended(mediaMapImageId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<MediaMapImage>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<MediaMapImage>>((resolve, reject) => {
		instance.get<MediaMapImage>(
			getUrl(`/private/media-map-image/${mediaMapImageId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<MediaMapImage>) => {
			resolve({
				data: convertApiFields<MediaMapImage>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapImageUpdateRequest(input: MediaMapImage, requestConfig?: AxiosRequestConfiguration): Promise<MediaMapImage> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<MediaMapImage>((resolve, reject) => {
		instance.post<MediaMapImage>(
			getUrl(`/private/media-map-image/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<MediaMapImage>) => {
			resolve(convertApiFields<MediaMapImage>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapImageUpdateRequestExtended(input: MediaMapImage, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<MediaMapImage>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<MediaMapImage>>((resolve, reject) => {
		instance.post<MediaMapImage>(
			getUrl(`/private/media-map-image/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<MediaMapImage>) => {
			resolve({
				data: convertApiFields<MediaMapImage>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapImageDestroyRequest(mediaMapImageId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/media-map-image/${mediaMapImageId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapImageDestroyRequestExtended(mediaMapImageId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/media-map-image/${mediaMapImageId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapDrawingIndexRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<MediaMapDrawing>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<MediaMapDrawing>>((resolve, reject) => {
		instance.get<Array<MediaMapDrawing>>(
			getUrl(`/private/media-map-drawing`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<MediaMapDrawing>>) => {
			resolve(convertApiFields<Array<MediaMapDrawing>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapDrawingIndexRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<MediaMapDrawing>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<MediaMapDrawing>>>((resolve, reject) => {
		instance.get<Array<MediaMapDrawing>>(
			getUrl(`/private/media-map-drawing`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<MediaMapDrawing>>) => {
			resolve({
				data: convertApiFields<Array<MediaMapDrawing>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapDrawingStoreRequest(input: MediaMapDrawing, requestConfig?: AxiosRequestConfiguration): Promise<MediaMapDrawing> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<MediaMapDrawing>((resolve, reject) => {
		instance.put<MediaMapDrawing>(
			getUrl(`/private/media-map-drawing`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<MediaMapDrawing>) => {
			resolve(convertApiFields<MediaMapDrawing>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapDrawingStoreRequestExtended(input: MediaMapDrawing, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<MediaMapDrawing>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<MediaMapDrawing>>((resolve, reject) => {
		instance.put<MediaMapDrawing>(
			getUrl(`/private/media-map-drawing`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<MediaMapDrawing>) => {
			resolve({
				data: convertApiFields<MediaMapDrawing>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapDrawingShowRequest(mediaMapDrawingId: number, requestConfig?: AxiosRequestConfiguration): Promise<MediaMapDrawing> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<MediaMapDrawing>((resolve, reject) => {
		instance.get<MediaMapDrawing>(
			getUrl(`/private/media-map-drawing/${mediaMapDrawingId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<MediaMapDrawing>) => {
			resolve(convertApiFields<MediaMapDrawing>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapDrawingShowRequestExtended(mediaMapDrawingId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<MediaMapDrawing>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<MediaMapDrawing>>((resolve, reject) => {
		instance.get<MediaMapDrawing>(
			getUrl(`/private/media-map-drawing/${mediaMapDrawingId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<MediaMapDrawing>) => {
			resolve({
				data: convertApiFields<MediaMapDrawing>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapDrawingUpdateRequest(input: MediaMapDrawing, requestConfig?: AxiosRequestConfiguration): Promise<MediaMapDrawing> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<MediaMapDrawing>((resolve, reject) => {
		instance.post<MediaMapDrawing>(
			getUrl(`/private/media-map-drawing/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<MediaMapDrawing>) => {
			resolve(convertApiFields<MediaMapDrawing>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapDrawingUpdateRequestExtended(input: MediaMapDrawing, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<MediaMapDrawing>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<MediaMapDrawing>>((resolve, reject) => {
		instance.post<MediaMapDrawing>(
			getUrl(`/private/media-map-drawing/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<MediaMapDrawing>) => {
			resolve({
				data: convertApiFields<MediaMapDrawing>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapDrawingDestroyRequest(mediaMapDrawingId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/media-map-drawing/${mediaMapDrawingId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapDrawingDestroyRequestExtended(mediaMapDrawingId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.delete(
			getUrl(`/private/media-map-drawing/${mediaMapDrawingId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaImgRequest(mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/media/${mediaId}/img`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaImgRequestExtended(mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/media/${mediaId}/img`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaPdfRequest(mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/media/${mediaId}/pdf`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaPdfRequestExtended(mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/media/${mediaId}/pdf`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapImgRequest(mediaMapImageId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/media/map/${mediaMapImageId}/img`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaMapImgRequestExtended(mediaMapImageId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/media/map/${mediaMapImageId}/img`, requestConfig),
			createFormData(files),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionCommentsRequest(discussionId: number, requestConfig?: AxiosRequestConfiguration): Promise<Array<Comment>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Comment>>((resolve, reject) => {
		instance.get<Array<Comment>>(
			getUrl(`/private/discussion/${discussionId}/comments`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Comment>>) => {
			resolve(convertApiFields<Array<Comment>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionCommentsRequestExtended(discussionId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Comment>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Comment>>>((resolve, reject) => {
		instance.get<Array<Comment>>(
			getUrl(`/private/discussion/${discussionId}/comments`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Comment>>) => {
			resolve({
				data: convertApiFields<Array<Comment>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaCommentsRequest(mediaId: number, requestConfig?: AxiosRequestConfiguration): Promise<Array<Comment>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Comment>>((resolve, reject) => {
		instance.get<Array<Comment>>(
			getUrl(`/private/media/${mediaId}/comments`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Comment>>) => {
			resolve(convertApiFields<Array<Comment>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaCommentsRequestExtended(mediaId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Comment>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Comment>>>((resolve, reject) => {
		instance.get<Array<Comment>>(
			getUrl(`/private/media/${mediaId}/comments`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Comment>>) => {
			resolve({
				data: convertApiFields<Array<Comment>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectCommentsRequest(projectId: number, requestConfig?: AxiosRequestConfiguration): Promise<Array<Comment>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Comment>>((resolve, reject) => {
		instance.get<Array<Comment>>(
			getUrl(`/private/project/${projectId}/comments`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Comment>>) => {
			resolve(convertApiFields<Array<Comment>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectCommentsRequestExtended(projectId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Comment>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Comment>>>((resolve, reject) => {
		instance.get<Array<Comment>>(
			getUrl(`/private/project/${projectId}/comments`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Comment>>) => {
			resolve({
				data: convertApiFields<Array<Comment>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function commentApproveRequest(commentId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/comment/approve/${commentId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function commentApproveRequestExtended(commentId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/comment/approve/${commentId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function commentSpamRequest(commentId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/comment/spam/${commentId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function commentSpamRequestExtended(commentId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/private/comment/spam/${commentId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function tagUpdateRequest(input: Tag, requestConfig?: AxiosRequestConfiguration): Promise<Tag> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Tag>((resolve, reject) => {
		instance.post<Tag>(
			getUrl(`/private/tag/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Tag>) => {
			resolve(convertApiFields<Tag>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function tagUpdateRequestExtended(input: Tag, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Tag>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Tag>>((resolve, reject) => {
		instance.post<Tag>(
			getUrl(`/private/tag/${input.id}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Tag>) => {
			resolve({
				data: convertApiFields<Tag>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function accountRequest(accountId: number, requestConfig?: AxiosRequestConfiguration): Promise<Account> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Account>((resolve, reject) => {
		instance.get<Account>(
			getUrl(`/public/account/${accountId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Account>) => {
			resolve(convertApiFields<Account>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function accountRequestExtended(accountId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Account>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Account>>((resolve, reject) => {
		instance.get<Account>(
			getUrl(`/public/account/${accountId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Account>) => {
			resolve({
				data: convertApiFields<Account>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectsRequest(requestConfig?: AxiosRequestConfiguration): Promise<Array<Project>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Project>>((resolve, reject) => {
		instance.get<Array<Project>>(
			getUrl(`/public/project`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Project>>) => {
			resolve(convertApiFields<Array<Project>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectsRequestExtended(requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Project>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Project>>>((resolve, reject) => {
		instance.get<Array<Project>>(
			getUrl(`/public/project`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Project>>) => {
			resolve({
				data: convertApiFields<Array<Project>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectsInBoundsRequest(swLat: number, swLng: number, neLat: number, neLng: number, requestConfig?: AxiosRequestConfiguration): Promise<Array<Project>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Project>>((resolve, reject) => {
		instance.get<Array<Project>>(
			getUrl(`/public/projectsInBounds/${swLat}/${swLng}/${neLat}/${neLng}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Project>>) => {
			resolve(convertApiFields<Array<Project>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectsInBoundsRequestExtended(swLat: number, swLng: number, neLat: number, neLng: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Project>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Project>>>((resolve, reject) => {
		instance.get<Array<Project>>(
			getUrl(`/public/projectsInBounds/${swLat}/${swLng}/${neLat}/${neLng}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Project>>) => {
			resolve({
				data: convertApiFields<Array<Project>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectRequest(projectId: number, requestConfig?: AxiosRequestConfiguration): Promise<Project> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Project>((resolve, reject) => {
		instance.get<Project>(
			getUrl(`/public/project/${projectId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Project>) => {
			resolve(convertApiFields<Project>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectRequestExtended(projectId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Project>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Project>>((resolve, reject) => {
		instance.get<Project>(
			getUrl(`/public/project/${projectId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Project>) => {
			resolve({
				data: convertApiFields<Project>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function showProjectByAliasRequest(projectName: string, requestConfig?: AxiosRequestConfiguration): Promise<Project> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Project>((resolve, reject) => {
		instance.get<Project>(
			getUrl(`/public/project/alias/${projectName}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Project>) => {
			resolve(convertApiFields<Project>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function showProjectByAliasRequestExtended(projectName: string, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Project>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Project>>((resolve, reject) => {
		instance.get<Project>(
			getUrl(`/public/project/alias/${projectName}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Project>) => {
			resolve({
				data: convertApiFields<Project>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaRequest(mediaId: number, requestConfig?: AxiosRequestConfiguration): Promise<Media> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Media>((resolve, reject) => {
		instance.get<Media>(
			getUrl(`/public/media/${mediaId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Media>) => {
			resolve(convertApiFields<Media>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function mediaRequestExtended(mediaId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Media>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Media>>((resolve, reject) => {
		instance.get<Media>(
			getUrl(`/public/media/${mediaId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Media>) => {
			resolve({
				data: convertApiFields<Media>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function showMediaByAliasRequest(mediaName: string, requestConfig?: AxiosRequestConfiguration): Promise<Media> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Media>((resolve, reject) => {
		instance.get<Media>(
			getUrl(`/public/media/alias/${mediaName}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Media>) => {
			resolve(convertApiFields<Media>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function showMediaByAliasRequestExtended(mediaName: string, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Media>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Media>>((resolve, reject) => {
		instance.get<Media>(
			getUrl(`/public/media/alias/${mediaName}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Media>) => {
			resolve({
				data: convertApiFields<Media>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionRequest(discussionId: number, requestConfig?: AxiosRequestConfiguration): Promise<Discussion> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Discussion>((resolve, reject) => {
		instance.get<Discussion>(
			getUrl(`/public/discussion/${discussionId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Discussion>) => {
			resolve(convertApiFields<Discussion>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function discussionRequestExtended(discussionId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Discussion>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Discussion>>((resolve, reject) => {
		instance.get<Discussion>(
			getUrl(`/public/discussion/${discussionId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Discussion>) => {
			resolve({
				data: convertApiFields<Discussion>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function showDiscussionByAliasRequest(discussionName: string, requestConfig?: AxiosRequestConfiguration): Promise<Discussion> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Discussion>((resolve, reject) => {
		instance.get<Discussion>(
			getUrl(`/public/discussion/alias/${discussionName}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Discussion>) => {
			resolve(convertApiFields<Discussion>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function showDiscussionByAliasRequestExtended(discussionName: string, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Discussion>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Discussion>>((resolve, reject) => {
		instance.get<Discussion>(
			getUrl(`/public/discussion/alias/${discussionName}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Discussion>) => {
			resolve({
				data: convertApiFields<Discussion>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectDiscussionsRequest(projectId: number, requestConfig?: AxiosRequestConfiguration): Promise<Array<Discussion>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Array<Discussion>>((resolve, reject) => {
		instance.get<Array<Discussion>>(
			getUrl(`/public/project/${projectId}/discussion`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Discussion>>) => {
			resolve(convertApiFields<Array<Discussion>>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function projectDiscussionsRequestExtended(projectId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Array<Discussion>>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Array<Discussion>>>((resolve, reject) => {
		instance.get<Array<Discussion>>(
			getUrl(`/public/project/${projectId}/discussion`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<Array<Discussion>>) => {
			resolve({
				data: convertApiFields<Array<Discussion>>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pinRequest(input: Pin, mediaId: number, requestConfig?: AxiosRequestConfiguration): Promise<Pin> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<Pin>((resolve, reject) => {
		instance.put<Pin>(
			getUrl(`/public/pin/${mediaId}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Pin>) => {
			resolve(convertApiFields<Pin>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pinRequestExtended(input: Pin, mediaId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<Pin>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<Pin>>((resolve, reject) => {
		instance.put<Pin>(
			getUrl(`/public/pin/${mediaId}`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<Pin>) => {
			resolve({
				data: convertApiFields<Pin>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function requestPasswordRequest(input: PasswordRequest, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/public/request-password`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function requestPasswordRequestExtended(input: PasswordRequest, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/public/request-password`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function updatePasswordRequest(input: PasswordUpdate, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/public/update-password`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function updatePasswordRequestExtended(input: PasswordUpdate, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.post<void>(
			getUrl(`/public/update-password`, requestConfig),
			input,
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pollAnswerRequest(pollId: number, pollAnswerId: number, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.put<void>(
			getUrl(`/public/poll/${pollId}/answer/${pollAnswerId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function pollAnswerRequestExtended(pollId: number, pollAnswerId: number, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.put<void>(
			getUrl(`/public/poll/${pollId}/answer/${pollAnswerId}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function voteAnswerRequest(voteId: number, voteAnswer: boolean, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.put<void>(
			getUrl(`/public/vote/${voteId}/${voteAnswer}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function voteAnswerRequestExtended(voteId: number, voteAnswer: boolean, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.put<void>(
			getUrl(`/public/vote/${voteId}/${voteAnswer}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function checklistItemDoneRequest(checklistId: number, checklistItemId: number, checklistDone: boolean, requestConfig?: AxiosRequestConfiguration): Promise<void> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<void>((resolve, reject) => {
		instance.put<void>(
			getUrl(`/public/checklist/${checklistId}/${checklistItemId}/${checklistDone}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve(convertApiFields<void>(response.data));	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function checklistItemDoneRequestExtended(checklistId: number, checklistItemId: number, checklistDone: boolean, requestConfig?: AxiosRequestConfiguration): Promise<ExtendedResponseData<void>> {
	let instance: AxiosInstance = (requestConfig && requestConfig.axios) ? requestConfig.axios : axios; 
	return new Promise<ExtendedResponseData<void>>((resolve, reject) => {
		instance.put<void>(
			getUrl(`/public/checklist/${checklistId}/${checklistItemId}/${checklistDone}`, requestConfig),
			requestConfig,
		).then((response: AxiosResponse<void>) => {
			resolve({
				data: convertApiFields<void>(response.data),
				apiResponseHeaders: parseHeadersToApiResponseHeaders(response.headers),
			    allHeaders: response.headers,
			    code: response.status,
			});	
		}).catch((error: AxiosError) => {
			reject(error);
		});
	});
}

export function loginRequestLoaderChunk(request: (props, _: (input: AuthRequest, requestConfig?: AxiosRequestConfiguration) => Promise<AuthResponse>) => Promise<AuthResponse>): { [key: string]: (props) => Promise<AuthResponse> } {
	return { login: (props) => request(props, loginRequest) };
}

export function loginRequestExtendedLoaderChunk(request: (props, _: (input: AuthRequest, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<AuthResponse>>) => Promise<ExtendedResponseData<AuthResponse>>): { [key: string]: (props) => Promise<ExtendedResponseData<AuthResponse>> } {
	return { loginExtended: (props) => request(props, loginRequestExtended) };
}

export function whoRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { [key: string]: (props) => Promise<User> } {
	return { who: (props) => request(props, whoRequest) };
}

export function whoRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { [key: string]: (props) => Promise<ExtendedResponseData<User>> } {
	return { whoExtended: (props) => request(props, whoRequestExtended) };
}

export function logoutRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { logout: (props) => request(props, logoutRequest) };
}

export function logoutRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { logoutExtended: (props) => request(props, logoutRequestExtended) };
}

export function accountIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Account>>) => Promise<Array<Account>>): { [key: string]: (props) => Promise<Array<Account>> } {
	return { accountIndex: (props) => request(props, accountIndexRequest) };
}

export function accountIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Account>>>) => Promise<ExtendedResponseData<Array<Account>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Account>>> } {
	return { accountIndexExtended: (props) => request(props, accountIndexRequestExtended) };
}

export function accountStoreRequestLoaderChunk(request: (props, _: (input: Account, requestConfig?: AxiosRequestConfiguration) => Promise<Account>) => Promise<Account>): { [key: string]: (props) => Promise<Account> } {
	return { accountStore: (props) => request(props, accountStoreRequest) };
}

export function accountStoreRequestExtendedLoaderChunk(request: (props, _: (input: Account, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Account>>) => Promise<ExtendedResponseData<Account>>): { [key: string]: (props) => Promise<ExtendedResponseData<Account>> } {
	return { accountStoreExtended: (props) => request(props, accountStoreRequestExtended) };
}

export function accountShowRequestLoaderChunk(request: (props, _: (accountId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Account>) => Promise<Account>): { [key: string]: (props) => Promise<Account> } {
	return { accountShow: (props) => request(props, accountShowRequest) };
}

export function accountShowRequestExtendedLoaderChunk(request: (props, _: (accountId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Account>>) => Promise<ExtendedResponseData<Account>>): { [key: string]: (props) => Promise<ExtendedResponseData<Account>> } {
	return { accountShowExtended: (props) => request(props, accountShowRequestExtended) };
}

export function accountUpdateRequestLoaderChunk(request: (props, _: (input: Account, requestConfig?: AxiosRequestConfiguration) => Promise<Account>) => Promise<Account>): { [key: string]: (props) => Promise<Account> } {
	return { accountUpdate: (props) => request(props, accountUpdateRequest) };
}

export function accountUpdateRequestExtendedLoaderChunk(request: (props, _: (input: Account, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Account>>) => Promise<ExtendedResponseData<Account>>): { [key: string]: (props) => Promise<ExtendedResponseData<Account>> } {
	return { accountUpdateExtended: (props) => request(props, accountUpdateRequestExtended) };
}

export function accountDestroyRequestLoaderChunk(request: (props, _: (accountId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { accountDestroy: (props) => request(props, accountDestroyRequest) };
}

export function accountDestroyRequestExtendedLoaderChunk(request: (props, _: (accountId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { accountDestroyExtended: (props) => request(props, accountDestroyRequestExtended) };
}

export function setAccountAvatarRequestLoaderChunk(request: (props, _: (accountId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { setAccountAvatar: (props) => request(props, setAccountAvatarRequest) };
}

export function setAccountAvatarRequestExtendedLoaderChunk(request: (props, _: (accountId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { setAccountAvatarExtended: (props) => request(props, setAccountAvatarRequestExtended) };
}

export function setAccountLogoRequestLoaderChunk(request: (props, _: (accountId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { setAccountLogo: (props) => request(props, setAccountLogoRequest) };
}

export function setAccountLogoRequestExtendedLoaderChunk(request: (props, _: (accountId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { setAccountLogoExtended: (props) => request(props, setAccountLogoRequestExtended) };
}

export function projectIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Project>>) => Promise<Array<Project>>): { [key: string]: (props) => Promise<Array<Project>> } {
	return { projectIndex: (props) => request(props, projectIndexRequest) };
}

export function projectIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Project>>>) => Promise<ExtendedResponseData<Array<Project>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Project>>> } {
	return { projectIndexExtended: (props) => request(props, projectIndexRequestExtended) };
}

export function projectStoreRequestLoaderChunk(request: (props, _: (input: Project, requestConfig?: AxiosRequestConfiguration) => Promise<Project>) => Promise<Project>): { [key: string]: (props) => Promise<Project> } {
	return { projectStore: (props) => request(props, projectStoreRequest) };
}

export function projectStoreRequestExtendedLoaderChunk(request: (props, _: (input: Project, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Project>>) => Promise<ExtendedResponseData<Project>>): { [key: string]: (props) => Promise<ExtendedResponseData<Project>> } {
	return { projectStoreExtended: (props) => request(props, projectStoreRequestExtended) };
}

export function projectShowRequestLoaderChunk(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Project>) => Promise<Project>): { [key: string]: (props) => Promise<Project> } {
	return { projectShow: (props) => request(props, projectShowRequest) };
}

export function projectShowRequestExtendedLoaderChunk(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Project>>) => Promise<ExtendedResponseData<Project>>): { [key: string]: (props) => Promise<ExtendedResponseData<Project>> } {
	return { projectShowExtended: (props) => request(props, projectShowRequestExtended) };
}

export function projectUpdateRequestLoaderChunk(request: (props, _: (input: Project, requestConfig?: AxiosRequestConfiguration) => Promise<Project>) => Promise<Project>): { [key: string]: (props) => Promise<Project> } {
	return { projectUpdate: (props) => request(props, projectUpdateRequest) };
}

export function projectUpdateRequestExtendedLoaderChunk(request: (props, _: (input: Project, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Project>>) => Promise<ExtendedResponseData<Project>>): { [key: string]: (props) => Promise<ExtendedResponseData<Project>> } {
	return { projectUpdateExtended: (props) => request(props, projectUpdateRequestExtended) };
}

export function projectDestroyRequestLoaderChunk(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { projectDestroy: (props) => request(props, projectDestroyRequest) };
}

export function projectDestroyRequestExtendedLoaderChunk(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { projectDestroyExtended: (props) => request(props, projectDestroyRequestExtended) };
}

export function setProjectAvatarRequestLoaderChunk(request: (props, _: (projectId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { setProjectAvatar: (props) => request(props, setProjectAvatarRequest) };
}

export function setProjectAvatarRequestExtendedLoaderChunk(request: (props, _: (projectId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { setProjectAvatarExtended: (props) => request(props, setProjectAvatarRequestExtended) };
}

export function mediaIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Media>>) => Promise<Array<Media>>): { [key: string]: (props) => Promise<Array<Media>> } {
	return { mediaIndex: (props) => request(props, mediaIndexRequest) };
}

export function mediaIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Media>>>) => Promise<ExtendedResponseData<Array<Media>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Media>>> } {
	return { mediaIndexExtended: (props) => request(props, mediaIndexRequestExtended) };
}

export function mediaStoreRequestLoaderChunk(request: (props, _: (input: Media, requestConfig?: AxiosRequestConfiguration) => Promise<Media>) => Promise<Media>): { [key: string]: (props) => Promise<Media> } {
	return { mediaStore: (props) => request(props, mediaStoreRequest) };
}

export function mediaStoreRequestExtendedLoaderChunk(request: (props, _: (input: Media, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Media>>) => Promise<ExtendedResponseData<Media>>): { [key: string]: (props) => Promise<ExtendedResponseData<Media>> } {
	return { mediaStoreExtended: (props) => request(props, mediaStoreRequestExtended) };
}

export function mediaShowRequestLoaderChunk(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Media>) => Promise<Media>): { [key: string]: (props) => Promise<Media> } {
	return { mediaShow: (props) => request(props, mediaShowRequest) };
}

export function mediaShowRequestExtendedLoaderChunk(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Media>>) => Promise<ExtendedResponseData<Media>>): { [key: string]: (props) => Promise<ExtendedResponseData<Media>> } {
	return { mediaShowExtended: (props) => request(props, mediaShowRequestExtended) };
}

export function mediaUpdateRequestLoaderChunk(request: (props, _: (input: Media, requestConfig?: AxiosRequestConfiguration) => Promise<Media>) => Promise<Media>): { [key: string]: (props) => Promise<Media> } {
	return { mediaUpdate: (props) => request(props, mediaUpdateRequest) };
}

export function mediaUpdateRequestExtendedLoaderChunk(request: (props, _: (input: Media, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Media>>) => Promise<ExtendedResponseData<Media>>): { [key: string]: (props) => Promise<ExtendedResponseData<Media>> } {
	return { mediaUpdateExtended: (props) => request(props, mediaUpdateRequestExtended) };
}

export function mediaDestroyRequestLoaderChunk(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { mediaDestroy: (props) => request(props, mediaDestroyRequest) };
}

export function mediaDestroyRequestExtendedLoaderChunk(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { mediaDestroyExtended: (props) => request(props, mediaDestroyRequestExtended) };
}

export function setMediaAvatarRequestLoaderChunk(request: (props, _: (mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { setMediaAvatar: (props) => request(props, setMediaAvatarRequest) };
}

export function setMediaAvatarRequestExtendedLoaderChunk(request: (props, _: (mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { setMediaAvatarExtended: (props) => request(props, setMediaAvatarRequestExtended) };
}

export function userIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<User>>) => Promise<Array<User>>): { [key: string]: (props) => Promise<Array<User>> } {
	return { userIndex: (props) => request(props, userIndexRequest) };
}

export function userIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<User>>>) => Promise<ExtendedResponseData<Array<User>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<User>>> } {
	return { userIndexExtended: (props) => request(props, userIndexRequestExtended) };
}

export function userStoreRequestLoaderChunk(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { [key: string]: (props) => Promise<User> } {
	return { userStore: (props) => request(props, userStoreRequest) };
}

export function userStoreRequestExtendedLoaderChunk(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { [key: string]: (props) => Promise<ExtendedResponseData<User>> } {
	return { userStoreExtended: (props) => request(props, userStoreRequestExtended) };
}

export function userShowRequestLoaderChunk(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { [key: string]: (props) => Promise<User> } {
	return { userShow: (props) => request(props, userShowRequest) };
}

export function userShowRequestExtendedLoaderChunk(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { [key: string]: (props) => Promise<ExtendedResponseData<User>> } {
	return { userShowExtended: (props) => request(props, userShowRequestExtended) };
}

export function userUpdateRequestLoaderChunk(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { [key: string]: (props) => Promise<User> } {
	return { userUpdate: (props) => request(props, userUpdateRequest) };
}

export function userUpdateRequestExtendedLoaderChunk(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { [key: string]: (props) => Promise<ExtendedResponseData<User>> } {
	return { userUpdateExtended: (props) => request(props, userUpdateRequestExtended) };
}

export function userDestroyRequestLoaderChunk(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { userDestroy: (props) => request(props, userDestroyRequest) };
}

export function userDestroyRequestExtendedLoaderChunk(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { userDestroyExtended: (props) => request(props, userDestroyRequestExtended) };
}

export function setUserAvatarRequestLoaderChunk(request: (props, _: (userId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { setUserAvatar: (props) => request(props, setUserAvatarRequest) };
}

export function setUserAvatarRequestExtendedLoaderChunk(request: (props, _: (userId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { setUserAvatarExtended: (props) => request(props, setUserAvatarRequestExtended) };
}

export function discussionIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Discussion>>) => Promise<Array<Discussion>>): { [key: string]: (props) => Promise<Array<Discussion>> } {
	return { discussionIndex: (props) => request(props, discussionIndexRequest) };
}

export function discussionIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Discussion>>>) => Promise<ExtendedResponseData<Array<Discussion>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Discussion>>> } {
	return { discussionIndexExtended: (props) => request(props, discussionIndexRequestExtended) };
}

export function discussionStoreRequestLoaderChunk(request: (props, _: (input: Discussion, requestConfig?: AxiosRequestConfiguration) => Promise<Discussion>) => Promise<Discussion>): { [key: string]: (props) => Promise<Discussion> } {
	return { discussionStore: (props) => request(props, discussionStoreRequest) };
}

export function discussionStoreRequestExtendedLoaderChunk(request: (props, _: (input: Discussion, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Discussion>>) => Promise<ExtendedResponseData<Discussion>>): { [key: string]: (props) => Promise<ExtendedResponseData<Discussion>> } {
	return { discussionStoreExtended: (props) => request(props, discussionStoreRequestExtended) };
}

export function discussionShowRequestLoaderChunk(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Discussion>) => Promise<Discussion>): { [key: string]: (props) => Promise<Discussion> } {
	return { discussionShow: (props) => request(props, discussionShowRequest) };
}

export function discussionShowRequestExtendedLoaderChunk(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Discussion>>) => Promise<ExtendedResponseData<Discussion>>): { [key: string]: (props) => Promise<ExtendedResponseData<Discussion>> } {
	return { discussionShowExtended: (props) => request(props, discussionShowRequestExtended) };
}

export function discussionUpdateRequestLoaderChunk(request: (props, _: (input: Discussion, requestConfig?: AxiosRequestConfiguration) => Promise<Discussion>) => Promise<Discussion>): { [key: string]: (props) => Promise<Discussion> } {
	return { discussionUpdate: (props) => request(props, discussionUpdateRequest) };
}

export function discussionUpdateRequestExtendedLoaderChunk(request: (props, _: (input: Discussion, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Discussion>>) => Promise<ExtendedResponseData<Discussion>>): { [key: string]: (props) => Promise<ExtendedResponseData<Discussion>> } {
	return { discussionUpdateExtended: (props) => request(props, discussionUpdateRequestExtended) };
}

export function discussionDestroyRequestLoaderChunk(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { discussionDestroy: (props) => request(props, discussionDestroyRequest) };
}

export function discussionDestroyRequestExtendedLoaderChunk(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { discussionDestroyExtended: (props) => request(props, discussionDestroyRequestExtended) };
}

export function setDiscussionAvatarRequestLoaderChunk(request: (props, _: (discussionId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { setDiscussionAvatar: (props) => request(props, setDiscussionAvatarRequest) };
}

export function setDiscussionAvatarRequestExtendedLoaderChunk(request: (props, _: (discussionId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { setDiscussionAvatarExtended: (props) => request(props, setDiscussionAvatarRequestExtended) };
}

export function pinIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Pin>>) => Promise<Array<Pin>>): { [key: string]: (props) => Promise<Array<Pin>> } {
	return { pinIndex: (props) => request(props, pinIndexRequest) };
}

export function pinIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Pin>>>) => Promise<ExtendedResponseData<Array<Pin>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Pin>>> } {
	return { pinIndexExtended: (props) => request(props, pinIndexRequestExtended) };
}

export function pinStoreRequestLoaderChunk(request: (props, _: (input: Pin, requestConfig?: AxiosRequestConfiguration) => Promise<Pin>) => Promise<Pin>): { [key: string]: (props) => Promise<Pin> } {
	return { pinStore: (props) => request(props, pinStoreRequest) };
}

export function pinStoreRequestExtendedLoaderChunk(request: (props, _: (input: Pin, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Pin>>) => Promise<ExtendedResponseData<Pin>>): { [key: string]: (props) => Promise<ExtendedResponseData<Pin>> } {
	return { pinStoreExtended: (props) => request(props, pinStoreRequestExtended) };
}

export function pinShowRequestLoaderChunk(request: (props, _: (pinId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Pin>) => Promise<Pin>): { [key: string]: (props) => Promise<Pin> } {
	return { pinShow: (props) => request(props, pinShowRequest) };
}

export function pinShowRequestExtendedLoaderChunk(request: (props, _: (pinId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Pin>>) => Promise<ExtendedResponseData<Pin>>): { [key: string]: (props) => Promise<ExtendedResponseData<Pin>> } {
	return { pinShowExtended: (props) => request(props, pinShowRequestExtended) };
}

export function pinUpdateRequestLoaderChunk(request: (props, _: (input: Pin, requestConfig?: AxiosRequestConfiguration) => Promise<Pin>) => Promise<Pin>): { [key: string]: (props) => Promise<Pin> } {
	return { pinUpdate: (props) => request(props, pinUpdateRequest) };
}

export function pinUpdateRequestExtendedLoaderChunk(request: (props, _: (input: Pin, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Pin>>) => Promise<ExtendedResponseData<Pin>>): { [key: string]: (props) => Promise<ExtendedResponseData<Pin>> } {
	return { pinUpdateExtended: (props) => request(props, pinUpdateRequestExtended) };
}

export function pinDestroyRequestLoaderChunk(request: (props, _: (pinId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { pinDestroy: (props) => request(props, pinDestroyRequest) };
}

export function pinDestroyRequestExtendedLoaderChunk(request: (props, _: (pinId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { pinDestroyExtended: (props) => request(props, pinDestroyRequestExtended) };
}

export function pollIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Poll>>) => Promise<Array<Poll>>): { [key: string]: (props) => Promise<Array<Poll>> } {
	return { pollIndex: (props) => request(props, pollIndexRequest) };
}

export function pollIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Poll>>>) => Promise<ExtendedResponseData<Array<Poll>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Poll>>> } {
	return { pollIndexExtended: (props) => request(props, pollIndexRequestExtended) };
}

export function pollStoreRequestLoaderChunk(request: (props, _: (input: Poll, requestConfig?: AxiosRequestConfiguration) => Promise<Poll>) => Promise<Poll>): { [key: string]: (props) => Promise<Poll> } {
	return { pollStore: (props) => request(props, pollStoreRequest) };
}

export function pollStoreRequestExtendedLoaderChunk(request: (props, _: (input: Poll, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Poll>>) => Promise<ExtendedResponseData<Poll>>): { [key: string]: (props) => Promise<ExtendedResponseData<Poll>> } {
	return { pollStoreExtended: (props) => request(props, pollStoreRequestExtended) };
}

export function pollShowRequestLoaderChunk(request: (props, _: (pollId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Poll>) => Promise<Poll>): { [key: string]: (props) => Promise<Poll> } {
	return { pollShow: (props) => request(props, pollShowRequest) };
}

export function pollShowRequestExtendedLoaderChunk(request: (props, _: (pollId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Poll>>) => Promise<ExtendedResponseData<Poll>>): { [key: string]: (props) => Promise<ExtendedResponseData<Poll>> } {
	return { pollShowExtended: (props) => request(props, pollShowRequestExtended) };
}

export function pollUpdateRequestLoaderChunk(request: (props, _: (input: Poll, requestConfig?: AxiosRequestConfiguration) => Promise<Poll>) => Promise<Poll>): { [key: string]: (props) => Promise<Poll> } {
	return { pollUpdate: (props) => request(props, pollUpdateRequest) };
}

export function pollUpdateRequestExtendedLoaderChunk(request: (props, _: (input: Poll, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Poll>>) => Promise<ExtendedResponseData<Poll>>): { [key: string]: (props) => Promise<ExtendedResponseData<Poll>> } {
	return { pollUpdateExtended: (props) => request(props, pollUpdateRequestExtended) };
}

export function pollDestroyRequestLoaderChunk(request: (props, _: (pollId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { pollDestroy: (props) => request(props, pollDestroyRequest) };
}

export function pollDestroyRequestExtendedLoaderChunk(request: (props, _: (pollId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { pollDestroyExtended: (props) => request(props, pollDestroyRequestExtended) };
}

export function setPollAvatarRequestLoaderChunk(request: (props, _: (pollId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { setPollAvatar: (props) => request(props, setPollAvatarRequest) };
}

export function setPollAvatarRequestExtendedLoaderChunk(request: (props, _: (pollId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { setPollAvatarExtended: (props) => request(props, setPollAvatarRequestExtended) };
}

export function voteIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Vote>>) => Promise<Array<Vote>>): { [key: string]: (props) => Promise<Array<Vote>> } {
	return { voteIndex: (props) => request(props, voteIndexRequest) };
}

export function voteIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Vote>>>) => Promise<ExtendedResponseData<Array<Vote>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Vote>>> } {
	return { voteIndexExtended: (props) => request(props, voteIndexRequestExtended) };
}

export function voteStoreRequestLoaderChunk(request: (props, _: (input: Vote, requestConfig?: AxiosRequestConfiguration) => Promise<Vote>) => Promise<Vote>): { [key: string]: (props) => Promise<Vote> } {
	return { voteStore: (props) => request(props, voteStoreRequest) };
}

export function voteStoreRequestExtendedLoaderChunk(request: (props, _: (input: Vote, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Vote>>) => Promise<ExtendedResponseData<Vote>>): { [key: string]: (props) => Promise<ExtendedResponseData<Vote>> } {
	return { voteStoreExtended: (props) => request(props, voteStoreRequestExtended) };
}

export function voteShowRequestLoaderChunk(request: (props, _: (voteId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Vote>) => Promise<Vote>): { [key: string]: (props) => Promise<Vote> } {
	return { voteShow: (props) => request(props, voteShowRequest) };
}

export function voteShowRequestExtendedLoaderChunk(request: (props, _: (voteId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Vote>>) => Promise<ExtendedResponseData<Vote>>): { [key: string]: (props) => Promise<ExtendedResponseData<Vote>> } {
	return { voteShowExtended: (props) => request(props, voteShowRequestExtended) };
}

export function voteUpdateRequestLoaderChunk(request: (props, _: (input: Vote, requestConfig?: AxiosRequestConfiguration) => Promise<Vote>) => Promise<Vote>): { [key: string]: (props) => Promise<Vote> } {
	return { voteUpdate: (props) => request(props, voteUpdateRequest) };
}

export function voteUpdateRequestExtendedLoaderChunk(request: (props, _: (input: Vote, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Vote>>) => Promise<ExtendedResponseData<Vote>>): { [key: string]: (props) => Promise<ExtendedResponseData<Vote>> } {
	return { voteUpdateExtended: (props) => request(props, voteUpdateRequestExtended) };
}

export function voteDestroyRequestLoaderChunk(request: (props, _: (voteId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { voteDestroy: (props) => request(props, voteDestroyRequest) };
}

export function voteDestroyRequestExtendedLoaderChunk(request: (props, _: (voteId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { voteDestroyExtended: (props) => request(props, voteDestroyRequestExtended) };
}

export function setVoteAvatarRequestLoaderChunk(request: (props, _: (voteId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { setVoteAvatar: (props) => request(props, setVoteAvatarRequest) };
}

export function setVoteAvatarRequestExtendedLoaderChunk(request: (props, _: (voteId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { setVoteAvatarExtended: (props) => request(props, setVoteAvatarRequestExtended) };
}

export function checklistIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Checklist>>) => Promise<Array<Checklist>>): { [key: string]: (props) => Promise<Array<Checklist>> } {
	return { checklistIndex: (props) => request(props, checklistIndexRequest) };
}

export function checklistIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Checklist>>>) => Promise<ExtendedResponseData<Array<Checklist>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Checklist>>> } {
	return { checklistIndexExtended: (props) => request(props, checklistIndexRequestExtended) };
}

export function checklistStoreRequestLoaderChunk(request: (props, _: (input: Checklist, requestConfig?: AxiosRequestConfiguration) => Promise<Checklist>) => Promise<Checklist>): { [key: string]: (props) => Promise<Checklist> } {
	return { checklistStore: (props) => request(props, checklistStoreRequest) };
}

export function checklistStoreRequestExtendedLoaderChunk(request: (props, _: (input: Checklist, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Checklist>>) => Promise<ExtendedResponseData<Checklist>>): { [key: string]: (props) => Promise<ExtendedResponseData<Checklist>> } {
	return { checklistStoreExtended: (props) => request(props, checklistStoreRequestExtended) };
}

export function checklistShowRequestLoaderChunk(request: (props, _: (checklistId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Checklist>) => Promise<Checklist>): { [key: string]: (props) => Promise<Checklist> } {
	return { checklistShow: (props) => request(props, checklistShowRequest) };
}

export function checklistShowRequestExtendedLoaderChunk(request: (props, _: (checklistId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Checklist>>) => Promise<ExtendedResponseData<Checklist>>): { [key: string]: (props) => Promise<ExtendedResponseData<Checklist>> } {
	return { checklistShowExtended: (props) => request(props, checklistShowRequestExtended) };
}

export function checklistUpdateRequestLoaderChunk(request: (props, _: (input: Checklist, requestConfig?: AxiosRequestConfiguration) => Promise<Checklist>) => Promise<Checklist>): { [key: string]: (props) => Promise<Checklist> } {
	return { checklistUpdate: (props) => request(props, checklistUpdateRequest) };
}

export function checklistUpdateRequestExtendedLoaderChunk(request: (props, _: (input: Checklist, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Checklist>>) => Promise<ExtendedResponseData<Checklist>>): { [key: string]: (props) => Promise<ExtendedResponseData<Checklist>> } {
	return { checklistUpdateExtended: (props) => request(props, checklistUpdateRequestExtended) };
}

export function checklistDestroyRequestLoaderChunk(request: (props, _: (checklistId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { checklistDestroy: (props) => request(props, checklistDestroyRequest) };
}

export function checklistDestroyRequestExtendedLoaderChunk(request: (props, _: (checklistId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { checklistDestroyExtended: (props) => request(props, checklistDestroyRequestExtended) };
}

export function setChecklistAvatarRequestLoaderChunk(request: (props, _: (checklistId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { setChecklistAvatar: (props) => request(props, setChecklistAvatarRequest) };
}

export function setChecklistAvatarRequestExtendedLoaderChunk(request: (props, _: (checklistId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { setChecklistAvatarExtended: (props) => request(props, setChecklistAvatarRequestExtended) };
}

export function mediaMapImageIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<MediaMapImage>>) => Promise<Array<MediaMapImage>>): { [key: string]: (props) => Promise<Array<MediaMapImage>> } {
	return { mediaMapImageIndex: (props) => request(props, mediaMapImageIndexRequest) };
}

export function mediaMapImageIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<MediaMapImage>>>) => Promise<ExtendedResponseData<Array<MediaMapImage>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<MediaMapImage>>> } {
	return { mediaMapImageIndexExtended: (props) => request(props, mediaMapImageIndexRequestExtended) };
}

export function mediaMapImageStoreRequestLoaderChunk(request: (props, _: (input: MediaMapImage, requestConfig?: AxiosRequestConfiguration) => Promise<MediaMapImage>) => Promise<MediaMapImage>): { [key: string]: (props) => Promise<MediaMapImage> } {
	return { mediaMapImageStore: (props) => request(props, mediaMapImageStoreRequest) };
}

export function mediaMapImageStoreRequestExtendedLoaderChunk(request: (props, _: (input: MediaMapImage, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<MediaMapImage>>) => Promise<ExtendedResponseData<MediaMapImage>>): { [key: string]: (props) => Promise<ExtendedResponseData<MediaMapImage>> } {
	return { mediaMapImageStoreExtended: (props) => request(props, mediaMapImageStoreRequestExtended) };
}

export function mediaMapImageShowRequestLoaderChunk(request: (props, _: (mediaMapImageId: number, requestConfig?: AxiosRequestConfiguration) => Promise<MediaMapImage>) => Promise<MediaMapImage>): { [key: string]: (props) => Promise<MediaMapImage> } {
	return { mediaMapImageShow: (props) => request(props, mediaMapImageShowRequest) };
}

export function mediaMapImageShowRequestExtendedLoaderChunk(request: (props, _: (mediaMapImageId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<MediaMapImage>>) => Promise<ExtendedResponseData<MediaMapImage>>): { [key: string]: (props) => Promise<ExtendedResponseData<MediaMapImage>> } {
	return { mediaMapImageShowExtended: (props) => request(props, mediaMapImageShowRequestExtended) };
}

export function mediaMapImageUpdateRequestLoaderChunk(request: (props, _: (input: MediaMapImage, requestConfig?: AxiosRequestConfiguration) => Promise<MediaMapImage>) => Promise<MediaMapImage>): { [key: string]: (props) => Promise<MediaMapImage> } {
	return { mediaMapImageUpdate: (props) => request(props, mediaMapImageUpdateRequest) };
}

export function mediaMapImageUpdateRequestExtendedLoaderChunk(request: (props, _: (input: MediaMapImage, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<MediaMapImage>>) => Promise<ExtendedResponseData<MediaMapImage>>): { [key: string]: (props) => Promise<ExtendedResponseData<MediaMapImage>> } {
	return { mediaMapImageUpdateExtended: (props) => request(props, mediaMapImageUpdateRequestExtended) };
}

export function mediaMapImageDestroyRequestLoaderChunk(request: (props, _: (mediaMapImageId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { mediaMapImageDestroy: (props) => request(props, mediaMapImageDestroyRequest) };
}

export function mediaMapImageDestroyRequestExtendedLoaderChunk(request: (props, _: (mediaMapImageId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { mediaMapImageDestroyExtended: (props) => request(props, mediaMapImageDestroyRequestExtended) };
}

export function mediaMapDrawingIndexRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<MediaMapDrawing>>) => Promise<Array<MediaMapDrawing>>): { [key: string]: (props) => Promise<Array<MediaMapDrawing>> } {
	return { mediaMapDrawingIndex: (props) => request(props, mediaMapDrawingIndexRequest) };
}

export function mediaMapDrawingIndexRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<MediaMapDrawing>>>) => Promise<ExtendedResponseData<Array<MediaMapDrawing>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<MediaMapDrawing>>> } {
	return { mediaMapDrawingIndexExtended: (props) => request(props, mediaMapDrawingIndexRequestExtended) };
}

export function mediaMapDrawingStoreRequestLoaderChunk(request: (props, _: (input: MediaMapDrawing, requestConfig?: AxiosRequestConfiguration) => Promise<MediaMapDrawing>) => Promise<MediaMapDrawing>): { [key: string]: (props) => Promise<MediaMapDrawing> } {
	return { mediaMapDrawingStore: (props) => request(props, mediaMapDrawingStoreRequest) };
}

export function mediaMapDrawingStoreRequestExtendedLoaderChunk(request: (props, _: (input: MediaMapDrawing, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<MediaMapDrawing>>) => Promise<ExtendedResponseData<MediaMapDrawing>>): { [key: string]: (props) => Promise<ExtendedResponseData<MediaMapDrawing>> } {
	return { mediaMapDrawingStoreExtended: (props) => request(props, mediaMapDrawingStoreRequestExtended) };
}

export function mediaMapDrawingShowRequestLoaderChunk(request: (props, _: (mediaMapDrawingId: number, requestConfig?: AxiosRequestConfiguration) => Promise<MediaMapDrawing>) => Promise<MediaMapDrawing>): { [key: string]: (props) => Promise<MediaMapDrawing> } {
	return { mediaMapDrawingShow: (props) => request(props, mediaMapDrawingShowRequest) };
}

export function mediaMapDrawingShowRequestExtendedLoaderChunk(request: (props, _: (mediaMapDrawingId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<MediaMapDrawing>>) => Promise<ExtendedResponseData<MediaMapDrawing>>): { [key: string]: (props) => Promise<ExtendedResponseData<MediaMapDrawing>> } {
	return { mediaMapDrawingShowExtended: (props) => request(props, mediaMapDrawingShowRequestExtended) };
}

export function mediaMapDrawingUpdateRequestLoaderChunk(request: (props, _: (input: MediaMapDrawing, requestConfig?: AxiosRequestConfiguration) => Promise<MediaMapDrawing>) => Promise<MediaMapDrawing>): { [key: string]: (props) => Promise<MediaMapDrawing> } {
	return { mediaMapDrawingUpdate: (props) => request(props, mediaMapDrawingUpdateRequest) };
}

export function mediaMapDrawingUpdateRequestExtendedLoaderChunk(request: (props, _: (input: MediaMapDrawing, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<MediaMapDrawing>>) => Promise<ExtendedResponseData<MediaMapDrawing>>): { [key: string]: (props) => Promise<ExtendedResponseData<MediaMapDrawing>> } {
	return { mediaMapDrawingUpdateExtended: (props) => request(props, mediaMapDrawingUpdateRequestExtended) };
}

export function mediaMapDrawingDestroyRequestLoaderChunk(request: (props, _: (mediaMapDrawingId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { mediaMapDrawingDestroy: (props) => request(props, mediaMapDrawingDestroyRequest) };
}

export function mediaMapDrawingDestroyRequestExtendedLoaderChunk(request: (props, _: (mediaMapDrawingId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { mediaMapDrawingDestroyExtended: (props) => request(props, mediaMapDrawingDestroyRequestExtended) };
}

export function mediaImgRequestLoaderChunk(request: (props, _: (mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { mediaImg: (props) => request(props, mediaImgRequest) };
}

export function mediaImgRequestExtendedLoaderChunk(request: (props, _: (mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { mediaImgExtended: (props) => request(props, mediaImgRequestExtended) };
}

export function mediaPdfRequestLoaderChunk(request: (props, _: (mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { mediaPdf: (props) => request(props, mediaPdfRequest) };
}

export function mediaPdfRequestExtendedLoaderChunk(request: (props, _: (mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { mediaPdfExtended: (props) => request(props, mediaPdfRequestExtended) };
}

export function mediaMapImgRequestLoaderChunk(request: (props, _: (mediaMapImageId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { mediaMapImg: (props) => request(props, mediaMapImgRequest) };
}

export function mediaMapImgRequestExtendedLoaderChunk(request: (props, _: (mediaMapImageId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { mediaMapImgExtended: (props) => request(props, mediaMapImgRequestExtended) };
}

export function discussionCommentsRequestLoaderChunk(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Array<Comment>>) => Promise<Array<Comment>>): { [key: string]: (props) => Promise<Array<Comment>> } {
	return { discussionComments: (props) => request(props, discussionCommentsRequest) };
}

export function discussionCommentsRequestExtendedLoaderChunk(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Comment>>>) => Promise<ExtendedResponseData<Array<Comment>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Comment>>> } {
	return { discussionCommentsExtended: (props) => request(props, discussionCommentsRequestExtended) };
}

export function mediaCommentsRequestLoaderChunk(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Array<Comment>>) => Promise<Array<Comment>>): { [key: string]: (props) => Promise<Array<Comment>> } {
	return { mediaComments: (props) => request(props, mediaCommentsRequest) };
}

export function mediaCommentsRequestExtendedLoaderChunk(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Comment>>>) => Promise<ExtendedResponseData<Array<Comment>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Comment>>> } {
	return { mediaCommentsExtended: (props) => request(props, mediaCommentsRequestExtended) };
}

export function projectCommentsRequestLoaderChunk(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Array<Comment>>) => Promise<Array<Comment>>): { [key: string]: (props) => Promise<Array<Comment>> } {
	return { projectComments: (props) => request(props, projectCommentsRequest) };
}

export function projectCommentsRequestExtendedLoaderChunk(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Comment>>>) => Promise<ExtendedResponseData<Array<Comment>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Comment>>> } {
	return { projectCommentsExtended: (props) => request(props, projectCommentsRequestExtended) };
}

export function commentApproveRequestLoaderChunk(request: (props, _: (commentId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { commentApprove: (props) => request(props, commentApproveRequest) };
}

export function commentApproveRequestExtendedLoaderChunk(request: (props, _: (commentId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { commentApproveExtended: (props) => request(props, commentApproveRequestExtended) };
}

export function commentSpamRequestLoaderChunk(request: (props, _: (commentId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { commentSpam: (props) => request(props, commentSpamRequest) };
}

export function commentSpamRequestExtendedLoaderChunk(request: (props, _: (commentId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { commentSpamExtended: (props) => request(props, commentSpamRequestExtended) };
}

export function tagUpdateRequestLoaderChunk(request: (props, _: (input: Tag, requestConfig?: AxiosRequestConfiguration) => Promise<Tag>) => Promise<Tag>): { [key: string]: (props) => Promise<Tag> } {
	return { tagUpdate: (props) => request(props, tagUpdateRequest) };
}

export function tagUpdateRequestExtendedLoaderChunk(request: (props, _: (input: Tag, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Tag>>) => Promise<ExtendedResponseData<Tag>>): { [key: string]: (props) => Promise<ExtendedResponseData<Tag>> } {
	return { tagUpdateExtended: (props) => request(props, tagUpdateRequestExtended) };
}

export function accountRequestLoaderChunk(request: (props, _: (accountId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Account>) => Promise<Account>): { [key: string]: (props) => Promise<Account> } {
	return { account: (props) => request(props, accountRequest) };
}

export function accountRequestExtendedLoaderChunk(request: (props, _: (accountId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Account>>) => Promise<ExtendedResponseData<Account>>): { [key: string]: (props) => Promise<ExtendedResponseData<Account>> } {
	return { accountExtended: (props) => request(props, accountRequestExtended) };
}

export function projectsRequestLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Project>>) => Promise<Array<Project>>): { [key: string]: (props) => Promise<Array<Project>> } {
	return { projects: (props) => request(props, projectsRequest) };
}

export function projectsRequestExtendedLoaderChunk(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Project>>>) => Promise<ExtendedResponseData<Array<Project>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Project>>> } {
	return { projectsExtended: (props) => request(props, projectsRequestExtended) };
}

export function projectsInBoundsRequestLoaderChunk(request: (props, _: (swLat: number, swLng: number, neLat: number, neLng: number, requestConfig?: AxiosRequestConfiguration) => Promise<Array<Project>>) => Promise<Array<Project>>): { [key: string]: (props) => Promise<Array<Project>> } {
	return { projectsInBounds: (props) => request(props, projectsInBoundsRequest) };
}

export function projectsInBoundsRequestExtendedLoaderChunk(request: (props, _: (swLat: number, swLng: number, neLat: number, neLng: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Project>>>) => Promise<ExtendedResponseData<Array<Project>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Project>>> } {
	return { projectsInBoundsExtended: (props) => request(props, projectsInBoundsRequestExtended) };
}

export function projectRequestLoaderChunk(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Project>) => Promise<Project>): { [key: string]: (props) => Promise<Project> } {
	return { project: (props) => request(props, projectRequest) };
}

export function projectRequestExtendedLoaderChunk(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Project>>) => Promise<ExtendedResponseData<Project>>): { [key: string]: (props) => Promise<ExtendedResponseData<Project>> } {
	return { projectExtended: (props) => request(props, projectRequestExtended) };
}

export function showProjectByAliasRequestLoaderChunk(request: (props, _: (projectName: string, requestConfig?: AxiosRequestConfiguration) => Promise<Project>) => Promise<Project>): { [key: string]: (props) => Promise<Project> } {
	return { showProjectByAlias: (props) => request(props, showProjectByAliasRequest) };
}

export function showProjectByAliasRequestExtendedLoaderChunk(request: (props, _: (projectName: string, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Project>>) => Promise<ExtendedResponseData<Project>>): { [key: string]: (props) => Promise<ExtendedResponseData<Project>> } {
	return { showProjectByAliasExtended: (props) => request(props, showProjectByAliasRequestExtended) };
}

export function mediaRequestLoaderChunk(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Media>) => Promise<Media>): { [key: string]: (props) => Promise<Media> } {
	return { media: (props) => request(props, mediaRequest) };
}

export function mediaRequestExtendedLoaderChunk(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Media>>) => Promise<ExtendedResponseData<Media>>): { [key: string]: (props) => Promise<ExtendedResponseData<Media>> } {
	return { mediaExtended: (props) => request(props, mediaRequestExtended) };
}

export function showMediaByAliasRequestLoaderChunk(request: (props, _: (mediaName: string, requestConfig?: AxiosRequestConfiguration) => Promise<Media>) => Promise<Media>): { [key: string]: (props) => Promise<Media> } {
	return { showMediaByAlias: (props) => request(props, showMediaByAliasRequest) };
}

export function showMediaByAliasRequestExtendedLoaderChunk(request: (props, _: (mediaName: string, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Media>>) => Promise<ExtendedResponseData<Media>>): { [key: string]: (props) => Promise<ExtendedResponseData<Media>> } {
	return { showMediaByAliasExtended: (props) => request(props, showMediaByAliasRequestExtended) };
}

export function discussionRequestLoaderChunk(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Discussion>) => Promise<Discussion>): { [key: string]: (props) => Promise<Discussion> } {
	return { discussion: (props) => request(props, discussionRequest) };
}

export function discussionRequestExtendedLoaderChunk(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Discussion>>) => Promise<ExtendedResponseData<Discussion>>): { [key: string]: (props) => Promise<ExtendedResponseData<Discussion>> } {
	return { discussionExtended: (props) => request(props, discussionRequestExtended) };
}

export function showDiscussionByAliasRequestLoaderChunk(request: (props, _: (discussionName: string, requestConfig?: AxiosRequestConfiguration) => Promise<Discussion>) => Promise<Discussion>): { [key: string]: (props) => Promise<Discussion> } {
	return { showDiscussionByAlias: (props) => request(props, showDiscussionByAliasRequest) };
}

export function showDiscussionByAliasRequestExtendedLoaderChunk(request: (props, _: (discussionName: string, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Discussion>>) => Promise<ExtendedResponseData<Discussion>>): { [key: string]: (props) => Promise<ExtendedResponseData<Discussion>> } {
	return { showDiscussionByAliasExtended: (props) => request(props, showDiscussionByAliasRequestExtended) };
}

export function projectDiscussionsRequestLoaderChunk(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Array<Discussion>>) => Promise<Array<Discussion>>): { [key: string]: (props) => Promise<Array<Discussion>> } {
	return { projectDiscussions: (props) => request(props, projectDiscussionsRequest) };
}

export function projectDiscussionsRequestExtendedLoaderChunk(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Discussion>>>) => Promise<ExtendedResponseData<Array<Discussion>>>): { [key: string]: (props) => Promise<ExtendedResponseData<Array<Discussion>>> } {
	return { projectDiscussionsExtended: (props) => request(props, projectDiscussionsRequestExtended) };
}

export function pinRequestLoaderChunk(request: (props, _: (input: Pin, mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Pin>) => Promise<Pin>): { [key: string]: (props) => Promise<Pin> } {
	return { pin: (props) => request(props, pinRequest) };
}

export function pinRequestExtendedLoaderChunk(request: (props, _: (input: Pin, mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Pin>>) => Promise<ExtendedResponseData<Pin>>): { [key: string]: (props) => Promise<ExtendedResponseData<Pin>> } {
	return { pinExtended: (props) => request(props, pinRequestExtended) };
}

export function requestPasswordRequestLoaderChunk(request: (props, _: (input: PasswordRequest, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { requestPassword: (props) => request(props, requestPasswordRequest) };
}

export function requestPasswordRequestExtendedLoaderChunk(request: (props, _: (input: PasswordRequest, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { requestPasswordExtended: (props) => request(props, requestPasswordRequestExtended) };
}

export function updatePasswordRequestLoaderChunk(request: (props, _: (input: PasswordUpdate, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { updatePassword: (props) => request(props, updatePasswordRequest) };
}

export function updatePasswordRequestExtendedLoaderChunk(request: (props, _: (input: PasswordUpdate, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { updatePasswordExtended: (props) => request(props, updatePasswordRequestExtended) };
}

export function pollAnswerRequestLoaderChunk(request: (props, _: (pollId: number, pollAnswerId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { pollAnswer: (props) => request(props, pollAnswerRequest) };
}

export function pollAnswerRequestExtendedLoaderChunk(request: (props, _: (pollId: number, pollAnswerId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { pollAnswerExtended: (props) => request(props, pollAnswerRequestExtended) };
}

export function voteAnswerRequestLoaderChunk(request: (props, _: (voteId: number, voteAnswer: boolean, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { voteAnswer: (props) => request(props, voteAnswerRequest) };
}

export function voteAnswerRequestExtendedLoaderChunk(request: (props, _: (voteId: number, voteAnswer: boolean, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { voteAnswerExtended: (props) => request(props, voteAnswerRequestExtended) };
}

export function checklistItemDoneRequestLoaderChunk(request: (props, _: (checklistId: number, checklistItemId: number, checklistDone: boolean, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { [key: string]: (props) => Promise<void> } {
	return { checklistItemDone: (props) => request(props, checklistItemDoneRequest) };
}

export function checklistItemDoneRequestExtendedLoaderChunk(request: (props, _: (checklistId: number, checklistItemId: number, checklistDone: boolean, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { [key: string]: (props) => Promise<ExtendedResponseData<void>> } {
	return { checklistItemDoneExtended: (props) => request(props, checklistItemDoneRequestExtended) };
}

export function loginRequestLoader(request: (props, _: (input: AuthRequest, requestConfig?: AxiosRequestConfiguration) => Promise<AuthResponse>) => Promise<AuthResponse>): { promises: { [key: string]: (props) => Promise<AuthResponse> } } {
	return { promises: loginRequestLoaderChunk(request) };
}

export function loginRequestExtendedLoader(request: (props, _: (input: AuthRequest, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<AuthResponse>>) => Promise<ExtendedResponseData<AuthResponse>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<AuthResponse>> } } {
	return { promises: loginRequestExtendedLoaderChunk(request) };
}

export function whoRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { promises: { [key: string]: (props) => Promise<User> } } {
	return { promises: whoRequestLoaderChunk(request) };
}

export function whoRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<User>> } } {
	return { promises: whoRequestExtendedLoaderChunk(request) };
}

export function logoutRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: logoutRequestLoaderChunk(request) };
}

export function logoutRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: logoutRequestExtendedLoaderChunk(request) };
}

export function accountIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Account>>) => Promise<Array<Account>>): { promises: { [key: string]: (props) => Promise<Array<Account>> } } {
	return { promises: accountIndexRequestLoaderChunk(request) };
}

export function accountIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Account>>>) => Promise<ExtendedResponseData<Array<Account>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Account>>> } } {
	return { promises: accountIndexRequestExtendedLoaderChunk(request) };
}

export function accountStoreRequestLoader(request: (props, _: (input: Account, requestConfig?: AxiosRequestConfiguration) => Promise<Account>) => Promise<Account>): { promises: { [key: string]: (props) => Promise<Account> } } {
	return { promises: accountStoreRequestLoaderChunk(request) };
}

export function accountStoreRequestExtendedLoader(request: (props, _: (input: Account, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Account>>) => Promise<ExtendedResponseData<Account>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Account>> } } {
	return { promises: accountStoreRequestExtendedLoaderChunk(request) };
}

export function accountShowRequestLoader(request: (props, _: (accountId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Account>) => Promise<Account>): { promises: { [key: string]: (props) => Promise<Account> } } {
	return { promises: accountShowRequestLoaderChunk(request) };
}

export function accountShowRequestExtendedLoader(request: (props, _: (accountId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Account>>) => Promise<ExtendedResponseData<Account>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Account>> } } {
	return { promises: accountShowRequestExtendedLoaderChunk(request) };
}

export function accountUpdateRequestLoader(request: (props, _: (input: Account, requestConfig?: AxiosRequestConfiguration) => Promise<Account>) => Promise<Account>): { promises: { [key: string]: (props) => Promise<Account> } } {
	return { promises: accountUpdateRequestLoaderChunk(request) };
}

export function accountUpdateRequestExtendedLoader(request: (props, _: (input: Account, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Account>>) => Promise<ExtendedResponseData<Account>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Account>> } } {
	return { promises: accountUpdateRequestExtendedLoaderChunk(request) };
}

export function accountDestroyRequestLoader(request: (props, _: (accountId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: accountDestroyRequestLoaderChunk(request) };
}

export function accountDestroyRequestExtendedLoader(request: (props, _: (accountId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: accountDestroyRequestExtendedLoaderChunk(request) };
}

export function setAccountAvatarRequestLoader(request: (props, _: (accountId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: setAccountAvatarRequestLoaderChunk(request) };
}

export function setAccountAvatarRequestExtendedLoader(request: (props, _: (accountId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: setAccountAvatarRequestExtendedLoaderChunk(request) };
}

export function setAccountLogoRequestLoader(request: (props, _: (accountId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: setAccountLogoRequestLoaderChunk(request) };
}

export function setAccountLogoRequestExtendedLoader(request: (props, _: (accountId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: setAccountLogoRequestExtendedLoaderChunk(request) };
}

export function projectIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Project>>) => Promise<Array<Project>>): { promises: { [key: string]: (props) => Promise<Array<Project>> } } {
	return { promises: projectIndexRequestLoaderChunk(request) };
}

export function projectIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Project>>>) => Promise<ExtendedResponseData<Array<Project>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Project>>> } } {
	return { promises: projectIndexRequestExtendedLoaderChunk(request) };
}

export function projectStoreRequestLoader(request: (props, _: (input: Project, requestConfig?: AxiosRequestConfiguration) => Promise<Project>) => Promise<Project>): { promises: { [key: string]: (props) => Promise<Project> } } {
	return { promises: projectStoreRequestLoaderChunk(request) };
}

export function projectStoreRequestExtendedLoader(request: (props, _: (input: Project, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Project>>) => Promise<ExtendedResponseData<Project>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Project>> } } {
	return { promises: projectStoreRequestExtendedLoaderChunk(request) };
}

export function projectShowRequestLoader(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Project>) => Promise<Project>): { promises: { [key: string]: (props) => Promise<Project> } } {
	return { promises: projectShowRequestLoaderChunk(request) };
}

export function projectShowRequestExtendedLoader(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Project>>) => Promise<ExtendedResponseData<Project>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Project>> } } {
	return { promises: projectShowRequestExtendedLoaderChunk(request) };
}

export function projectUpdateRequestLoader(request: (props, _: (input: Project, requestConfig?: AxiosRequestConfiguration) => Promise<Project>) => Promise<Project>): { promises: { [key: string]: (props) => Promise<Project> } } {
	return { promises: projectUpdateRequestLoaderChunk(request) };
}

export function projectUpdateRequestExtendedLoader(request: (props, _: (input: Project, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Project>>) => Promise<ExtendedResponseData<Project>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Project>> } } {
	return { promises: projectUpdateRequestExtendedLoaderChunk(request) };
}

export function projectDestroyRequestLoader(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: projectDestroyRequestLoaderChunk(request) };
}

export function projectDestroyRequestExtendedLoader(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: projectDestroyRequestExtendedLoaderChunk(request) };
}

export function setProjectAvatarRequestLoader(request: (props, _: (projectId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: setProjectAvatarRequestLoaderChunk(request) };
}

export function setProjectAvatarRequestExtendedLoader(request: (props, _: (projectId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: setProjectAvatarRequestExtendedLoaderChunk(request) };
}

export function mediaIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Media>>) => Promise<Array<Media>>): { promises: { [key: string]: (props) => Promise<Array<Media>> } } {
	return { promises: mediaIndexRequestLoaderChunk(request) };
}

export function mediaIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Media>>>) => Promise<ExtendedResponseData<Array<Media>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Media>>> } } {
	return { promises: mediaIndexRequestExtendedLoaderChunk(request) };
}

export function mediaStoreRequestLoader(request: (props, _: (input: Media, requestConfig?: AxiosRequestConfiguration) => Promise<Media>) => Promise<Media>): { promises: { [key: string]: (props) => Promise<Media> } } {
	return { promises: mediaStoreRequestLoaderChunk(request) };
}

export function mediaStoreRequestExtendedLoader(request: (props, _: (input: Media, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Media>>) => Promise<ExtendedResponseData<Media>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Media>> } } {
	return { promises: mediaStoreRequestExtendedLoaderChunk(request) };
}

export function mediaShowRequestLoader(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Media>) => Promise<Media>): { promises: { [key: string]: (props) => Promise<Media> } } {
	return { promises: mediaShowRequestLoaderChunk(request) };
}

export function mediaShowRequestExtendedLoader(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Media>>) => Promise<ExtendedResponseData<Media>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Media>> } } {
	return { promises: mediaShowRequestExtendedLoaderChunk(request) };
}

export function mediaUpdateRequestLoader(request: (props, _: (input: Media, requestConfig?: AxiosRequestConfiguration) => Promise<Media>) => Promise<Media>): { promises: { [key: string]: (props) => Promise<Media> } } {
	return { promises: mediaUpdateRequestLoaderChunk(request) };
}

export function mediaUpdateRequestExtendedLoader(request: (props, _: (input: Media, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Media>>) => Promise<ExtendedResponseData<Media>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Media>> } } {
	return { promises: mediaUpdateRequestExtendedLoaderChunk(request) };
}

export function mediaDestroyRequestLoader(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: mediaDestroyRequestLoaderChunk(request) };
}

export function mediaDestroyRequestExtendedLoader(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: mediaDestroyRequestExtendedLoaderChunk(request) };
}

export function setMediaAvatarRequestLoader(request: (props, _: (mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: setMediaAvatarRequestLoaderChunk(request) };
}

export function setMediaAvatarRequestExtendedLoader(request: (props, _: (mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: setMediaAvatarRequestExtendedLoaderChunk(request) };
}

export function userIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<User>>) => Promise<Array<User>>): { promises: { [key: string]: (props) => Promise<Array<User>> } } {
	return { promises: userIndexRequestLoaderChunk(request) };
}

export function userIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<User>>>) => Promise<ExtendedResponseData<Array<User>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<User>>> } } {
	return { promises: userIndexRequestExtendedLoaderChunk(request) };
}

export function userStoreRequestLoader(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { promises: { [key: string]: (props) => Promise<User> } } {
	return { promises: userStoreRequestLoaderChunk(request) };
}

export function userStoreRequestExtendedLoader(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<User>> } } {
	return { promises: userStoreRequestExtendedLoaderChunk(request) };
}

export function userShowRequestLoader(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { promises: { [key: string]: (props) => Promise<User> } } {
	return { promises: userShowRequestLoaderChunk(request) };
}

export function userShowRequestExtendedLoader(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<User>> } } {
	return { promises: userShowRequestExtendedLoaderChunk(request) };
}

export function userUpdateRequestLoader(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<User>) => Promise<User>): { promises: { [key: string]: (props) => Promise<User> } } {
	return { promises: userUpdateRequestLoaderChunk(request) };
}

export function userUpdateRequestExtendedLoader(request: (props, _: (input: User, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<User>>) => Promise<ExtendedResponseData<User>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<User>> } } {
	return { promises: userUpdateRequestExtendedLoaderChunk(request) };
}

export function userDestroyRequestLoader(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: userDestroyRequestLoaderChunk(request) };
}

export function userDestroyRequestExtendedLoader(request: (props, _: (userId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: userDestroyRequestExtendedLoaderChunk(request) };
}

export function setUserAvatarRequestLoader(request: (props, _: (userId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: setUserAvatarRequestLoaderChunk(request) };
}

export function setUserAvatarRequestExtendedLoader(request: (props, _: (userId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: setUserAvatarRequestExtendedLoaderChunk(request) };
}

export function discussionIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Discussion>>) => Promise<Array<Discussion>>): { promises: { [key: string]: (props) => Promise<Array<Discussion>> } } {
	return { promises: discussionIndexRequestLoaderChunk(request) };
}

export function discussionIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Discussion>>>) => Promise<ExtendedResponseData<Array<Discussion>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Discussion>>> } } {
	return { promises: discussionIndexRequestExtendedLoaderChunk(request) };
}

export function discussionStoreRequestLoader(request: (props, _: (input: Discussion, requestConfig?: AxiosRequestConfiguration) => Promise<Discussion>) => Promise<Discussion>): { promises: { [key: string]: (props) => Promise<Discussion> } } {
	return { promises: discussionStoreRequestLoaderChunk(request) };
}

export function discussionStoreRequestExtendedLoader(request: (props, _: (input: Discussion, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Discussion>>) => Promise<ExtendedResponseData<Discussion>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Discussion>> } } {
	return { promises: discussionStoreRequestExtendedLoaderChunk(request) };
}

export function discussionShowRequestLoader(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Discussion>) => Promise<Discussion>): { promises: { [key: string]: (props) => Promise<Discussion> } } {
	return { promises: discussionShowRequestLoaderChunk(request) };
}

export function discussionShowRequestExtendedLoader(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Discussion>>) => Promise<ExtendedResponseData<Discussion>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Discussion>> } } {
	return { promises: discussionShowRequestExtendedLoaderChunk(request) };
}

export function discussionUpdateRequestLoader(request: (props, _: (input: Discussion, requestConfig?: AxiosRequestConfiguration) => Promise<Discussion>) => Promise<Discussion>): { promises: { [key: string]: (props) => Promise<Discussion> } } {
	return { promises: discussionUpdateRequestLoaderChunk(request) };
}

export function discussionUpdateRequestExtendedLoader(request: (props, _: (input: Discussion, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Discussion>>) => Promise<ExtendedResponseData<Discussion>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Discussion>> } } {
	return { promises: discussionUpdateRequestExtendedLoaderChunk(request) };
}

export function discussionDestroyRequestLoader(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: discussionDestroyRequestLoaderChunk(request) };
}

export function discussionDestroyRequestExtendedLoader(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: discussionDestroyRequestExtendedLoaderChunk(request) };
}

export function setDiscussionAvatarRequestLoader(request: (props, _: (discussionId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: setDiscussionAvatarRequestLoaderChunk(request) };
}

export function setDiscussionAvatarRequestExtendedLoader(request: (props, _: (discussionId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: setDiscussionAvatarRequestExtendedLoaderChunk(request) };
}

export function pinIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Pin>>) => Promise<Array<Pin>>): { promises: { [key: string]: (props) => Promise<Array<Pin>> } } {
	return { promises: pinIndexRequestLoaderChunk(request) };
}

export function pinIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Pin>>>) => Promise<ExtendedResponseData<Array<Pin>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Pin>>> } } {
	return { promises: pinIndexRequestExtendedLoaderChunk(request) };
}

export function pinStoreRequestLoader(request: (props, _: (input: Pin, requestConfig?: AxiosRequestConfiguration) => Promise<Pin>) => Promise<Pin>): { promises: { [key: string]: (props) => Promise<Pin> } } {
	return { promises: pinStoreRequestLoaderChunk(request) };
}

export function pinStoreRequestExtendedLoader(request: (props, _: (input: Pin, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Pin>>) => Promise<ExtendedResponseData<Pin>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Pin>> } } {
	return { promises: pinStoreRequestExtendedLoaderChunk(request) };
}

export function pinShowRequestLoader(request: (props, _: (pinId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Pin>) => Promise<Pin>): { promises: { [key: string]: (props) => Promise<Pin> } } {
	return { promises: pinShowRequestLoaderChunk(request) };
}

export function pinShowRequestExtendedLoader(request: (props, _: (pinId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Pin>>) => Promise<ExtendedResponseData<Pin>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Pin>> } } {
	return { promises: pinShowRequestExtendedLoaderChunk(request) };
}

export function pinUpdateRequestLoader(request: (props, _: (input: Pin, requestConfig?: AxiosRequestConfiguration) => Promise<Pin>) => Promise<Pin>): { promises: { [key: string]: (props) => Promise<Pin> } } {
	return { promises: pinUpdateRequestLoaderChunk(request) };
}

export function pinUpdateRequestExtendedLoader(request: (props, _: (input: Pin, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Pin>>) => Promise<ExtendedResponseData<Pin>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Pin>> } } {
	return { promises: pinUpdateRequestExtendedLoaderChunk(request) };
}

export function pinDestroyRequestLoader(request: (props, _: (pinId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: pinDestroyRequestLoaderChunk(request) };
}

export function pinDestroyRequestExtendedLoader(request: (props, _: (pinId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: pinDestroyRequestExtendedLoaderChunk(request) };
}

export function pollIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Poll>>) => Promise<Array<Poll>>): { promises: { [key: string]: (props) => Promise<Array<Poll>> } } {
	return { promises: pollIndexRequestLoaderChunk(request) };
}

export function pollIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Poll>>>) => Promise<ExtendedResponseData<Array<Poll>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Poll>>> } } {
	return { promises: pollIndexRequestExtendedLoaderChunk(request) };
}

export function pollStoreRequestLoader(request: (props, _: (input: Poll, requestConfig?: AxiosRequestConfiguration) => Promise<Poll>) => Promise<Poll>): { promises: { [key: string]: (props) => Promise<Poll> } } {
	return { promises: pollStoreRequestLoaderChunk(request) };
}

export function pollStoreRequestExtendedLoader(request: (props, _: (input: Poll, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Poll>>) => Promise<ExtendedResponseData<Poll>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Poll>> } } {
	return { promises: pollStoreRequestExtendedLoaderChunk(request) };
}

export function pollShowRequestLoader(request: (props, _: (pollId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Poll>) => Promise<Poll>): { promises: { [key: string]: (props) => Promise<Poll> } } {
	return { promises: pollShowRequestLoaderChunk(request) };
}

export function pollShowRequestExtendedLoader(request: (props, _: (pollId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Poll>>) => Promise<ExtendedResponseData<Poll>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Poll>> } } {
	return { promises: pollShowRequestExtendedLoaderChunk(request) };
}

export function pollUpdateRequestLoader(request: (props, _: (input: Poll, requestConfig?: AxiosRequestConfiguration) => Promise<Poll>) => Promise<Poll>): { promises: { [key: string]: (props) => Promise<Poll> } } {
	return { promises: pollUpdateRequestLoaderChunk(request) };
}

export function pollUpdateRequestExtendedLoader(request: (props, _: (input: Poll, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Poll>>) => Promise<ExtendedResponseData<Poll>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Poll>> } } {
	return { promises: pollUpdateRequestExtendedLoaderChunk(request) };
}

export function pollDestroyRequestLoader(request: (props, _: (pollId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: pollDestroyRequestLoaderChunk(request) };
}

export function pollDestroyRequestExtendedLoader(request: (props, _: (pollId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: pollDestroyRequestExtendedLoaderChunk(request) };
}

export function setPollAvatarRequestLoader(request: (props, _: (pollId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: setPollAvatarRequestLoaderChunk(request) };
}

export function setPollAvatarRequestExtendedLoader(request: (props, _: (pollId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: setPollAvatarRequestExtendedLoaderChunk(request) };
}

export function voteIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Vote>>) => Promise<Array<Vote>>): { promises: { [key: string]: (props) => Promise<Array<Vote>> } } {
	return { promises: voteIndexRequestLoaderChunk(request) };
}

export function voteIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Vote>>>) => Promise<ExtendedResponseData<Array<Vote>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Vote>>> } } {
	return { promises: voteIndexRequestExtendedLoaderChunk(request) };
}

export function voteStoreRequestLoader(request: (props, _: (input: Vote, requestConfig?: AxiosRequestConfiguration) => Promise<Vote>) => Promise<Vote>): { promises: { [key: string]: (props) => Promise<Vote> } } {
	return { promises: voteStoreRequestLoaderChunk(request) };
}

export function voteStoreRequestExtendedLoader(request: (props, _: (input: Vote, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Vote>>) => Promise<ExtendedResponseData<Vote>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Vote>> } } {
	return { promises: voteStoreRequestExtendedLoaderChunk(request) };
}

export function voteShowRequestLoader(request: (props, _: (voteId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Vote>) => Promise<Vote>): { promises: { [key: string]: (props) => Promise<Vote> } } {
	return { promises: voteShowRequestLoaderChunk(request) };
}

export function voteShowRequestExtendedLoader(request: (props, _: (voteId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Vote>>) => Promise<ExtendedResponseData<Vote>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Vote>> } } {
	return { promises: voteShowRequestExtendedLoaderChunk(request) };
}

export function voteUpdateRequestLoader(request: (props, _: (input: Vote, requestConfig?: AxiosRequestConfiguration) => Promise<Vote>) => Promise<Vote>): { promises: { [key: string]: (props) => Promise<Vote> } } {
	return { promises: voteUpdateRequestLoaderChunk(request) };
}

export function voteUpdateRequestExtendedLoader(request: (props, _: (input: Vote, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Vote>>) => Promise<ExtendedResponseData<Vote>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Vote>> } } {
	return { promises: voteUpdateRequestExtendedLoaderChunk(request) };
}

export function voteDestroyRequestLoader(request: (props, _: (voteId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: voteDestroyRequestLoaderChunk(request) };
}

export function voteDestroyRequestExtendedLoader(request: (props, _: (voteId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: voteDestroyRequestExtendedLoaderChunk(request) };
}

export function setVoteAvatarRequestLoader(request: (props, _: (voteId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: setVoteAvatarRequestLoaderChunk(request) };
}

export function setVoteAvatarRequestExtendedLoader(request: (props, _: (voteId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: setVoteAvatarRequestExtendedLoaderChunk(request) };
}

export function checklistIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Checklist>>) => Promise<Array<Checklist>>): { promises: { [key: string]: (props) => Promise<Array<Checklist>> } } {
	return { promises: checklistIndexRequestLoaderChunk(request) };
}

export function checklistIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Checklist>>>) => Promise<ExtendedResponseData<Array<Checklist>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Checklist>>> } } {
	return { promises: checklistIndexRequestExtendedLoaderChunk(request) };
}

export function checklistStoreRequestLoader(request: (props, _: (input: Checklist, requestConfig?: AxiosRequestConfiguration) => Promise<Checklist>) => Promise<Checklist>): { promises: { [key: string]: (props) => Promise<Checklist> } } {
	return { promises: checklistStoreRequestLoaderChunk(request) };
}

export function checklistStoreRequestExtendedLoader(request: (props, _: (input: Checklist, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Checklist>>) => Promise<ExtendedResponseData<Checklist>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Checklist>> } } {
	return { promises: checklistStoreRequestExtendedLoaderChunk(request) };
}

export function checklistShowRequestLoader(request: (props, _: (checklistId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Checklist>) => Promise<Checklist>): { promises: { [key: string]: (props) => Promise<Checklist> } } {
	return { promises: checklistShowRequestLoaderChunk(request) };
}

export function checklistShowRequestExtendedLoader(request: (props, _: (checklistId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Checklist>>) => Promise<ExtendedResponseData<Checklist>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Checklist>> } } {
	return { promises: checklistShowRequestExtendedLoaderChunk(request) };
}

export function checklistUpdateRequestLoader(request: (props, _: (input: Checklist, requestConfig?: AxiosRequestConfiguration) => Promise<Checklist>) => Promise<Checklist>): { promises: { [key: string]: (props) => Promise<Checklist> } } {
	return { promises: checklistUpdateRequestLoaderChunk(request) };
}

export function checklistUpdateRequestExtendedLoader(request: (props, _: (input: Checklist, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Checklist>>) => Promise<ExtendedResponseData<Checklist>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Checklist>> } } {
	return { promises: checklistUpdateRequestExtendedLoaderChunk(request) };
}

export function checklistDestroyRequestLoader(request: (props, _: (checklistId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: checklistDestroyRequestLoaderChunk(request) };
}

export function checklistDestroyRequestExtendedLoader(request: (props, _: (checklistId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: checklistDestroyRequestExtendedLoaderChunk(request) };
}

export function setChecklistAvatarRequestLoader(request: (props, _: (checklistId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: setChecklistAvatarRequestLoaderChunk(request) };
}

export function setChecklistAvatarRequestExtendedLoader(request: (props, _: (checklistId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: setChecklistAvatarRequestExtendedLoaderChunk(request) };
}

export function mediaMapImageIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<MediaMapImage>>) => Promise<Array<MediaMapImage>>): { promises: { [key: string]: (props) => Promise<Array<MediaMapImage>> } } {
	return { promises: mediaMapImageIndexRequestLoaderChunk(request) };
}

export function mediaMapImageIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<MediaMapImage>>>) => Promise<ExtendedResponseData<Array<MediaMapImage>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<MediaMapImage>>> } } {
	return { promises: mediaMapImageIndexRequestExtendedLoaderChunk(request) };
}

export function mediaMapImageStoreRequestLoader(request: (props, _: (input: MediaMapImage, requestConfig?: AxiosRequestConfiguration) => Promise<MediaMapImage>) => Promise<MediaMapImage>): { promises: { [key: string]: (props) => Promise<MediaMapImage> } } {
	return { promises: mediaMapImageStoreRequestLoaderChunk(request) };
}

export function mediaMapImageStoreRequestExtendedLoader(request: (props, _: (input: MediaMapImage, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<MediaMapImage>>) => Promise<ExtendedResponseData<MediaMapImage>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<MediaMapImage>> } } {
	return { promises: mediaMapImageStoreRequestExtendedLoaderChunk(request) };
}

export function mediaMapImageShowRequestLoader(request: (props, _: (mediaMapImageId: number, requestConfig?: AxiosRequestConfiguration) => Promise<MediaMapImage>) => Promise<MediaMapImage>): { promises: { [key: string]: (props) => Promise<MediaMapImage> } } {
	return { promises: mediaMapImageShowRequestLoaderChunk(request) };
}

export function mediaMapImageShowRequestExtendedLoader(request: (props, _: (mediaMapImageId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<MediaMapImage>>) => Promise<ExtendedResponseData<MediaMapImage>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<MediaMapImage>> } } {
	return { promises: mediaMapImageShowRequestExtendedLoaderChunk(request) };
}

export function mediaMapImageUpdateRequestLoader(request: (props, _: (input: MediaMapImage, requestConfig?: AxiosRequestConfiguration) => Promise<MediaMapImage>) => Promise<MediaMapImage>): { promises: { [key: string]: (props) => Promise<MediaMapImage> } } {
	return { promises: mediaMapImageUpdateRequestLoaderChunk(request) };
}

export function mediaMapImageUpdateRequestExtendedLoader(request: (props, _: (input: MediaMapImage, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<MediaMapImage>>) => Promise<ExtendedResponseData<MediaMapImage>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<MediaMapImage>> } } {
	return { promises: mediaMapImageUpdateRequestExtendedLoaderChunk(request) };
}

export function mediaMapImageDestroyRequestLoader(request: (props, _: (mediaMapImageId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: mediaMapImageDestroyRequestLoaderChunk(request) };
}

export function mediaMapImageDestroyRequestExtendedLoader(request: (props, _: (mediaMapImageId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: mediaMapImageDestroyRequestExtendedLoaderChunk(request) };
}

export function mediaMapDrawingIndexRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<MediaMapDrawing>>) => Promise<Array<MediaMapDrawing>>): { promises: { [key: string]: (props) => Promise<Array<MediaMapDrawing>> } } {
	return { promises: mediaMapDrawingIndexRequestLoaderChunk(request) };
}

export function mediaMapDrawingIndexRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<MediaMapDrawing>>>) => Promise<ExtendedResponseData<Array<MediaMapDrawing>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<MediaMapDrawing>>> } } {
	return { promises: mediaMapDrawingIndexRequestExtendedLoaderChunk(request) };
}

export function mediaMapDrawingStoreRequestLoader(request: (props, _: (input: MediaMapDrawing, requestConfig?: AxiosRequestConfiguration) => Promise<MediaMapDrawing>) => Promise<MediaMapDrawing>): { promises: { [key: string]: (props) => Promise<MediaMapDrawing> } } {
	return { promises: mediaMapDrawingStoreRequestLoaderChunk(request) };
}

export function mediaMapDrawingStoreRequestExtendedLoader(request: (props, _: (input: MediaMapDrawing, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<MediaMapDrawing>>) => Promise<ExtendedResponseData<MediaMapDrawing>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<MediaMapDrawing>> } } {
	return { promises: mediaMapDrawingStoreRequestExtendedLoaderChunk(request) };
}

export function mediaMapDrawingShowRequestLoader(request: (props, _: (mediaMapDrawingId: number, requestConfig?: AxiosRequestConfiguration) => Promise<MediaMapDrawing>) => Promise<MediaMapDrawing>): { promises: { [key: string]: (props) => Promise<MediaMapDrawing> } } {
	return { promises: mediaMapDrawingShowRequestLoaderChunk(request) };
}

export function mediaMapDrawingShowRequestExtendedLoader(request: (props, _: (mediaMapDrawingId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<MediaMapDrawing>>) => Promise<ExtendedResponseData<MediaMapDrawing>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<MediaMapDrawing>> } } {
	return { promises: mediaMapDrawingShowRequestExtendedLoaderChunk(request) };
}

export function mediaMapDrawingUpdateRequestLoader(request: (props, _: (input: MediaMapDrawing, requestConfig?: AxiosRequestConfiguration) => Promise<MediaMapDrawing>) => Promise<MediaMapDrawing>): { promises: { [key: string]: (props) => Promise<MediaMapDrawing> } } {
	return { promises: mediaMapDrawingUpdateRequestLoaderChunk(request) };
}

export function mediaMapDrawingUpdateRequestExtendedLoader(request: (props, _: (input: MediaMapDrawing, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<MediaMapDrawing>>) => Promise<ExtendedResponseData<MediaMapDrawing>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<MediaMapDrawing>> } } {
	return { promises: mediaMapDrawingUpdateRequestExtendedLoaderChunk(request) };
}

export function mediaMapDrawingDestroyRequestLoader(request: (props, _: (mediaMapDrawingId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: mediaMapDrawingDestroyRequestLoaderChunk(request) };
}

export function mediaMapDrawingDestroyRequestExtendedLoader(request: (props, _: (mediaMapDrawingId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: mediaMapDrawingDestroyRequestExtendedLoaderChunk(request) };
}

export function mediaImgRequestLoader(request: (props, _: (mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: mediaImgRequestLoaderChunk(request) };
}

export function mediaImgRequestExtendedLoader(request: (props, _: (mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: mediaImgRequestExtendedLoaderChunk(request) };
}

export function mediaPdfRequestLoader(request: (props, _: (mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: mediaPdfRequestLoaderChunk(request) };
}

export function mediaPdfRequestExtendedLoader(request: (props, _: (mediaId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: mediaPdfRequestExtendedLoaderChunk(request) };
}

export function mediaMapImgRequestLoader(request: (props, _: (mediaMapImageId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: mediaMapImgRequestLoaderChunk(request) };
}

export function mediaMapImgRequestExtendedLoader(request: (props, _: (mediaMapImageId: number, files: FilesInput, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: mediaMapImgRequestExtendedLoaderChunk(request) };
}

export function discussionCommentsRequestLoader(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Array<Comment>>) => Promise<Array<Comment>>): { promises: { [key: string]: (props) => Promise<Array<Comment>> } } {
	return { promises: discussionCommentsRequestLoaderChunk(request) };
}

export function discussionCommentsRequestExtendedLoader(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Comment>>>) => Promise<ExtendedResponseData<Array<Comment>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Comment>>> } } {
	return { promises: discussionCommentsRequestExtendedLoaderChunk(request) };
}

export function mediaCommentsRequestLoader(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Array<Comment>>) => Promise<Array<Comment>>): { promises: { [key: string]: (props) => Promise<Array<Comment>> } } {
	return { promises: mediaCommentsRequestLoaderChunk(request) };
}

export function mediaCommentsRequestExtendedLoader(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Comment>>>) => Promise<ExtendedResponseData<Array<Comment>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Comment>>> } } {
	return { promises: mediaCommentsRequestExtendedLoaderChunk(request) };
}

export function projectCommentsRequestLoader(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Array<Comment>>) => Promise<Array<Comment>>): { promises: { [key: string]: (props) => Promise<Array<Comment>> } } {
	return { promises: projectCommentsRequestLoaderChunk(request) };
}

export function projectCommentsRequestExtendedLoader(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Comment>>>) => Promise<ExtendedResponseData<Array<Comment>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Comment>>> } } {
	return { promises: projectCommentsRequestExtendedLoaderChunk(request) };
}

export function commentApproveRequestLoader(request: (props, _: (commentId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: commentApproveRequestLoaderChunk(request) };
}

export function commentApproveRequestExtendedLoader(request: (props, _: (commentId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: commentApproveRequestExtendedLoaderChunk(request) };
}

export function commentSpamRequestLoader(request: (props, _: (commentId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: commentSpamRequestLoaderChunk(request) };
}

export function commentSpamRequestExtendedLoader(request: (props, _: (commentId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: commentSpamRequestExtendedLoaderChunk(request) };
}

export function tagUpdateRequestLoader(request: (props, _: (input: Tag, requestConfig?: AxiosRequestConfiguration) => Promise<Tag>) => Promise<Tag>): { promises: { [key: string]: (props) => Promise<Tag> } } {
	return { promises: tagUpdateRequestLoaderChunk(request) };
}

export function tagUpdateRequestExtendedLoader(request: (props, _: (input: Tag, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Tag>>) => Promise<ExtendedResponseData<Tag>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Tag>> } } {
	return { promises: tagUpdateRequestExtendedLoaderChunk(request) };
}

export function accountRequestLoader(request: (props, _: (accountId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Account>) => Promise<Account>): { promises: { [key: string]: (props) => Promise<Account> } } {
	return { promises: accountRequestLoaderChunk(request) };
}

export function accountRequestExtendedLoader(request: (props, _: (accountId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Account>>) => Promise<ExtendedResponseData<Account>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Account>> } } {
	return { promises: accountRequestExtendedLoaderChunk(request) };
}

export function projectsRequestLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<Array<Project>>) => Promise<Array<Project>>): { promises: { [key: string]: (props) => Promise<Array<Project>> } } {
	return { promises: projectsRequestLoaderChunk(request) };
}

export function projectsRequestExtendedLoader(request: (props, _: (requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Project>>>) => Promise<ExtendedResponseData<Array<Project>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Project>>> } } {
	return { promises: projectsRequestExtendedLoaderChunk(request) };
}

export function projectsInBoundsRequestLoader(request: (props, _: (swLat: number, swLng: number, neLat: number, neLng: number, requestConfig?: AxiosRequestConfiguration) => Promise<Array<Project>>) => Promise<Array<Project>>): { promises: { [key: string]: (props) => Promise<Array<Project>> } } {
	return { promises: projectsInBoundsRequestLoaderChunk(request) };
}

export function projectsInBoundsRequestExtendedLoader(request: (props, _: (swLat: number, swLng: number, neLat: number, neLng: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Project>>>) => Promise<ExtendedResponseData<Array<Project>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Project>>> } } {
	return { promises: projectsInBoundsRequestExtendedLoaderChunk(request) };
}

export function projectRequestLoader(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Project>) => Promise<Project>): { promises: { [key: string]: (props) => Promise<Project> } } {
	return { promises: projectRequestLoaderChunk(request) };
}

export function projectRequestExtendedLoader(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Project>>) => Promise<ExtendedResponseData<Project>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Project>> } } {
	return { promises: projectRequestExtendedLoaderChunk(request) };
}

export function showProjectByAliasRequestLoader(request: (props, _: (projectName: string, requestConfig?: AxiosRequestConfiguration) => Promise<Project>) => Promise<Project>): { promises: { [key: string]: (props) => Promise<Project> } } {
	return { promises: showProjectByAliasRequestLoaderChunk(request) };
}

export function showProjectByAliasRequestExtendedLoader(request: (props, _: (projectName: string, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Project>>) => Promise<ExtendedResponseData<Project>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Project>> } } {
	return { promises: showProjectByAliasRequestExtendedLoaderChunk(request) };
}

export function mediaRequestLoader(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Media>) => Promise<Media>): { promises: { [key: string]: (props) => Promise<Media> } } {
	return { promises: mediaRequestLoaderChunk(request) };
}

export function mediaRequestExtendedLoader(request: (props, _: (mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Media>>) => Promise<ExtendedResponseData<Media>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Media>> } } {
	return { promises: mediaRequestExtendedLoaderChunk(request) };
}

export function showMediaByAliasRequestLoader(request: (props, _: (mediaName: string, requestConfig?: AxiosRequestConfiguration) => Promise<Media>) => Promise<Media>): { promises: { [key: string]: (props) => Promise<Media> } } {
	return { promises: showMediaByAliasRequestLoaderChunk(request) };
}

export function showMediaByAliasRequestExtendedLoader(request: (props, _: (mediaName: string, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Media>>) => Promise<ExtendedResponseData<Media>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Media>> } } {
	return { promises: showMediaByAliasRequestExtendedLoaderChunk(request) };
}

export function discussionRequestLoader(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Discussion>) => Promise<Discussion>): { promises: { [key: string]: (props) => Promise<Discussion> } } {
	return { promises: discussionRequestLoaderChunk(request) };
}

export function discussionRequestExtendedLoader(request: (props, _: (discussionId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Discussion>>) => Promise<ExtendedResponseData<Discussion>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Discussion>> } } {
	return { promises: discussionRequestExtendedLoaderChunk(request) };
}

export function showDiscussionByAliasRequestLoader(request: (props, _: (discussionName: string, requestConfig?: AxiosRequestConfiguration) => Promise<Discussion>) => Promise<Discussion>): { promises: { [key: string]: (props) => Promise<Discussion> } } {
	return { promises: showDiscussionByAliasRequestLoaderChunk(request) };
}

export function showDiscussionByAliasRequestExtendedLoader(request: (props, _: (discussionName: string, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Discussion>>) => Promise<ExtendedResponseData<Discussion>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Discussion>> } } {
	return { promises: showDiscussionByAliasRequestExtendedLoaderChunk(request) };
}

export function projectDiscussionsRequestLoader(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Array<Discussion>>) => Promise<Array<Discussion>>): { promises: { [key: string]: (props) => Promise<Array<Discussion>> } } {
	return { promises: projectDiscussionsRequestLoaderChunk(request) };
}

export function projectDiscussionsRequestExtendedLoader(request: (props, _: (projectId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Array<Discussion>>>) => Promise<ExtendedResponseData<Array<Discussion>>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Array<Discussion>>> } } {
	return { promises: projectDiscussionsRequestExtendedLoaderChunk(request) };
}

export function pinRequestLoader(request: (props, _: (input: Pin, mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<Pin>) => Promise<Pin>): { promises: { [key: string]: (props) => Promise<Pin> } } {
	return { promises: pinRequestLoaderChunk(request) };
}

export function pinRequestExtendedLoader(request: (props, _: (input: Pin, mediaId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<Pin>>) => Promise<ExtendedResponseData<Pin>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<Pin>> } } {
	return { promises: pinRequestExtendedLoaderChunk(request) };
}

export function requestPasswordRequestLoader(request: (props, _: (input: PasswordRequest, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: requestPasswordRequestLoaderChunk(request) };
}

export function requestPasswordRequestExtendedLoader(request: (props, _: (input: PasswordRequest, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: requestPasswordRequestExtendedLoaderChunk(request) };
}

export function updatePasswordRequestLoader(request: (props, _: (input: PasswordUpdate, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: updatePasswordRequestLoaderChunk(request) };
}

export function updatePasswordRequestExtendedLoader(request: (props, _: (input: PasswordUpdate, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: updatePasswordRequestExtendedLoaderChunk(request) };
}

export function pollAnswerRequestLoader(request: (props, _: (pollId: number, pollAnswerId: number, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: pollAnswerRequestLoaderChunk(request) };
}

export function pollAnswerRequestExtendedLoader(request: (props, _: (pollId: number, pollAnswerId: number, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: pollAnswerRequestExtendedLoaderChunk(request) };
}

export function voteAnswerRequestLoader(request: (props, _: (voteId: number, voteAnswer: boolean, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: voteAnswerRequestLoaderChunk(request) };
}

export function voteAnswerRequestExtendedLoader(request: (props, _: (voteId: number, voteAnswer: boolean, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: voteAnswerRequestExtendedLoaderChunk(request) };
}

export function checklistItemDoneRequestLoader(request: (props, _: (checklistId: number, checklistItemId: number, checklistDone: boolean, requestConfig?: AxiosRequestConfiguration) => Promise<void>) => Promise<void>): { promises: { [key: string]: (props) => Promise<void> } } {
	return { promises: checklistItemDoneRequestLoaderChunk(request) };
}

export function checklistItemDoneRequestExtendedLoader(request: (props, _: (checklistId: number, checklistItemId: number, checklistDone: boolean, requestConfig?: AxiosRequestConfiguration) => Promise<ExtendedResponseData<void>>) => Promise<ExtendedResponseData<void>>): { promises: { [key: string]: (props) => Promise<ExtendedResponseData<void>> } } {
	return { promises: checklistItemDoneRequestExtendedLoaderChunk(request) };
}

export interface LoginRequestPropsMapper {
	login: AuthResponse
}

export interface LoginRequestExtendedPropsMapper {
	loginExtended: ExtendedResponseData<AuthResponse>
}

export interface WhoRequestPropsMapper {
	who: User
}

export interface WhoRequestExtendedPropsMapper {
	whoExtended: ExtendedResponseData<User>
}

export interface LogoutRequestPropsMapper {
	logout: void
}

export interface LogoutRequestExtendedPropsMapper {
	logoutExtended: ExtendedResponseData<void>
}

export interface AccountIndexRequestPropsMapper {
	accountIndex: Array<Account>
}

export interface AccountIndexRequestExtendedPropsMapper {
	accountIndexExtended: ExtendedResponseData<Array<Account>>
}

export interface AccountStoreRequestPropsMapper {
	accountStore: Account
}

export interface AccountStoreRequestExtendedPropsMapper {
	accountStoreExtended: ExtendedResponseData<Account>
}

export interface AccountShowRequestPropsMapper {
	accountShow: Account
}

export interface AccountShowRequestExtendedPropsMapper {
	accountShowExtended: ExtendedResponseData<Account>
}

export interface AccountUpdateRequestPropsMapper {
	accountUpdate: Account
}

export interface AccountUpdateRequestExtendedPropsMapper {
	accountUpdateExtended: ExtendedResponseData<Account>
}

export interface AccountDestroyRequestPropsMapper {
	accountDestroy: void
}

export interface AccountDestroyRequestExtendedPropsMapper {
	accountDestroyExtended: ExtendedResponseData<void>
}

export interface SetAccountAvatarRequestPropsMapper {
	setAccountAvatar: void
}

export interface SetAccountAvatarRequestExtendedPropsMapper {
	setAccountAvatarExtended: ExtendedResponseData<void>
}

export interface SetAccountLogoRequestPropsMapper {
	setAccountLogo: void
}

export interface SetAccountLogoRequestExtendedPropsMapper {
	setAccountLogoExtended: ExtendedResponseData<void>
}

export interface ProjectIndexRequestPropsMapper {
	projectIndex: Array<Project>
}

export interface ProjectIndexRequestExtendedPropsMapper {
	projectIndexExtended: ExtendedResponseData<Array<Project>>
}

export interface ProjectStoreRequestPropsMapper {
	projectStore: Project
}

export interface ProjectStoreRequestExtendedPropsMapper {
	projectStoreExtended: ExtendedResponseData<Project>
}

export interface ProjectShowRequestPropsMapper {
	projectShow: Project
}

export interface ProjectShowRequestExtendedPropsMapper {
	projectShowExtended: ExtendedResponseData<Project>
}

export interface ProjectUpdateRequestPropsMapper {
	projectUpdate: Project
}

export interface ProjectUpdateRequestExtendedPropsMapper {
	projectUpdateExtended: ExtendedResponseData<Project>
}

export interface ProjectDestroyRequestPropsMapper {
	projectDestroy: void
}

export interface ProjectDestroyRequestExtendedPropsMapper {
	projectDestroyExtended: ExtendedResponseData<void>
}

export interface SetProjectAvatarRequestPropsMapper {
	setProjectAvatar: void
}

export interface SetProjectAvatarRequestExtendedPropsMapper {
	setProjectAvatarExtended: ExtendedResponseData<void>
}

export interface MediaIndexRequestPropsMapper {
	mediaIndex: Array<Media>
}

export interface MediaIndexRequestExtendedPropsMapper {
	mediaIndexExtended: ExtendedResponseData<Array<Media>>
}

export interface MediaStoreRequestPropsMapper {
	mediaStore: Media
}

export interface MediaStoreRequestExtendedPropsMapper {
	mediaStoreExtended: ExtendedResponseData<Media>
}

export interface MediaShowRequestPropsMapper {
	mediaShow: Media
}

export interface MediaShowRequestExtendedPropsMapper {
	mediaShowExtended: ExtendedResponseData<Media>
}

export interface MediaUpdateRequestPropsMapper {
	mediaUpdate: Media
}

export interface MediaUpdateRequestExtendedPropsMapper {
	mediaUpdateExtended: ExtendedResponseData<Media>
}

export interface MediaDestroyRequestPropsMapper {
	mediaDestroy: void
}

export interface MediaDestroyRequestExtendedPropsMapper {
	mediaDestroyExtended: ExtendedResponseData<void>
}

export interface SetMediaAvatarRequestPropsMapper {
	setMediaAvatar: void
}

export interface SetMediaAvatarRequestExtendedPropsMapper {
	setMediaAvatarExtended: ExtendedResponseData<void>
}

export interface UserIndexRequestPropsMapper {
	userIndex: Array<User>
}

export interface UserIndexRequestExtendedPropsMapper {
	userIndexExtended: ExtendedResponseData<Array<User>>
}

export interface UserStoreRequestPropsMapper {
	userStore: User
}

export interface UserStoreRequestExtendedPropsMapper {
	userStoreExtended: ExtendedResponseData<User>
}

export interface UserShowRequestPropsMapper {
	userShow: User
}

export interface UserShowRequestExtendedPropsMapper {
	userShowExtended: ExtendedResponseData<User>
}

export interface UserUpdateRequestPropsMapper {
	userUpdate: User
}

export interface UserUpdateRequestExtendedPropsMapper {
	userUpdateExtended: ExtendedResponseData<User>
}

export interface UserDestroyRequestPropsMapper {
	userDestroy: void
}

export interface UserDestroyRequestExtendedPropsMapper {
	userDestroyExtended: ExtendedResponseData<void>
}

export interface SetUserAvatarRequestPropsMapper {
	setUserAvatar: void
}

export interface SetUserAvatarRequestExtendedPropsMapper {
	setUserAvatarExtended: ExtendedResponseData<void>
}

export interface DiscussionIndexRequestPropsMapper {
	discussionIndex: Array<Discussion>
}

export interface DiscussionIndexRequestExtendedPropsMapper {
	discussionIndexExtended: ExtendedResponseData<Array<Discussion>>
}

export interface DiscussionStoreRequestPropsMapper {
	discussionStore: Discussion
}

export interface DiscussionStoreRequestExtendedPropsMapper {
	discussionStoreExtended: ExtendedResponseData<Discussion>
}

export interface DiscussionShowRequestPropsMapper {
	discussionShow: Discussion
}

export interface DiscussionShowRequestExtendedPropsMapper {
	discussionShowExtended: ExtendedResponseData<Discussion>
}

export interface DiscussionUpdateRequestPropsMapper {
	discussionUpdate: Discussion
}

export interface DiscussionUpdateRequestExtendedPropsMapper {
	discussionUpdateExtended: ExtendedResponseData<Discussion>
}

export interface DiscussionDestroyRequestPropsMapper {
	discussionDestroy: void
}

export interface DiscussionDestroyRequestExtendedPropsMapper {
	discussionDestroyExtended: ExtendedResponseData<void>
}

export interface SetDiscussionAvatarRequestPropsMapper {
	setDiscussionAvatar: void
}

export interface SetDiscussionAvatarRequestExtendedPropsMapper {
	setDiscussionAvatarExtended: ExtendedResponseData<void>
}

export interface PinIndexRequestPropsMapper {
	pinIndex: Array<Pin>
}

export interface PinIndexRequestExtendedPropsMapper {
	pinIndexExtended: ExtendedResponseData<Array<Pin>>
}

export interface PinStoreRequestPropsMapper {
	pinStore: Pin
}

export interface PinStoreRequestExtendedPropsMapper {
	pinStoreExtended: ExtendedResponseData<Pin>
}

export interface PinShowRequestPropsMapper {
	pinShow: Pin
}

export interface PinShowRequestExtendedPropsMapper {
	pinShowExtended: ExtendedResponseData<Pin>
}

export interface PinUpdateRequestPropsMapper {
	pinUpdate: Pin
}

export interface PinUpdateRequestExtendedPropsMapper {
	pinUpdateExtended: ExtendedResponseData<Pin>
}

export interface PinDestroyRequestPropsMapper {
	pinDestroy: void
}

export interface PinDestroyRequestExtendedPropsMapper {
	pinDestroyExtended: ExtendedResponseData<void>
}

export interface PollIndexRequestPropsMapper {
	pollIndex: Array<Poll>
}

export interface PollIndexRequestExtendedPropsMapper {
	pollIndexExtended: ExtendedResponseData<Array<Poll>>
}

export interface PollStoreRequestPropsMapper {
	pollStore: Poll
}

export interface PollStoreRequestExtendedPropsMapper {
	pollStoreExtended: ExtendedResponseData<Poll>
}

export interface PollShowRequestPropsMapper {
	pollShow: Poll
}

export interface PollShowRequestExtendedPropsMapper {
	pollShowExtended: ExtendedResponseData<Poll>
}

export interface PollUpdateRequestPropsMapper {
	pollUpdate: Poll
}

export interface PollUpdateRequestExtendedPropsMapper {
	pollUpdateExtended: ExtendedResponseData<Poll>
}

export interface PollDestroyRequestPropsMapper {
	pollDestroy: void
}

export interface PollDestroyRequestExtendedPropsMapper {
	pollDestroyExtended: ExtendedResponseData<void>
}

export interface SetPollAvatarRequestPropsMapper {
	setPollAvatar: void
}

export interface SetPollAvatarRequestExtendedPropsMapper {
	setPollAvatarExtended: ExtendedResponseData<void>
}

export interface VoteIndexRequestPropsMapper {
	voteIndex: Array<Vote>
}

export interface VoteIndexRequestExtendedPropsMapper {
	voteIndexExtended: ExtendedResponseData<Array<Vote>>
}

export interface VoteStoreRequestPropsMapper {
	voteStore: Vote
}

export interface VoteStoreRequestExtendedPropsMapper {
	voteStoreExtended: ExtendedResponseData<Vote>
}

export interface VoteShowRequestPropsMapper {
	voteShow: Vote
}

export interface VoteShowRequestExtendedPropsMapper {
	voteShowExtended: ExtendedResponseData<Vote>
}

export interface VoteUpdateRequestPropsMapper {
	voteUpdate: Vote
}

export interface VoteUpdateRequestExtendedPropsMapper {
	voteUpdateExtended: ExtendedResponseData<Vote>
}

export interface VoteDestroyRequestPropsMapper {
	voteDestroy: void
}

export interface VoteDestroyRequestExtendedPropsMapper {
	voteDestroyExtended: ExtendedResponseData<void>
}

export interface SetVoteAvatarRequestPropsMapper {
	setVoteAvatar: void
}

export interface SetVoteAvatarRequestExtendedPropsMapper {
	setVoteAvatarExtended: ExtendedResponseData<void>
}

export interface ChecklistIndexRequestPropsMapper {
	checklistIndex: Array<Checklist>
}

export interface ChecklistIndexRequestExtendedPropsMapper {
	checklistIndexExtended: ExtendedResponseData<Array<Checklist>>
}

export interface ChecklistStoreRequestPropsMapper {
	checklistStore: Checklist
}

export interface ChecklistStoreRequestExtendedPropsMapper {
	checklistStoreExtended: ExtendedResponseData<Checklist>
}

export interface ChecklistShowRequestPropsMapper {
	checklistShow: Checklist
}

export interface ChecklistShowRequestExtendedPropsMapper {
	checklistShowExtended: ExtendedResponseData<Checklist>
}

export interface ChecklistUpdateRequestPropsMapper {
	checklistUpdate: Checklist
}

export interface ChecklistUpdateRequestExtendedPropsMapper {
	checklistUpdateExtended: ExtendedResponseData<Checklist>
}

export interface ChecklistDestroyRequestPropsMapper {
	checklistDestroy: void
}

export interface ChecklistDestroyRequestExtendedPropsMapper {
	checklistDestroyExtended: ExtendedResponseData<void>
}

export interface SetChecklistAvatarRequestPropsMapper {
	setChecklistAvatar: void
}

export interface SetChecklistAvatarRequestExtendedPropsMapper {
	setChecklistAvatarExtended: ExtendedResponseData<void>
}

export interface MediaMapImageIndexRequestPropsMapper {
	mediaMapImageIndex: Array<MediaMapImage>
}

export interface MediaMapImageIndexRequestExtendedPropsMapper {
	mediaMapImageIndexExtended: ExtendedResponseData<Array<MediaMapImage>>
}

export interface MediaMapImageStoreRequestPropsMapper {
	mediaMapImageStore: MediaMapImage
}

export interface MediaMapImageStoreRequestExtendedPropsMapper {
	mediaMapImageStoreExtended: ExtendedResponseData<MediaMapImage>
}

export interface MediaMapImageShowRequestPropsMapper {
	mediaMapImageShow: MediaMapImage
}

export interface MediaMapImageShowRequestExtendedPropsMapper {
	mediaMapImageShowExtended: ExtendedResponseData<MediaMapImage>
}

export interface MediaMapImageUpdateRequestPropsMapper {
	mediaMapImageUpdate: MediaMapImage
}

export interface MediaMapImageUpdateRequestExtendedPropsMapper {
	mediaMapImageUpdateExtended: ExtendedResponseData<MediaMapImage>
}

export interface MediaMapImageDestroyRequestPropsMapper {
	mediaMapImageDestroy: void
}

export interface MediaMapImageDestroyRequestExtendedPropsMapper {
	mediaMapImageDestroyExtended: ExtendedResponseData<void>
}

export interface MediaMapDrawingIndexRequestPropsMapper {
	mediaMapDrawingIndex: Array<MediaMapDrawing>
}

export interface MediaMapDrawingIndexRequestExtendedPropsMapper {
	mediaMapDrawingIndexExtended: ExtendedResponseData<Array<MediaMapDrawing>>
}

export interface MediaMapDrawingStoreRequestPropsMapper {
	mediaMapDrawingStore: MediaMapDrawing
}

export interface MediaMapDrawingStoreRequestExtendedPropsMapper {
	mediaMapDrawingStoreExtended: ExtendedResponseData<MediaMapDrawing>
}

export interface MediaMapDrawingShowRequestPropsMapper {
	mediaMapDrawingShow: MediaMapDrawing
}

export interface MediaMapDrawingShowRequestExtendedPropsMapper {
	mediaMapDrawingShowExtended: ExtendedResponseData<MediaMapDrawing>
}

export interface MediaMapDrawingUpdateRequestPropsMapper {
	mediaMapDrawingUpdate: MediaMapDrawing
}

export interface MediaMapDrawingUpdateRequestExtendedPropsMapper {
	mediaMapDrawingUpdateExtended: ExtendedResponseData<MediaMapDrawing>
}

export interface MediaMapDrawingDestroyRequestPropsMapper {
	mediaMapDrawingDestroy: void
}

export interface MediaMapDrawingDestroyRequestExtendedPropsMapper {
	mediaMapDrawingDestroyExtended: ExtendedResponseData<void>
}

export interface MediaImgRequestPropsMapper {
	mediaImg: void
}

export interface MediaImgRequestExtendedPropsMapper {
	mediaImgExtended: ExtendedResponseData<void>
}

export interface MediaPdfRequestPropsMapper {
	mediaPdf: void
}

export interface MediaPdfRequestExtendedPropsMapper {
	mediaPdfExtended: ExtendedResponseData<void>
}

export interface MediaMapImgRequestPropsMapper {
	mediaMapImg: void
}

export interface MediaMapImgRequestExtendedPropsMapper {
	mediaMapImgExtended: ExtendedResponseData<void>
}

export interface DiscussionCommentsRequestPropsMapper {
	discussionComments: Array<Comment>
}

export interface DiscussionCommentsRequestExtendedPropsMapper {
	discussionCommentsExtended: ExtendedResponseData<Array<Comment>>
}

export interface MediaCommentsRequestPropsMapper {
	mediaComments: Array<Comment>
}

export interface MediaCommentsRequestExtendedPropsMapper {
	mediaCommentsExtended: ExtendedResponseData<Array<Comment>>
}

export interface ProjectCommentsRequestPropsMapper {
	projectComments: Array<Comment>
}

export interface ProjectCommentsRequestExtendedPropsMapper {
	projectCommentsExtended: ExtendedResponseData<Array<Comment>>
}

export interface CommentApproveRequestPropsMapper {
	commentApprove: void
}

export interface CommentApproveRequestExtendedPropsMapper {
	commentApproveExtended: ExtendedResponseData<void>
}

export interface CommentSpamRequestPropsMapper {
	commentSpam: void
}

export interface CommentSpamRequestExtendedPropsMapper {
	commentSpamExtended: ExtendedResponseData<void>
}

export interface TagUpdateRequestPropsMapper {
	tagUpdate: Tag
}

export interface TagUpdateRequestExtendedPropsMapper {
	tagUpdateExtended: ExtendedResponseData<Tag>
}

export interface AccountRequestPropsMapper {
	account: Account
}

export interface AccountRequestExtendedPropsMapper {
	accountExtended: ExtendedResponseData<Account>
}

export interface ProjectsRequestPropsMapper {
	projects: Array<Project>
}

export interface ProjectsRequestExtendedPropsMapper {
	projectsExtended: ExtendedResponseData<Array<Project>>
}

export interface ProjectsInBoundsRequestPropsMapper {
	projectsInBounds: Array<Project>
}

export interface ProjectsInBoundsRequestExtendedPropsMapper {
	projectsInBoundsExtended: ExtendedResponseData<Array<Project>>
}

export interface ProjectRequestPropsMapper {
	project: Project
}

export interface ProjectRequestExtendedPropsMapper {
	projectExtended: ExtendedResponseData<Project>
}

export interface ShowProjectByAliasRequestPropsMapper {
	showProjectByAlias: Project
}

export interface ShowProjectByAliasRequestExtendedPropsMapper {
	showProjectByAliasExtended: ExtendedResponseData<Project>
}

export interface MediaRequestPropsMapper {
	media: Media
}

export interface MediaRequestExtendedPropsMapper {
	mediaExtended: ExtendedResponseData<Media>
}

export interface ShowMediaByAliasRequestPropsMapper {
	showMediaByAlias: Media
}

export interface ShowMediaByAliasRequestExtendedPropsMapper {
	showMediaByAliasExtended: ExtendedResponseData<Media>
}

export interface DiscussionRequestPropsMapper {
	discussion: Discussion
}

export interface DiscussionRequestExtendedPropsMapper {
	discussionExtended: ExtendedResponseData<Discussion>
}

export interface ShowDiscussionByAliasRequestPropsMapper {
	showDiscussionByAlias: Discussion
}

export interface ShowDiscussionByAliasRequestExtendedPropsMapper {
	showDiscussionByAliasExtended: ExtendedResponseData<Discussion>
}

export interface ProjectDiscussionsRequestPropsMapper {
	projectDiscussions: Array<Discussion>
}

export interface ProjectDiscussionsRequestExtendedPropsMapper {
	projectDiscussionsExtended: ExtendedResponseData<Array<Discussion>>
}

export interface PinRequestPropsMapper {
	pin: Pin
}

export interface PinRequestExtendedPropsMapper {
	pinExtended: ExtendedResponseData<Pin>
}

export interface RequestPasswordRequestPropsMapper {
	requestPassword: void
}

export interface RequestPasswordRequestExtendedPropsMapper {
	requestPasswordExtended: ExtendedResponseData<void>
}

export interface UpdatePasswordRequestPropsMapper {
	updatePassword: void
}

export interface UpdatePasswordRequestExtendedPropsMapper {
	updatePasswordExtended: ExtendedResponseData<void>
}

export interface PollAnswerRequestPropsMapper {
	pollAnswer: void
}

export interface PollAnswerRequestExtendedPropsMapper {
	pollAnswerExtended: ExtendedResponseData<void>
}

export interface VoteAnswerRequestPropsMapper {
	voteAnswer: void
}

export interface VoteAnswerRequestExtendedPropsMapper {
	voteAnswerExtended: ExtendedResponseData<void>
}

export interface ChecklistItemDoneRequestPropsMapper {
	checklistItemDone: void
}

export interface ChecklistItemDoneRequestExtendedPropsMapper {
	checklistItemDoneExtended: ExtendedResponseData<void>
}

