import * as React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import {AbstractSimpleComponent} from "lumen-react-javascript";

export default class PrivacyPolicy extends AbstractSimpleComponent {

    render() {
        return (
            <div id="privacy-policy" className="bg-gray-lighter p flex-1">
                <FormattedHTMLMessage id="privacyPolicyContent" defaultMessage="Privacy policy content"/>
            </div>
        );
    }

}