import * as React from 'react';
import {AbstractStatelessComponent} from "lumen-react-javascript";
import {Discussion, Vote} from "src/type-definitions/types";
import './votes-addon.less';
import {voteAnswerRequest} from "src/type-definitions/endpoints";

interface Props {
    discussion: Discussion;
}

export default class VotesAddon extends AbstractStatelessComponent<Props> {

    private renderVotes(vote: Vote) {
        return (
            <div key={vote.id} className="p">
                <div className="vote-addon flex-vertical rel-width pb">
                    <div className="question">
                        <h4>{vote.question}</h4>
                    </div>
                    <div className="vote-addon-yes flex-vertical">
                        <div className="cursor-pointer" onClick={() => {
                            voteAnswerRequest(vote.id, true).then(() => {
                                window.location.reload()
                            });
                        }}>
                            <span className="mr">
                                <input type="checkbox" checked={vote.used.used && vote.used.up}/>
                            </span>
                            <strong>{vote.upVoteLabel}</strong>
                        </div>
                        <div className="bar bar-yes flex-horizontal">
                            <div style={{flex: vote.reputation.up}}/>
                            <div style={{flex: vote.reputation.down}}/>
                        </div>
                    </div>
                    <div className="vote-addon-no flex-vertical">
                        <div className="cursor-pointer" onClick={() => {
                            voteAnswerRequest(vote.id, false).then(() => {
                                window.location.reload()
                            });
                        }}>
                            <span className="mr">
                                <input type="checkbox" checked={vote.used.used && !vote.used.up}/>
                            </span>
                            <strong><strong>{vote.downVoteLabel}</strong></strong>
                        </div>
                        <div className="bar bar-no flex-horizontal">
                            <div style={{flex: vote.reputation.down}}/>
                            <div style={{flex: vote.reputation.up}}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        if (this.props.discussion.votes.length) {
            return this.props.discussion.votes.map(vote => this.renderVotes(vote));
        } else {
            return null;
        }
    }

}
