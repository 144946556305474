import * as React from 'react';
import {OverlayView} from "react-google-maps";
import {AbstractComponent} from "lumen-react-javascript";

interface Props {
    latitude: number;
    longitude: number;
}

interface State {
}

export default class MapView extends AbstractComponent<Props, State> {

    render() {
        return (
            <OverlayView
                position={{lat: this.props.latitude, lng: this.props.longitude}}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
                {this.props.children}
            </OverlayView>
        );
    }

}