import * as React from 'react';
import {Media} from 'src/type-definitions/types';
import {FormattedDate} from 'react-intl';
import {AbstractLabel, LabelProps} from "lumen-react-javascript";

interface Props extends LabelProps {
    _media: Media;
}

export default class MediaLabel extends AbstractLabel<Props, any> {

    protected getContent(): string | JSX.Element {
        return null
    }

    protected getGlyph(): string {
        return "picture";
    }

    protected getName(): string {
        return this.props._media.name;
    }

    protected getSub(): string | JSX.Element {
        return <FormattedDate value={this.props._media.created.toISOString()}/>;
    }

    protected getThumbnail(): string {
        return this.props._media.avatars.thumbs[AbstractLabel.sizeKeyMap[this.props.labelType]];
    }

    protected hasThumbnail(): boolean {
        return !!this.props._media.avatars;
    }

}