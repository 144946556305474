import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-widgets/dist/css/react-widgets.css';
import languages from './languages';
import reducers from './reducers';
import {run} from 'lumen-react-javascript';
import Cookie from 'js-cookie';
import axios from "axios";
import * as config from "config";
import Raven from "raven-js/dist/raven.min";
import ErrorBoundary from "./components/error-boundary/error-boundary";

require("custom-event-polyfill");

function localeCallback() {
    let cookieLocale = Cookie.get('locale');
    if (cookieLocale) {
        return cookieLocale;
    } else {
        let instance = axios.create({baseURL: config.backendUrl});
        return instance.get('/geo').then(l => {
            if (l && l.data && l.data.countryIso) {
                let lang = l.data.countryIso.toLowerCase();
                Cookie.set('locale', lang);
                return lang;
            } else {
                return navigator.language.split('-')[0].toLowerCase() || null;
            }
        });
    }
}

Raven.config('https://d94c65bab4694dc78fa378a36d8559d8@sentry.io/1278079', {
    environment: config.environment,
}).install();
// window.addEventListener('unhandledrejection', (event) => {
//     console.log(event);
//     if (error.reason) {
//         if (error.reason instanceof Error) {
//             Raven.captureException(error.reason);
//         } else {
//             Raven.captureMessage('unhandledrejection.reason has type: ' + typeof error.reason);
//         }
//     } else {
//         Raven.captureMessage('unhandledrejection.reason was undefined');
//     }
// });


export function boot(Entry, bootOptions) {
    run(Entry, {
        App: bootOptions.App,
        ErrorBoundary,
        languages,
        localeCallback: localeCallback(),
        appName: bootOptions.appName || 'default',
        className: bootOptions.className,
        module,
        reducers,
        postBoot: bootOptions.postBoot,
        globalLoader: bootOptions.globalLoader || '...',
    });
}