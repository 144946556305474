export function fitMapToBounds(map, latLngs: Array<{ latitude: number, longitude: number }>) {
    let latMin = Number.POSITIVE_INFINITY;
    let lngMin = Number.POSITIVE_INFINITY;
    let latMax = Number.NEGATIVE_INFINITY;
    let lngMax = Number.NEGATIVE_INFINITY;

    latLngs.forEach(p => {
        let lat = p.latitude;
        let lng = p.longitude;
        if (lat < latMin) {
            latMin = lat;
        }
        if (lat > latMax) {
            latMax = lat;
        }
        if (lng < lngMin) {
            lngMin = lng;
        }
        if (lng > lngMax) {
            lngMax = lng;
        }
    });

    if (latMin == Number.POSITIVE_INFINITY) {
        return;
    }

    map.fitBounds(
        {
            east: lngMax,
            north: latMin,
            west: lngMin,
            south: latMax,
        }
    );
}

export function calculateDistance(p1: { lat: number, lng: number }, p2: { lat: number, lng: number }) {
    let R = 6378137; // Earth’s mean radius in meter
    let dLat = rad(p2.lat - p1.lat);
    let dLong = rad(p2.lng - p1.lng);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // returns the distance in meter
}

function rad(x) {
    return x * Math.PI / 180;
}