import * as React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import {AbstractSimpleComponent} from "lumen-react-javascript";

export default class Info extends AbstractSimpleComponent {

    render() {
        return (
            <div id="info" className="bg-gray-lighter p flex-1">
                <FormattedHTMLMessage id="infoContent" defaultMessage="Info content"/>
            </div>
        );
    }

}