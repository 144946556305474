import * as React from 'react';
import {compose} from "recompose";
import {geolocated} from "react-geolocated";
import {Alert} from "react-bootstrap";
import {defineMessages} from 'react-intl';
import {AbstractComponent, locationLoader, LocationLoaderInjectedProps} from "lumen-react-javascript";

const messages = defineMessages({
    loadingLocation: {
        id: 'loadingLocation',
        defaultMessage: 'Trying to locate your position.',
    },
    errorLocation: {
        id: 'errorLocation',
        defaultMessage: 'Could not get location.',
    },
    successLocation: {
        id: 'successLocation',
        defaultMessage: 'Successfully retrieved location.',
    },
});

interface Props extends LocationLoaderInjectedProps {
}

interface State {
    show: boolean;
}

const LOCATION_ALERT = {
    loading: {
        style: "warning",
        message: 'loadingLocation',
    },
    error: {
        style: "danger",
        message: 'errorLocation',
    },
    success: {
        style: "success",
        message: 'successLocation',
    },
};

export class LocationAlert extends AbstractComponent<Props, State> {

    constructor(props, context) {
        super(props, context);
        this.state = {show: true};
    }

    render() {
        this.props.handleLocationCallbacks({
            onLocationReady: this.onLocationReady.bind(this),
        });

        const alertType = !this.props.locationReady ? 'loading' : (this.state.show ? (this.props.hasLocation ? 'success' : 'error') : null)

        if (!alertType) {
            return null;
        }

        return (
            <Alert id="location-alert" bsStyle={LOCATION_ALERT[alertType].style}>
                <p>
                    {this.intl.formatMessage(messages[LOCATION_ALERT[alertType].message])}
                </p>
            </Alert>
        );
    }

    onLocationReady(enabled: boolean, coords: Coordinates) {
        setTimeout(() => this.setState({show: false}), 2000)
    }

}

export default compose(
    geolocated({
        watchPosition: false,
    }),
    locationLoader(),
)(LocationAlert);