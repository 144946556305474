import * as React from 'react';
import {
    DiscussionStateProp,
    discussionStatePropsMapper,
    MediaStateProp,
    mediaStatePropsMapper,
    ProjectStateProp,
    projectStatePropsMapper
} from "src/type-definitions/reducers";
import {RouteComponentProps} from "react-router";
import {DiscussionActionType, DiscussionLoader} from "src/reducers/open-discussion-reducer";
import {DiscussionActiveActionType} from "src/reducers/active-discussion-reducer";
import MediaLabel from 'src/components/media/media-label';
import DiscussionLabel from 'src/components/discussion/discussion-label';
import {FormattedMessage} from 'react-intl';
import {AbstractComponent, connect, Empty, LabelType} from "lumen-react-javascript";
import {Badge, Glyphicon, OverlayTrigger, Popover} from "react-bootstrap";
import DisqusAddon from "public/components/discussion/disqus-addon";
import classNames from "classnames";
import './discussion.less';
import VotesAddon from "public/components/discussion/votes-addon";
import PollsAddon from "public/components/discussion/polls-addon";
import ChecklistAddon from "public/components/discussion/checklists-addon";

interface Props extends MediaStateProp, DiscussionStateProp, ProjectStateProp, RouteComponentProps<{ projectId: number, mediaId: number }> {
    openDiscussionState: DiscussionLoader;
    openDiscussionDispatcher: (payload: DiscussionLoader) => void;
    activeDiscussionDispatcher: (action: any) => void;
}

enum Addon {
    Discussion,
    Polls,
    Votes,
    Checklists,
}

interface State {
    mode: Addon;
}

export class Discussion extends AbstractComponent<Props, State> {

    constructor(props, context) {
        super(props, context);
        this.state = {mode: Addon.Discussion};
    }

    componentDidUpdate(prevProps) {
        if (this.props.openDiscussionState.identifier != prevProps.openDiscussionState.identifier) {
            this.setState({mode: Addon.Discussion});
        }
    }

    private renderProjectDiscussion(disqus: DiscussionLoader) {
        if (this.props.projectState && this.props.projectState.hasDiscussion) {
            let active = !(!disqus || disqus.identifier != `project-discussion-${this.props.projectState.id}`);
            return (
                <tr className={active ? 'bg-gray-light text-white' : 'cursor-pointer'}>
                    <td onClick={
                        !active ?
                            () => {
                                this.props.activeDiscussionDispatcher({
                                    type: DiscussionActiveActionType.DISCUSSION_ACTIVE_OUT,
                                });
                                this.props.openDiscussionDispatcher({
                                    url: `${window.location.origin}/project/${this.props.projectState.id}`,
                                    identifier: `project-discussion-${this.props.projectState.id}`,
                                    title: `General project discussion for ${this.props.projectState.name}`,
                                    type: 'project',
                                })
                            } : null
                    }>
                        <div className="description description-medium animate-fade-in-from-top">
                            <FormattedMessage id="generalDiscussion" defaultMessage="General discussion"/>
                        </div>
                    </td>
                </tr>
            );
        }
        return null;
    }

    private renderMediaDiscussion(disqus: DiscussionLoader) {
        if (!this.props.discussionState && this.props.mediaState && this.props.mediaState.hasDiscussion) {
            let active = !(!disqus || disqus.identifier != `media-discussion-${this.props.mediaState.id}`);
            return (
                <tr className={active ? 'bg-gray-light text-white' : 'cursor-pointer'}>
                    <td onClick={
                        !active ?
                            () => {
                                this.props.activeDiscussionDispatcher({
                                    type: DiscussionActiveActionType.DISCUSSION_ACTIVE_OUT,
                                });
                                this.props.openDiscussionDispatcher({
                                    url: `${window.location.origin}/project/${this.props.projectState.id}/media/${this.props.mediaState.id}`,
                                    identifier: `media-discussion-${this.props.mediaState.id}`,
                                    title: `General discussion for ${this.props.mediaState.name}`,
                                    type: 'media',
                                })
                            } : null
                    }>
                        <MediaLabel
                            _media={this.props.mediaState}
                            labelType={LabelType.MEDIUM}
                            renderThumbnail={false}
                            className="animate-fade-in-from-top"
                        />
                    </td>
                </tr>
            );
        }
        return null;
    }

    private renderDiscussion(disqus: DiscussionLoader) {
        if (this.props.discussionState) {
            let active = !(!disqus || disqus.identifier != `discussion-${this.props.discussionState.id}`);
            return (
                <tr id="discussion-row" className={active ? 'bg-gray-light text-white' : 'cursor-pointer'}>
                    <td onClick={
                        !active ?
                            () => {
                                this.props.activeDiscussionDispatcher({
                                    type: DiscussionActiveActionType.DISCUSSION_ACTIVE_IN,
                                    payload: this.props.discussionState
                                });
                                this.props.openDiscussionDispatcher({
                                    url: `${window.location.origin}/project/${this.props.projectState.id}/discussion/${this.props.discussionState.id}`,
                                    identifier: `discussion-${this.props.discussionState.id}`,
                                    title: this.props.discussionState.name,
                                    type: 'discussion',
                                })
                            } : null
                    }>
                        <div className="flex-horizontal justify-between">
                            <div className={classNames({
                                "flex-horizontal flex-1 cursor-pointer": true,
                                "bg-gray": active && this.state.mode != Addon.Discussion
                            })} onClick={() => this.setState({mode: Addon.Discussion})}>
                                <DiscussionLabel
                                    discussion={this.props.discussionState}
                                    labelType={LabelType.MEDIUM}
                                    renderThumbnail={false}
                                    className="animate-fade-in-from-top"
                                />
                                <div className="ph">
                                    {(this.props.discussionState.tags.length > 0) && (
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="left"
                                            overlay={(
                                                <Popover>
                                                    {this.props.discussionState.tags.map(tag => (
                                                        <div key={tag.id} className="ml display-inline-block">
                                                            {tag.color ? (
                                                                <Badge
                                                                    style={{backgroundColor: `#${tag.color}`}}>{tag.name}</Badge>
                                                            ) : (
                                                                <Badge>{tag.name}</Badge>
                                                            )}
                                                        </div>
                                                    ))}
                                                </Popover>
                                            )}
                                        >
                                            <Badge bsClass="badge badge-inverse">
                                                <FormattedMessage id="numTags" defaultMessage="{number} tag(s)"
                                                                  values={{number: this.props.discussionState.tags.length}}/>
                                            </Badge>
                                        </OverlayTrigger>
                                    )}
                                </div>
                            </div>
                            {!!this.props.discussionState && (
                                <div className="flex-horizontal flex-1">
                                    {!!this.props.discussionState.votes.length && (
                                        <div className={classNames({
                                            "flex-1 text-center ph cursor-pointer flex-vertical justify-center": true,
                                            "bg-gray": active && this.state.mode != Addon.Votes
                                        })} onClick={() => this.setState({mode: Addon.Votes})}>
                                            <Glyphicon glyph="thumbs-up"/>
                                        </div>
                                    )}
                                    {!!this.props.discussionState.polls.length && (
                                        <div className={classNames({
                                            "flex-1 text-center ph cursor-pointer flex-vertical justify-center": true,
                                            "bg-gray": active && this.state.mode != Addon.Polls
                                        })} onClick={() => this.setState({mode: Addon.Polls})}>
                                            <Glyphicon glyph="list"/>
                                        </div>
                                    )}
                                    {!!this.props.discussionState.checklists.length && (
                                        <div className={classNames({
                                            "flex-1 text-center ph cursor-pointer flex-vertical justify-center": true,
                                            "bg-gray": active && this.state.mode != Addon.Checklists
                                        })} onClick={() => this.setState({mode: Addon.Checklists})}>
                                            <Glyphicon glyph="ok"/>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </td>
                </tr>
            );
        }
    }

    render() {
        let disqus: DiscussionLoader = (this.props.openDiscussionState.identifier && this.props.openDiscussionState.title) ? this.props.openDiscussionState : null;
        return (
            <Empty>
                <div>
                    <table style={{marginBottom: 0}} className="table table-bordered bg-gray-lighter">
                        <tbody>
                        {this.renderProjectDiscussion(disqus)}
                        {this.renderMediaDiscussion(disqus)}
                        {this.renderDiscussion(disqus)}
                        </tbody>
                    </table>
                </div>
                {this.state.mode === Addon.Discussion &&
                <DisqusAddon projectId={this.props.projectState.id} discussionLoader={disqus}/>}
                {this.state.mode === Addon.Votes && <VotesAddon discussion={this.props.discussionState}/>}
                {this.state.mode === Addon.Polls && <PollsAddon discussion={this.props.discussionState}/>}
                {this.state.mode === Addon.Checklists && <ChecklistAddon discussion={this.props.discussionState}/>}
            </Empty>
        );
    }

}

export default connect(Discussion, state => ({
        ...projectStatePropsMapper(state),
        ...discussionStatePropsMapper(state),
        ...mediaStatePropsMapper(state),
        openDiscussionState: state.openDiscussionState,
    }), dispatch => ({
        openDiscussionDispatcher: (payload: DiscussionLoader) => dispatch({
            type: DiscussionActionType.LOAD_DISCUSSION,
            payload
        }),
        activeDiscussionDispatcher: action => dispatch(action),
    })
);