import * as React from 'react';
import {ProjectStateProp, projectStatePropsMapper} from "src/type-definitions/reducers";
import {RouteComponentProps} from "react-router";
import {DiscussionActionType, DiscussionLoader} from "src/reducers/open-discussion-reducer";
import {DiscussionActiveActionType} from "src/reducers/active-discussion-reducer";
import ProjectLabel from 'src/components/project/project-label';
import {AbstractStatelessComponent, connector, LabelType} from "lumen-react-javascript";

interface Props extends ProjectStateProp, RouteComponentProps<{ projectId: number }> {
    openDiscussionDispatcher: (payload: DiscussionLoader) => void;
    activeDiscussionDispatcher: (action: any) => void;
}

export class Project extends AbstractStatelessComponent<Props> {

    render() {
        if (this.props.match.isExact) {
            setTimeout(() => {
                if (this.props.projectState.hasDiscussion) {
                    this.props.activeDiscussionDispatcher({
                        type: DiscussionActiveActionType.DISCUSSION_ACTIVE_OUT,
                    });
                    this.props.openDiscussionDispatcher({
                        url: `${window.location.origin}/project/${this.props.projectState.id}`,
                        identifier: `project-discussion-${this.props.projectState.id}`,
                        title: `General project discussion for ${this.props.projectState.name}`,
                        type: 'project',
                    });
                }
                if (this.props.projectState.media[0]) {
                    this.history.push(`/project/${this.props.projectState.id}/media/${this.props.projectState.media[0].id}`);
                }
            });
        }

        return (
            <ProjectLabel
                className="p bg-gray-lighter animate-fade-in-from-top"
                project={this.props.projectState}
                labelType={LabelType.SEMIFULL}
            />
        );
    }

}

export default connector(
    Project,
    {
        mapStateToProps: projectStatePropsMapper,
        mapDispatchToProps: dispatch => ({
            openDiscussionDispatcher: (payload: DiscussionLoader) => dispatch({
                type: DiscussionActionType.LOAD_DISCUSSION,
                payload,
            }),
            activeDiscussionDispatcher: action => dispatch(action),
        })
    },
);