import * as React from 'react';
import {Discussion} from 'src/type-definitions/types';
import {FormattedDate} from 'react-intl';
import {AbstractLabel, LabelProps} from "lumen-react-javascript";

interface Props extends LabelProps {
    discussion: Discussion;
}

export default class DiscussionLabel extends AbstractLabel<Props, any> {

    protected getContent(): string | JSX.Element {
        return this.props.discussion.name;
    }

    protected getGlyph(): string {
        return "comment";
    }

    protected getName(): string {
        return this.props.discussion.name;
    }

    protected getSub(): string | JSX.Element {
        return <FormattedDate value={this.props.discussion.created.toISOString()}/>;
    }

    protected getThumbnail(): string {
        return this.props.discussion.avatars.thumbs[AbstractLabel.sizeKeyMap[this.props.labelType]];
    }

    protected hasThumbnail(): boolean {
        return !!this.props.discussion.avatars;
    }

}