import * as React from 'react';
import DisqusLibrary from 'disqus-react';

export default class Disqus extends DisqusLibrary.DiscussionEmbed {

    getDisqusConfig(config) {
        return function () {
            this.page.identifier = config.identifier;
            this.page.url = config.url;
            this.page.title = config.title;
            this.page.category_id = config.category_id;
            this.language = config.language;
            this.callbacks.onNewComment = [
                config.onNewComment,
            ];
        };
    }

}