import * as React from 'react';
import {Media, Pin, MediaIframe as MediaIframeObject} from "src/type-definitions/types";
import {AbstractComponent} from "lumen-react-javascript";

interface Props {
    pins: Array<Pin>;
    onPinClick: (pin: Pin) => void;
    media: Media;
}

export default class MediaIframe extends AbstractComponent<Props, {}> {

    render() {
        return (
            <div className="flex-1 position-relative">
                <iframe className="position-absolute" width={'100%'} height={'100%'} src={(this.props.media.data as MediaIframeObject).url}/>
            </div>
        );
    }

}