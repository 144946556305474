import * as React from 'react';
import {
    accountDispatchBinder,
    AccountDispatcher,
    projectDispatchBinder,
    ProjectDispatcher
} from "src/type-definitions/actions";
import {RouteComponentProps} from "react-router";
import {AbstractStatelessPureComponent, connector, Empty, resolveLoader} from "lumen-react-javascript";
import * as config from "config";
import {
    AccountStateProp,
    accountStatePropsMapper,
    ProjectStateProp,
    projectStatePropsMapper
} from "src/type-definitions/reducers";
import {AppState} from "src/reducers/index";
import {Account} from "src/type-definitions/types";
import {defineMessages} from 'react-intl';

const messages = defineMessages({
    payoff: {
        id: 'payoff',
        defaultMessage: 'urban collaborative planning',
    }
});

interface Props extends ProjectStateProp, ProjectDispatcher, AccountStateProp, AccountDispatcher, RouteComponentProps<{ accountId?: number, projectId?: number }> {
}

function getAccountIdFromConfig() {
    let hostmap = config.hostmap;
    if (hostmap[window.location.host]) {
        return hostmap[window.location.host];
    }
    return null;
}

export class ApplicationContextLoader extends AbstractStatelessPureComponent<Props> {

    render() {
        let {projectState, accountState} = this.props;
        let {projectId, accountId} = this.props.match.params;
        let loadedAccount: Account = null;
        let loaded: boolean = false;

        if (projectState && projectId && projectId == projectState.id) {
            loaded = true;
            loadedAccount = projectState.account;
        } else if ((accountId && accountState && accountId == accountState.id) ||
            (getAccountIdFromConfig() && accountState && getAccountIdFromConfig() == accountState.id)) {
            loaded = true;
            loadedAccount = accountState;
        } else if (!projectId && !accountId) {
            loaded = true;
        }

        if (loaded) {
            if (loadedAccount && loadedAccount.whitelabel) {
                let {color, tagline, logo, name, avatars} = loadedAccount;

                if (tagline) {
                    document.getElementById('payoff-container').innerText = tagline;
                    document.title = `${name} - ${tagline}`;
                }
                if (logo) {
                    document.getElementById('site-logo').setAttribute("src", logo);
                    document.getElementById('site-logo').setAttribute("title", name);
                }

                let favicon = (avatars && avatars.thumbs) ? avatars.thumbs['100x100'] : (logo ? logo : null);
                if (favicon) {
                    let link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
                    link.type = 'image/x-icon';
                    link.rel = 'shortcut icon';
                    link.href = favicon;
                    document.getElementsByTagName('head')[0].appendChild(link);
                }

                return (
                    <Empty>
                        <style dangerouslySetInnerHTML={{
                            __html: `
.text-primary {
    color: #${color} !important;
}

.bg-primary {
    background-color: #${color} !important;
}

.btn-primary {
    background-color: #${color} !important;
    border-color: #${color} !important;
}

#medias .media-button.active {
    background-color: #${color} !important;
}

.media-img-container .media-img-img .media-img-pin {
    background-color: #${color} !important;
}

a {
    color: #${color} !important;
}

.badge {
    background-color: #${color};
}
`
                        }}/>
                        {this.props.children}
                    </Empty>
                );
            } else {
                document.getElementById('payoff-container').innerText = this.intl.formatMessage(messages.payoff);
                document.title = `Collaplan - ${this.intl.formatMessage(messages.payoff)}`;

                document.getElementById('site-logo').setAttribute("src", require("src/img/logo.png"));
                document.getElementById('site-logo').setAttribute("title", "Collaplan");

                let link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = '/favicon.ico';
                document.getElementsByTagName('head')[0].appendChild(link);
                return this.props.children;
            }
        } else {
            return resolveLoader();
        }
    }

}

export default connector(
    ApplicationContextLoader,
    {
        mapStateToProps: (state: AppState) => ({
            ...projectStatePropsMapper(state),
            ...accountStatePropsMapper(state),
        }),
        mapDispatchToProps: dispatch => ({
            ...projectDispatchBinder(dispatch),
            ...accountDispatchBinder(dispatch),
        }),
        connectLoaderOptions: {
            dispatchers: {
                project: (props, advance: () => void) => {
                    if (props.match.params.projectId) {
                        props.projectDispatcher(props.match.params.projectId, {
                            requestConfig: {with: 'account;media'},
                            then: () => advance(),
                        });
                    } else {
                        advance();
                    }
                },
                account: (props, advance: () => void) => {
                    let accountId = null;
                    if (props.match.params.accountId) {
                        accountId = props.match.params.accountId;
                    } else {
                        accountId = getAccountIdFromConfig();
                    }
                    if (accountId) {
                        props.accountDispatcher(accountId, {
                            requestConfig: {with: 'projects'},
                            then: () => advance(),
                        });
                    } else {
                        advance();
                    }
                },
            }
        },
    },
);