import * as React from 'react';
import {
    MediaStateProp,
    mediaStatePropsMapper,
    ProjectStateProp,
    projectStatePropsMapper
} from "src/type-definitions/reducers";
import {
    discussionDispatchBinder,
    DiscussionDispatcher,
    mediaDispatchBinder,
    MediaDispatcher
} from "src/type-definitions/actions";
import {RouteComponentProps} from "react-router";
import MediaRenderer from "src/app/public/components/media/media-renderer";
import {DiscussionActionType, DiscussionLoader} from "src/reducers/open-discussion-reducer";
import {DiscussionActiveActionType} from "src/reducers/active-discussion-reducer";
import {AbstractStatelessComponent, connect, resolveLoader} from "lumen-react-javascript";

interface Props extends ProjectStateProp, MediaStateProp, MediaDispatcher, DiscussionDispatcher, RouteComponentProps<{ projectId: number, mediaId: number, pinId: number }> {
    openDiscussionDispatcher: (payload: DiscussionLoader) => void;
    activeDiscussionDispatcher: (action: any) => void;
}

export class Media extends AbstractStatelessComponent<Props> {

    componentDidMount() {
        this.props.mediaDispatcher(this.props.match.params.mediaId, {
            then: media => {
                if (media.hasDiscussion) {
                    this.props.activeDiscussionDispatcher({
                        type: DiscussionActiveActionType.DISCUSSION_ACTIVE_OUT,
                    });
                    this.props.openDiscussionDispatcher({
                        url: `${window.location.origin}/project/${media.project.id}/media/${media.id}`,
                        identifier: `media-discussion-${media.id}`,
                        title: media.name,
                        type: 'media',
                    });
                } else if (media.project.hasDiscussion) {
                    this.props.activeDiscussionDispatcher({
                        type: DiscussionActiveActionType.DISCUSSION_ACTIVE_OUT,
                    });
                    this.props.openDiscussionDispatcher({
                        url: `${window.location.origin}/project/${media.project.id}`,
                        identifier: `project-discussion-${media.project.id}`,
                        title: media.project.name,
                        type: 'project',
                    });
                }

                if (this.props.match.params.pinId) {
                    let discussionId = media.pins.find(p => p.id == this.props.match.params.pinId).discussionId;
                    this.props.discussionDispatcher(discussionId, {
                        then: discussion => {
                            this.props.activeDiscussionDispatcher({
                                type: DiscussionActiveActionType.DISCUSSION_ACTIVE_IN,
                                payload: discussion
                            });
                            this.props.openDiscussionDispatcher({
                                url: `${window.location.origin}/project/${media.project.id}/media/${media.id}/discussion/${discussion.id}`,
                                identifier: `discussion-${discussion.id}`,
                                title: discussion.name,
                                type: 'discussion',
                            });
                        }
                    });
                }
                return media;
            },
            requestConfig: {
                with: 'project;pins.discussion',
            }
        });
    }

    render() {
        if (!this.props.mediaState) {
            return resolveLoader();
        }

        return <MediaRenderer mediaState={this.props.mediaState} onPinClick={(pin) => {
            document.getElementById('content-pop').scrollIntoView({behavior: 'smooth'});
            this.props.discussionDispatcher(pin.discussionId, {
                then: discussion => {
                    this.props.activeDiscussionDispatcher({
                        type: DiscussionActiveActionType.DISCUSSION_ACTIVE_IN,
                        payload: discussion
                    });
                    this.props.openDiscussionDispatcher({
                        url: `${window.location.origin}/project/${this.props.projectState.id}/media/${this.props.mediaState.id}/discussion/${discussion.id}`,
                        identifier: `discussion-${discussion.id}`,
                        title: discussion.name,
                        type: 'discussion',
                    });
                }
            });
            this.history.push(`/project/${this.props.match.params.projectId}/media/${this.props.match.params.mediaId}/pin/${pin.id}`);
        }}/>;
    }

}

export default connect(Media,
    state => ({
        ...mediaStatePropsMapper(state),
        ...projectStatePropsMapper(state),
    }),
    dispatch => ({
        ...mediaDispatchBinder(dispatch),
        ...discussionDispatchBinder(dispatch),
        openDiscussionDispatcher: (payload: DiscussionLoader) => dispatch({
            type: DiscussionActionType.LOAD_DISCUSSION,
            payload
        }),
        activeDiscussionDispatcher: action => dispatch(action),
    })
);