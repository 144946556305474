import {ActionCreator, Dispatch} from "redux";
import {BaseAction, callDispatcher, GenericCallbackOptions} from 'lumen-react-javascript';
import { Account, Project, Media, Discussion } from './types';
import { accountRequest, projectsRequest, projectsInBoundsRequest, projectRequest, showProjectByAliasRequest, mediaRequest, showMediaByAliasRequest, discussionRequest, showDiscussionByAliasRequest } from './endpoints';

export enum ActionType {
	ACCOUNT = 'ACCOUNT',
	PROJECTS = 'PROJECTS',
	PROJECTS_IN_BOUNDS = 'PROJECTS_IN_BOUNDS',
	PROJECT = 'PROJECT',
	SHOW_PROJECT_BY_ALIAS = 'SHOW_PROJECT_BY_ALIAS',
	MEDIA = 'MEDIA',
	SHOW_MEDIA_BY_ALIAS = 'SHOW_MEDIA_BY_ALIAS',
	DISCUSSION = 'DISCUSSION',
	SHOW_DISCUSSION_BY_ALIAS = 'SHOW_DISCUSSION_BY_ALIAS',
}

export type AccountActionCallbackOptions = GenericCallbackOptions<ActionType, Account>;
export type ProjectsActionCallbackOptions = GenericCallbackOptions<ActionType, Array<Project>>;
export type ProjectsInBoundsActionCallbackOptions = GenericCallbackOptions<ActionType, Array<Project>>;
export type ProjectActionCallbackOptions = GenericCallbackOptions<ActionType, Project>;
export type ShowProjectByAliasActionCallbackOptions = GenericCallbackOptions<ActionType, Project>;
export type MediaActionCallbackOptions = GenericCallbackOptions<ActionType, Media>;
export type ShowMediaByAliasActionCallbackOptions = GenericCallbackOptions<ActionType, Media>;
export type DiscussionActionCallbackOptions = GenericCallbackOptions<ActionType, Discussion>;
export type ShowDiscussionByAliasActionCallbackOptions = GenericCallbackOptions<ActionType, Discussion>;

export type AccountActionCallback = (accountId: number, options?: AccountActionCallbackOptions | Dispatch<AccountAction>) => AccountAction;
export type ProjectsActionCallback = (options?: ProjectsActionCallbackOptions | Dispatch<ProjectsAction>) => ProjectsAction;
export type ProjectsInBoundsActionCallback = (swLat: number, swLng: number, neLat: number, neLng: number, options?: ProjectsInBoundsActionCallbackOptions | Dispatch<ProjectsInBoundsAction>) => ProjectsInBoundsAction;
export type ProjectActionCallback = (projectId: number, options?: ProjectActionCallbackOptions | Dispatch<ProjectAction>) => ProjectAction;
export type ShowProjectByAliasActionCallback = (projectName: string, options?: ShowProjectByAliasActionCallbackOptions | Dispatch<ShowProjectByAliasAction>) => ShowProjectByAliasAction;
export type MediaActionCallback = (mediaId: number, options?: MediaActionCallbackOptions | Dispatch<MediaAction>) => MediaAction;
export type ShowMediaByAliasActionCallback = (mediaName: string, options?: ShowMediaByAliasActionCallbackOptions | Dispatch<ShowMediaByAliasAction>) => ShowMediaByAliasAction;
export type DiscussionActionCallback = (discussionId: number, options?: DiscussionActionCallbackOptions | Dispatch<DiscussionAction>) => DiscussionAction;
export type ShowDiscussionByAliasActionCallback = (discussionName: string, options?: ShowDiscussionByAliasActionCallbackOptions | Dispatch<ShowDiscussionByAliasAction>) => ShowDiscussionByAliasAction;

export interface AccountAction extends BaseAction<Account> {
    type: ActionType;
}

export interface ProjectsAction extends BaseAction<Array<Project>> {
    type: ActionType;
}

export interface ProjectsInBoundsAction extends BaseAction<Array<Project>> {
    type: ActionType;
}

export interface ProjectAction extends BaseAction<Project> {
    type: ActionType;
}

export interface ShowProjectByAliasAction extends BaseAction<Project> {
    type: ActionType;
}

export interface MediaAction extends BaseAction<Media> {
    type: ActionType;
}

export interface ShowMediaByAliasAction extends BaseAction<Media> {
    type: ActionType;
}

export interface DiscussionAction extends BaseAction<Discussion> {
    type: ActionType;
}

export interface ShowDiscussionByAliasAction extends BaseAction<Discussion> {
    type: ActionType;
}

export function accountDispatchBinder(dispatch: Dispatch<AccountAction>) {
	return {accountDispatcher: account(dispatch)};
}

export function projectsDispatchBinder(dispatch: Dispatch<ProjectsAction>) {
	return {projectsDispatcher: projects(dispatch)};
}

export function projectsInBoundsDispatchBinder(dispatch: Dispatch<ProjectsInBoundsAction>) {
	return {projectsInBoundsDispatcher: projectsInBounds(dispatch)};
}

export function projectDispatchBinder(dispatch: Dispatch<ProjectAction>) {
	return {projectDispatcher: project(dispatch)};
}

export function showProjectByAliasDispatchBinder(dispatch: Dispatch<ShowProjectByAliasAction>) {
	return {showProjectByAliasDispatcher: showProjectByAlias(dispatch)};
}

export function mediaDispatchBinder(dispatch: Dispatch<MediaAction>) {
	return {mediaDispatcher: media(dispatch)};
}

export function showMediaByAliasDispatchBinder(dispatch: Dispatch<ShowMediaByAliasAction>) {
	return {showMediaByAliasDispatcher: showMediaByAlias(dispatch)};
}

export function discussionDispatchBinder(dispatch: Dispatch<DiscussionAction>) {
	return {discussionDispatcher: discussion(dispatch)};
}

export function showDiscussionByAliasDispatchBinder(dispatch: Dispatch<ShowDiscussionByAliasAction>) {
	return {showDiscussionByAliasDispatcher: showDiscussionByAlias(dispatch)};
}

export interface AccountDispatcher {
    accountDispatcher: AccountActionCallback;
}

export interface ProjectsDispatcher {
    projectsDispatcher: ProjectsActionCallback;
}

export interface ProjectsInBoundsDispatcher {
    projectsInBoundsDispatcher: ProjectsInBoundsActionCallback;
}

export interface ProjectDispatcher {
    projectDispatcher: ProjectActionCallback;
}

export interface ShowProjectByAliasDispatcher {
    showProjectByAliasDispatcher: ShowProjectByAliasActionCallback;
}

export interface MediaDispatcher {
    mediaDispatcher: MediaActionCallback;
}

export interface ShowMediaByAliasDispatcher {
    showMediaByAliasDispatcher: ShowMediaByAliasActionCallback;
}

export interface DiscussionDispatcher {
    discussionDispatcher: DiscussionActionCallback;
}

export interface ShowDiscussionByAliasDispatcher {
    showDiscussionByAliasDispatcher: ShowDiscussionByAliasActionCallback;
}

export const account: ActionCreator<AccountActionCallback> =
    (dispatch: Dispatch<Promise<Account>>): AccountActionCallback =>
        (accountId: number, options?: AccountActionCallbackOptions) =>
            callDispatcher<Account, AccountActionCallbackOptions, ActionType>(
            	dispatch, 
            	ActionType.ACCOUNT, 
            	(options && options.requestConfig) ? 
					accountRequest(accountId, options.requestConfig) : 
					accountRequest(accountId), 
            	options);

export const projects: ActionCreator<ProjectsActionCallback> =
    (dispatch: Dispatch<Promise<Array<Project>>>): ProjectsActionCallback =>
        (options?: ProjectsActionCallbackOptions) =>
            callDispatcher<Array<Project>, ProjectsActionCallbackOptions, ActionType>(
            	dispatch, 
            	ActionType.PROJECTS, 
            	(options && options.requestConfig) ? 
					projectsRequest(options.requestConfig) : 
					projectsRequest(), 
            	options);

export const projectsInBounds: ActionCreator<ProjectsInBoundsActionCallback> =
    (dispatch: Dispatch<Promise<Array<Project>>>): ProjectsInBoundsActionCallback =>
        (swLat: number, swLng: number, neLat: number, neLng: number, options?: ProjectsInBoundsActionCallbackOptions) =>
            callDispatcher<Array<Project>, ProjectsInBoundsActionCallbackOptions, ActionType>(
            	dispatch, 
            	ActionType.PROJECTS_IN_BOUNDS, 
            	(options && options.requestConfig) ? 
					projectsInBoundsRequest(swLat, swLng, neLat, neLng, options.requestConfig) : 
					projectsInBoundsRequest(swLat, swLng, neLat, neLng), 
            	options);

export const project: ActionCreator<ProjectActionCallback> =
    (dispatch: Dispatch<Promise<Project>>): ProjectActionCallback =>
        (projectId: number, options?: ProjectActionCallbackOptions) =>
            callDispatcher<Project, ProjectActionCallbackOptions, ActionType>(
            	dispatch, 
            	ActionType.PROJECT, 
            	(options && options.requestConfig) ? 
					projectRequest(projectId, options.requestConfig) : 
					projectRequest(projectId), 
            	options);

export const showProjectByAlias: ActionCreator<ShowProjectByAliasActionCallback> =
    (dispatch: Dispatch<Promise<Project>>): ShowProjectByAliasActionCallback =>
        (projectName: string, options?: ShowProjectByAliasActionCallbackOptions) =>
            callDispatcher<Project, ShowProjectByAliasActionCallbackOptions, ActionType>(
            	dispatch, 
            	ActionType.SHOW_PROJECT_BY_ALIAS, 
            	(options && options.requestConfig) ? 
					showProjectByAliasRequest(projectName, options.requestConfig) : 
					showProjectByAliasRequest(projectName), 
            	options);

export const media: ActionCreator<MediaActionCallback> =
    (dispatch: Dispatch<Promise<Media>>): MediaActionCallback =>
        (mediaId: number, options?: MediaActionCallbackOptions) =>
            callDispatcher<Media, MediaActionCallbackOptions, ActionType>(
            	dispatch, 
            	ActionType.MEDIA, 
            	(options && options.requestConfig) ? 
					mediaRequest(mediaId, options.requestConfig) : 
					mediaRequest(mediaId), 
            	options);

export const showMediaByAlias: ActionCreator<ShowMediaByAliasActionCallback> =
    (dispatch: Dispatch<Promise<Media>>): ShowMediaByAliasActionCallback =>
        (mediaName: string, options?: ShowMediaByAliasActionCallbackOptions) =>
            callDispatcher<Media, ShowMediaByAliasActionCallbackOptions, ActionType>(
            	dispatch, 
            	ActionType.SHOW_MEDIA_BY_ALIAS, 
            	(options && options.requestConfig) ? 
					showMediaByAliasRequest(mediaName, options.requestConfig) : 
					showMediaByAliasRequest(mediaName), 
            	options);

export const discussion: ActionCreator<DiscussionActionCallback> =
    (dispatch: Dispatch<Promise<Discussion>>): DiscussionActionCallback =>
        (discussionId: number, options?: DiscussionActionCallbackOptions) =>
            callDispatcher<Discussion, DiscussionActionCallbackOptions, ActionType>(
            	dispatch, 
            	ActionType.DISCUSSION, 
            	(options && options.requestConfig) ? 
					discussionRequest(discussionId, options.requestConfig) : 
					discussionRequest(discussionId), 
            	options);

export const showDiscussionByAlias: ActionCreator<ShowDiscussionByAliasActionCallback> =
    (dispatch: Dispatch<Promise<Discussion>>): ShowDiscussionByAliasActionCallback =>
        (discussionName: string, options?: ShowDiscussionByAliasActionCallbackOptions) =>
            callDispatcher<Discussion, ShowDiscussionByAliasActionCallbackOptions, ActionType>(
            	dispatch, 
            	ActionType.SHOW_DISCUSSION_BY_ALIAS, 
            	(options && options.requestConfig) ? 
					showDiscussionByAliasRequest(discussionName, options.requestConfig) : 
					showDiscussionByAliasRequest(discussionName), 
            	options);