import * as React from 'react';
import {AbstractComponent} from 'lumen-react-javascript';
import classNames from 'classnames';
import {Discussion} from "src/type-definitions/types";
import {Glyphicon} from "react-bootstrap";
import {prefix} from 'inline-style-prefixer';

interface Props {
    discussion: Discussion;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    onClick?: () => void;
    active?: boolean;
    textOnHover?: boolean;
    style?: any;
    hideIcons?: boolean;
}

interface State {
    hover: boolean;
}

export default class DiscussionPin extends AbstractComponent<Props, State> {

    static defaultProps = {
        textOnHover: false,
        hideIcons: false,
        style: {},
    };

    constructor(props, context) {
        super(props, context);
        this.state = {hover: false};
    }

    render() {
        return (
            <div
                style={this.props.style}
                className={classNames({
                    'pin': true,
                    'bg-primary': true,
                    'active': this.props.active || this.state.hover,
                })}
                onMouseEnter={() => {
                    if (this.props.onMouseOver) {
                        this.props.onMouseOver();
                    }
                    this.setState({hover: true});
                }}
                onMouseLeave={() => {
                    if (this.props.onMouseOut) {
                        this.props.onMouseOut();
                    }
                    this.setState({hover: false});
                }}
                onClick={() => {
                    if (this.props.onClick) {
                        this.props.onClick();
                    }
                }}
            >
                {!this.props.hideIcons && this.renderIcons()}
                {this.renderSvg()}
                {(!this.props.textOnHover || this.state.hover || this.props.active) && (
                    <div className="pin-text">{this.props.discussion.name}</div>
                )}
            </div>
        );
    }

    private partialCircle(cx, cy, r, start, end) {
        const length = end - start;
        if (length === 0) return [];

        const fromX = r * Math.cos(start) + cx;
        const fromY = r * Math.sin(start) + cy;
        const toX = r * Math.cos(end) + cx;
        const toY = r * Math.sin(end) + cy;
        const large = Math.abs(length) <= Math.PI ? '0' : '1';
        const sweep = length < 0 ? '0' : '1';

        return [
            ['M', cx, cy],
            ['L', fromX, fromY],
            ['A', r, r, 0, large, sweep, toX, toY],
            ['L', cx, cy],
        ];
    }

    private renderSvg() {
        let svgPaths = [];

        let coloredTags = this.props.discussion.tags.filter(t => !!t.color);
        if (coloredTags.length === 1) {
            svgPaths.push(<circle key={-1} fill={`#${coloredTags[0].color}`} cx="5" cy="5" r="5"/>);
        } else if (coloredTags.length > 1) {
            for (let i = 0; i < coloredTags.length; i++) {
                let path = this.partialCircle(5, 5, 5.1,
                    (-Math.PI / 2) + ((i / coloredTags.length - 1) * (2 * Math.PI)),
                    (-Math.PI / 2) + (((i + 1) / coloredTags.length - 1) * (2 * Math.PI))
                ).map(command => command.join(' ')).join(' ');
                svgPaths.push(<path key={coloredTags[i].id} fill={`#${coloredTags[i].color}`} d={path}/>);
            }
        }

        if (svgPaths) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                    {svgPaths}
                </svg>
            );
        } else {
            return null;
        }
    }

    private renderIcons() {
        let icons = [];

        if (this.props.discussion.votes.length) {
            let total = 0;
            let up = 0;
            this.props.discussion.votes.forEach(vote => {
                total += vote.reputation.up + vote.reputation.down;
                up += vote.reputation.up;
            });
            let percentage = (total != 0) ? up / total : 0.5;
            if (percentage == 0) {
                icons.push(
                    <div key="votes" className="pin-icon text-gray-light flex-1">
                        <div className="pin-icon-content votes">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                                <circle fill="#d9534f" cx="5" cy="5" r="5.1"/>
                            </svg>
                            <Glyphicon glyph="thumbs-up"/>
                        </div>
                    </div>
                );
            } else if (percentage == 1) {
                icons.push(
                    <div key="votes" className="pin-icon text-gray-light flex-1">
                        <div className="pin-icon-content votes">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                                <circle fill="#5cb85c" cx="5" cy="5" r="5.1"/>
                            </svg>
                        </div>
                        <Glyphicon glyph="thumbs-up"/>
                    </div>
                );
            } else {
                let pathUp = this.partialCircle(5, 5, 5.1,
                    (-Math.PI / 2),
                    (-Math.PI / 2) + (percentage * (2 * Math.PI))
                ).map(command => command.join(' ')).join(' ');
                let pathTotal = this.partialCircle(5, 5, 5.1,
                    (-Math.PI / 2) + (percentage * (2 * Math.PI)),
                    (-Math.PI / 2) + (2 * Math.PI),
                ).map(command => command.join(' ')).join(' ');
                icons.push(
                    <div key="votes" className="pin-icon text-gray-light flex-1">
                        <div className="pin-icon-content votes">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                                <path fill="#d9534f" d={pathUp}/>
                                <path fill="#5cb85c" d={pathTotal}/>
                            </svg>
                            <Glyphicon glyph="thumbs-up"/>
                        </div>
                    </div>
                );
            }
        }

        if (this.props.discussion.polls.length) {
            icons.push(
                <div key="polls" className="pin-icon text-gray-light flex-1">
                    <div className="pin-icon-content">
                        <Glyphicon glyph="list"/>
                    </div>
                </div>
            );
        }

        if (this.props.discussion.checklists.length) {
            let total = 0;
            let done = 0;
            this.props.discussion.checklists.forEach(checklist => {
                total += checklist.items.length;
                done += checklist.items.filter(item => item.done).length;
            });
            let percentage = total > 0 ? (done / total) * 100 : 0;
            icons.push(
                <div key="checklists" className="pin-icon text-gray-light flex-1">
                    <div className="pin-icon-content checklists">
                        <div className="checklists-done" style={{height: `${percentage}%`}}/>
                        <Glyphicon glyph="ok"/>
                    </div>
                </div>
            );
        }

        if (icons.length) {
            return (
                <div className="pin-icons position-absolute flex-horizontal justify-center rel-width">
                    {icons}
                </div>
            )
        } else {
            return null;
        }
    }
}