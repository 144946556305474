import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {InjectedFormProps} from "redux-form";
import {updatePasswordRequest} from "src/type-definitions/endpoints";
import queryString from 'query-string';
import {PasswordUpdate} from "src/type-definitions/types";
import {AbstractComponent, FormFieldInput, FormSubmit, FormValidator, simpleForm} from "lumen-react-javascript";

interface Props extends InjectedFormProps<PasswordUpdate> {
}

interface State {
    submitted: boolean;
}

export class UpdatePassword extends AbstractComponent<Props, State> {

    private token: string;

    constructor(props, context) {
        super(props, context);
        this.state = {submitted: false};
        let qs = queryString.parse(window.location.search);
        if (qs.token) {
            this.token = qs.token;
        } else {
            this.history.push("/");
        }
    }

    private onSubmit(values) {
        values.token = this.token;
        updatePasswordRequest(values).then(() => {
            this.setState({submitted: true});
        });
    }

    render() {
        return (
            <div id="request-password" className="bg-gray-lighter p">
                {this.state.submitted ? (
                    <p>
                        <FormattedMessage id="passwordUpdatedConfirmation"
                                          defaultMessage="Your password has been updated"/>
                    </p>
                ) : (
                    <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
                        <FormattedMessage id="enterPassword"
                                          defaultMessage="Enter your new password"/>
                        <FormFieldInput type="password" name="password"/>

                        <FormattedMessage id="confirmPassword"
                                          defaultMessage="Confirm your new password"/>
                        <FormFieldInput type="password" name="newPassword"/>

                        <FormSubmit label={<FormattedMessage id="update"
                                                             defaultMessage="Update"/>} {...this.props}/>
                    </form>
                )}
            </div>
        );
    }

}

export default simpleForm(
    UpdatePassword,
    {
        validate: (values, props) =>
            FormValidator.make(values, props)
                .required('password')
                .required('newPassword')
                .equalsField('password', 'newPassword')
                .validate(),
        form: 'UpdatePassword',
    }
);