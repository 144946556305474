import {AbstractPureComponent} from "lumen-react-javascript";
import * as React from "react";
import {Glyphicon} from "react-bootstrap";
import classNames from "classnames";

interface State {
    down: boolean;
}

export default class MobileViewButton extends AbstractPureComponent<{}, State> {

    state = {
        down: true,
    };

    render() {
        return (
            <div
                ref={i => {
                    if (i) {
                        window.addEventListener('resize', (() => {
                            this.setScrollState();
                        }));

                        let dom = document.getElementById('content');
                        if (dom) {
                            dom.addEventListener('scroll', () => {
                                this.setScrollState();
                            });
                        } else {
                            console.warn("could not find content container");
                        }
                        this.setScrollState();
                    }
                }}
                id="mobile-view-button"
                className={classNames("compact-show", {"up": !this.state.down})}
                onClick={() => {
                    if (this.state.down) {
                        document.getElementById('content-pop').scrollIntoView({behavior: 'smooth'})
                    } else {
                        document.getElementById('content-push').scrollIntoView({behavior: 'smooth'})
                    }
                    this.setScrollState();
                }}
            >
                <Glyphicon glyph="circle-arrow-down"/>
            </div>
        );
    }

    private setScrollState() {
        let dom = document.getElementById('content');
        let clientHeight = dom.clientHeight || dom.offsetHeight;
        let scrollHeight = dom.scrollHeight - clientHeight;
        let down = dom.scrollTop < scrollHeight;
        setTimeout(() => this.setState({down}));
    }
}