import {AnyAction, Reducer} from "redux";
import {Discussion} from "../type-definitions/types";
import {GlobalActionType} from "lumen-react-javascript";

export enum DiscussionActiveActionType {
    DISCUSSION_ACTIVE_IN = 'DISCUSSION_ACTIVE_IN',
    DISCUSSION_ACTIVE_OUT = 'DISCUSSION_ACTIVE_OUT',
}

const activeDiscussionReducer: Reducer<Discussion> = (state: Discussion = null, action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case DiscussionActiveActionType.DISCUSSION_ACTIVE_IN:
            return {...action.payload};
        case DiscussionActiveActionType.DISCUSSION_ACTIVE_OUT:
            return null;
        case GlobalActionType.REFRESH_STORE:
            return {...state};
        default:
            return state;
    }
};

export default activeDiscussionReducer;