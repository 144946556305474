import * as React from 'react';
import {Media, MediaPdf as MediaPdfObject, Pin} from "src/type-definitions/types";
import {AbstractComponent} from "lumen-react-javascript";
import {FormattedHTMLMessage} from 'react-intl';
// import {Document, Page} from 'react-pdf';
// import './media-pdf.less';

interface Props {
    pins: Array<Pin>;
    onPinClick: (pin: Pin) => void;
    media: Media;
}

interface State {
    page: number;
    total: number;
}

export default class MediaPdf extends AbstractComponent<Props, State> {

    constructor(props, context) {
        super(props, context);

        this.state = {
            page: 1,
            total: 0,
        };
    }

    render() {
        return (
            <div className="flex-1 position-relative flex-vertical">
                <div className="p">
                    <FormattedHTMLMessage
                        id="cannotViewPdf"
                        defaultMessage="If you cannot view the pdf click <a target='_blank' href='{link}'>here</a>"
                        values={{link: (this.props.media.data as MediaPdfObject).file}}
                    />
                </div>
                <div className="flex-1 position-relative">
                    <iframe
                        className="position-absolute rel-width rel-height"
                        style={{border: 0}}
                        src={(this.props.media.data as MediaPdfObject).file}
                    />
                </div>
            </div>
        );
    }

}