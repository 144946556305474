import * as React from 'react';
import {Media} from "src/type-definitions/types";
import {projectDiscussionsRequestLoader, ProjectDiscussionsRequestPropsMapper} from "src/type-definitions/endpoints";
import {Button, Modal} from "react-bootstrap";
import {ModalProps} from "react-bootstrap/lib/Modal";
import {defineMessages, FormattedMessage} from 'react-intl';
import {FormAction, formValueSelector, InjectedFormProps, submit} from "redux-form";
import {DispatchProp} from "react-redux";
import {
    AbstractComponent,
    form,
    FormFieldDropdown,
    FormFieldInput,
    FormFieldMultiselect,
    FormValidator
} from "lumen-react-javascript";
import {ProjectStateProp, projectStatePropsMapper} from "src/type-definitions/reducers";
import {MULTISELECT_MESSAGES} from "src/app/const";

const messages = defineMessages({
    createNewDiscussion: {
        id: 'createNewDiscussion',
        defaultMessage: 'Create a new discussion',
    },
    bindToExisting: {
        id: 'bindToExisting',
        defaultMessage: 'Bind to an existing discussion',
    },
    whatWouldYouLikeToDo: {
        id: 'whatWouldYouLikeToDo',
        defaultMessage: 'What would you like to do?',
    },
    discussionTitle: {
        id: 'discussionTitle',
        defaultMessage: 'Discussion title',
    },
    discussion: {
        id: 'discussion',
        defaultMessage: 'Discussion',
    },
    tags: {
        id: 'tags',
        defaultMessage: 'Tags',
    },
});

interface Props extends ProjectStateProp, ProjectDiscussionsRequestPropsMapper, InjectedFormProps<any>, DispatchProp<FormAction> {
    media: Media;
    modalProps: ModalProps;
    onAddPin: (pin) => void;
    bind: boolean;
}

export class PinPopup extends AbstractComponent<Props, {}> {

    static onSubmit(values, dispatch, props) {
        props.onAddPin && props.onAddPin(values);
        props.modalProps.onHide();
    }

    render() {
        return (
            <Modal {...this.props.modalProps}>
                <Modal.Header>
                    <Modal.Title><FormattedMessage id="addPin" defaultMessage="Add pin"/></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={this.props.handleSubmit}>
                        <FormFieldDropdown
                            name="bind"
                            data={[
                                {id: false, label: this.intl.formatMessage(messages.createNewDiscussion)},
                                {id: true, label: this.intl.formatMessage(messages.bindToExisting)},
                            ]}
                            valueField="id"
                            textField="label"
                            label={this.intl.formatMessage(messages.whatWouldYouLikeToDo)}/>

                        {!this.props.bind ? [
                            <FormFieldInput
                                key={0}
                                name="discussionTitle"
                                label={this.intl.formatMessage(messages.discussionTitle)}/>,
                            (this.props.projectState.openTags || this.props.projectState.tags.length > 0) ? (
                                <FormFieldMultiselect
                                    key={1}
                                    valueField="id"
                                    textField="name"
                                    multiselectProps={{
                                        onCreate: true,
                                        allowCreate: this.props.projectState.openTags ? 'onFilter' : false,
                                        filter: 'contains',
                                        messages: MULTISELECT_MESSAGES(this.intl),
                                        onChange: tags => console.log(tags)
                                    }}
                                    name="discussionTags"
                                    label={this.intl.formatMessage(messages.tags)}
                                    data={this.props.projectState.tags}/>
                            ) : null
                        ] : (
                            <FormFieldDropdown
                                name="discussionId"
                                label={this.intl.formatMessage(messages.discussion)}
                                data={this.props.projectDiscussions}
                                valueField="id"
                                textField="name"/>
                        )}
                    </form>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.props.modalProps.onHide()}>
                        <FormattedMessage id="cancel" defaultMessage="Cancel"/>
                    </Button>
                    <button type="submit" onClick={() => this.props.dispatch(submit('PinPopup'))}
                            className="btn btn-primary"
                            disabled={this.props.pristine || this.props.submitting}>
                        {!this.props.bind ? (
                            <FormattedMessage id="create" defaultMessage="Create"/>
                        ) : (
                            <FormattedMessage id="bind" defaultMessage="Bind"/>
                        )}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

}

const selector = formValueSelector('PinPopup');

export default form(
    PinPopup,
    {
        initialValues: {bind: false},
        form: 'PinPopup',
        validate: (values, props) => {
            let validator = FormValidator.make(values, props);
            if (props.bind) {
                validator.required('discussionId');
            } else {
                validator.required('discussionTitle');
            }

            if (props.projectState.requiredTags) {
                validator.required('discussionTags');
            }

            return validator.validate();
        },
        onSubmit: PinPopup.onSubmit,
    },
    state => ({
        ...{bind: selector(state, 'bind')},
        ...projectStatePropsMapper(state),
    }),
    null,
    null,
    projectDiscussionsRequestLoader((props, _) => _(props.media.projectId))
);