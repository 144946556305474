import * as React from 'react';
import {AccountStateProp, accountStatePropsMapper,} from "src/type-definitions/reducers";
import {AppState} from "src/reducers/index";
import {AbstractStatelessComponent, connector} from "lumen-react-javascript";
import AccountMap from "src/app/public/routes/account-map";
import Map from "src/app/public/routes/map";

interface Props extends AccountStateProp {
}

export class MapWrapper extends AbstractStatelessComponent<Props> {

    render() {
        return this.props.accountState && this.props.accountState.id ?
            <AccountMap {...this.props}/> : <Map {...this.props}/>
    }

}

export default connector(
    MapWrapper,
    {
        mapStateToProps: (state: AppState) => ({
            ...accountStatePropsMapper(state),
        }),
    }
);