import * as React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import {AbstractSimpleComponent} from "lumen-react-javascript";

export default class Terms extends AbstractSimpleComponent {

    render() {
        return (
            <div id="terms" className="bg-gray-lighter p flex-1">
                <FormattedHTMLMessage id="termsContent" defaultMessage="Terms content"/>
            </div>
        );
    }

}