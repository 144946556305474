import * as React from 'react';
import {FormattedMessage} from 'react-intl';
import {InjectedFormProps} from "redux-form";
import {requestPasswordRequest} from "src/type-definitions/endpoints";
import {PasswordRequest} from "src/type-definitions/types";
import {AbstractComponent, Empty, FormFieldInput, FormSubmit, FormValidator, simpleForm} from "lumen-react-javascript";

interface Props extends InjectedFormProps<PasswordRequest> {
}

interface State {
    submitted: boolean;
}

export class RequestPassword extends AbstractComponent<Props, State> {

    constructor(props, context) {
        super(props, context);
        this.state = {submitted: false};
    }

    private onSubmit(values) {
        requestPasswordRequest(values).then(() => {
            this.setState({submitted: true});
        });
    }

    render() {
        return (
            <div id="request-password" className="bg-gray-lighter p flex-1">
                {this.state.submitted ? (
                    <Empty>
                        <p>
                            <FormattedMessage id="passwordRequestConfirmation"
                                              defaultMessage="If your email has been found a mail has been sent to your inbox"/>
                        </p>
                        <p>
                            <a href="#" onClick={e => {
                                window.location.href = `/admin`;
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                                <FormattedMessage id="clickHereToGoBackToAdmin"
                                                  defaultMessage="Click here to go back to the admin"/>
                            </a>
                        </p>
                    </Empty>
                ) : (
                    <form onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}>
                        <FormattedMessage id="enterEmailForPassword"
                                          defaultMessage="Enter your email to request your password"/>
                        <FormFieldInput name="email"/>
                        <FormSubmit label={<FormattedMessage id="request"
                                                             defaultMessage="Request"/>} {...this.props}/>
                    </form>
                )}
            </div>
        );
    }

}

export default simpleForm(
    RequestPassword,
    {
        validate: (values, props) => FormValidator.make(values, props).required('email').validate(),
        form: 'RequestPassword',
    }
);