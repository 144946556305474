import * as React from 'react';
import {MediaStateProp} from "src/type-definitions/reducers";
import MediaMap from "./media-map";
import {MediaType, Pin} from "src/type-definitions/types";
import MediaImg from "./media-img";
import MediaPdf from "./media-pdf";
import MediaYoutube from "./media-youtube";
import MediaIframe from "./media-iframe";
import {AbstractStatelessComponent} from "lumen-react-javascript";
import {resolveLoader} from "lumen-react-javascript";

interface Props extends MediaStateProp {
    onPinClick: (pin: Pin) => void;
}

export default class MediaRenderer extends AbstractStatelessComponent<Props> {

    render() {
        if (!this.props.mediaState) {
            return resolveLoader();
        }

        switch (this.props.mediaState.type) {
            case MediaType.MAP:
                return <MediaMap media={this.props.mediaState} pins={this.props.mediaState.pins} onPinClick={this.props.onPinClick}/>;
            case MediaType.IMG:
                return <MediaImg media={this.props.mediaState} pins={this.props.mediaState.pins} onPinClick={this.props.onPinClick}/>;
            case MediaType.PDF:
                return <MediaPdf media={this.props.mediaState} pins={this.props.mediaState.pins} onPinClick={this.props.onPinClick}/>
            case MediaType.YOUTUBE:
                return <MediaYoutube media={this.props.mediaState} pins={this.props.mediaState.pins} onPinClick={this.props.onPinClick}/>
            case MediaType.IFRAME:
                return <MediaIframe media={this.props.mediaState} pins={this.props.mediaState.pins} onPinClick={this.props.onPinClick}/>
            default:
                return (
                    <div>
                        <table className="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <td>{this.props.mediaState.name}</td>
                            </tr>
                            </tbody>
                        </table>
                        <table className="table table-striped table-bordered table-hover">
                            <tbody>
                            {this.props.mediaState.pins.map(pin => (
                                <tr key={pin.id}>
                                    <td onClick={() => this.props.onPinClick(pin)}>{pin.id}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                );
        }
    }

}