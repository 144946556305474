import {AnyAction, Reducer} from "redux";
import {User} from "../type-definitions/types";
import {AuthUtils, GlobalActionType} from "lumen-react-javascript";

export enum AuthActionType {
    LOGGED_IN = 'LOGGED_IN',
    LOGGED_OUT = 'LOGGED_OUT',
}

const AuthUtilsReducer: Reducer<User> = (state: User = AuthUtils.authenticatedUser<User>(), action: AnyAction) => {
    if (action.error) {
        return state;
    }

    switch (action.type) {
        case AuthActionType.LOGGED_IN:
            return {...AuthUtils.authenticatedUser<User>()};
        case AuthActionType.LOGGED_OUT:
            return null;
        case GlobalActionType.REFRESH_STORE:
            return {...AuthUtils.authenticatedUser<User>()};
        default:
            return state;
    }
};

export default AuthUtilsReducer;